import GetMethod from "../../Hooks/API/GetMethod";

const getCommandById = async (id, tenantsDB) =>{
    const queryParameters = {
        tenantsDB
    };
    const path = `/CommandsDevice/${id}`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default getCommandById;