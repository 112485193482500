import React, { useEffect, useRef, useState } from "react";
import { IoIosSettings } from "react-icons/io";

import { Form, Button } from "react-bootstrap";

import { 
    DndContext,
} from "@dnd-kit/core";
import "./Dashboard.css";
import Droppable from "./Droppable/Droppable";

//Graphics references
import GraphHeatmap from "./Graphics/GraphHeatmap";
import BarChart from "./Graphics/LineChart";
//API references
import postDashboardFinancial from "../../api/Dashboard/postDashboardFinancial";
import MultipleBarChart from "./Graphics/MultipleBarChart";
import useDragAndDrop from "../../Hooks/useDragAndDrop/useDragAndDrop";

function DashboardTransactions() {
    
    const formatDate = (date,languageCode) => {
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };
    /******************** START: DATE STATE ****************************************************/
    const DateState = {
        TODAY: 0,
        WEEK_DATE: 1,
        MONTH_DATE: 2,
        YEAR_TO_DATE: 3,
        PROGRAM_TO_DATE: 4,
        CUSTOM_DATES: 5
    };
    /******************** END: DATE STATE *********************************************************/
     // #region get localStorage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const language = JSON.parse(localStorage.getItem('language'));
     // #endregion

    const getCurrentMonthDateRange = () => {
        //local date
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startOfMonth.setHours(0, 0, 0, 0);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        //UTC date
        const startOfMonthUTC = startOfMonth.toISOString();
        const endOfMonthUTC = endOfMonth.toISOString();
        
        return { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth };
    };

    const getCurrentYearDateRange = () =>{
        const currentDate = new Date();
        const firstDayOfYear = new Date();
        //Local date
        firstDayOfYear.setFullYear(currentDate.getFullYear() - 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        return {startOfWeekUTC,endOfWeekUTC,firstDayOfYear,lastDayOfYear};
    }
    
    const { startOfMonth, endOfMonth } = getCurrentMonthDateRange();

    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);

    //const [items, setItems] = useState([]);
    const [heatMapItem, setHeatMapItem] = useState([]);
    const [lineMapItem, setLineMapItem] = useState([]);
    const [barChart, setBarChart] = useState([]);
    const [countBillsAndCoins, seCountBillsAndCoins] = useState([]);
    const [isTVM,setIsTVM] = useState(true);
    const [dateBtn,setDateBtn] = useState([]);
    const [dateFinancial,SetDateFinancial] = useState(
        `${language.FromDate}: ${formatDate(startOfMonth,languageCode)} 
        ${language.ToDate}: ${formatDate(endOfMonth,languageCode)}`
    );

    const initialItems = { dashboard1: [], dashboard2: [] };
    const { items, sensors, handleDragOver, handleDragEnd, setItems } = useDragAndDrop(initialItems);

    /******************** START: state for DATE HANDLE **********************************/
    const [customDate,setCustomDate] = useState(false);
    const [dateState,setDateState] = useState(DateState.MONTH_DATE);
    const [toDate,setToDate] = useState('');
    const [fromDate,setFromDate] = useState('');
    /********************** END: state for DATE HANDLE **********************************/
    
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenantsDB = currentUser.data.currentTenant;
                /******************* START: Gets year ********************************************/
                const {startOfWeekUTC,endOfWeekUTC} = getCurrentYearDateRange();
                /******************* END: Gets year ********************************************/
                /******************* START: Gets Month *****************************************/
                const { startOfMonthUTC, endOfMonthUTC } = getCurrentMonthDateRange();
                /******************* END: Gets Month *****************************************/
                const response = await postDashboardFinancial(
                { 
                    tenantsDB, 
                    heatMap_data : { 
                        fromDate: startOfWeekUTC, 
                        toDate: endOfWeekUTC,
                        isTVM 
                    },
                    summaryGraph_SalesProducts : { 
                        fromDate: startOfWeekUTC, 
                        toDate: endOfWeekUTC,
                        typeGraph:0 
                    },
                    summaryGraph_Sales : { 
                        fromDate: startOfWeekUTC, 
                        toDate: endOfWeekUTC,
                        typeGraph:1 
                    },
                    summaryGraph_CountBillsAndCoins : { 
                        fromDate: startOfWeekUTC, 
                        toDate: endOfWeekUTC,
                        typeGraph:2 
                    },
                    card : { 
                        fromDate:(fromDate?fromDate:startOfMonthUTC),
                        toDate:(toDate?toDate:endOfMonthUTC)
                    },
                });
                const dashboardCard = response.cardFinancial[0];
                const heatmap_financial = response.heatMap;
                const salesIncome = response.SummaryGraph_Sales;
                const salesProducts = response.SummaryGraph_salesProducts;
                const countBillsAndCoins = response.SummaryGraph_CountBillsAndCoins;
                setHeatMapItem(heatmap_financial);
                setLineMapItem(salesIncome);
                setBarChart(salesProducts);
                seCountBillsAndCoins(countBillsAndCoins);
                //array for card
                const dashboards = Array.from({ length: 2 }, () => []);
                
                const dashboardEntries = Object.entries(dashboardCard);
                let cardCount = 1;
                dashboardEntries.map(item => {
                    const dashboardIndex = Math.min(Math.floor(cardCount / 15), 1);
                    dashboards[dashboardIndex].push({
                        id:cardCount,
                        name:item[0],
                        total:item[1]
                    });
                    cardCount++;
                });
                const [dashboard1, dashboard2] = dashboards;
                setItems({
                    dashboard1,
                    dashboard2
                });
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [isTVM,dateState]);

    const handleByTvm = () => {
        setIsTVM(true);
    }

    const handleByStation = () => {
        setIsTVM(false);
    }
    /**************** START: DATE HANDLE *************************************/

    const handleToday = async () =>{
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        //UTC date
        const startOfDayUTC = startOfDay.toISOString();
        const endOfDayUTC = endOfDay.toISOString();
        setFromDate(startOfDayUTC);
        setToDate(endOfDayUTC);
        setDateState(DateState.TODAY);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfDay,languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay,languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfDay,languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay,languageCode)}`
        );
    }

    const handleWeekDate = async () =>{
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfWeek.toISOString();
        const endOfWeekUTC = lastDayOfWeek.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setDateState(DateState.WEEK_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek,languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek,languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek,languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek,languageCode)}`
        );
    }

    const handleMonthDate = async () =>{
        setCustomDate(false);
        const { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth } = getCurrentMonthDateRange();
        setFromDate(startOfMonthUTC);
        setToDate(endOfMonthUTC);
        setDateState(DateState.MONTH_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfMonth,languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth,languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfMonth,languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth,languageCode)}`
        );
    }

    const handleYearToDate = async () =>{
        setCustomDate(false);
        const currentDate = new Date();
        //Local date
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setDateState(DateState.YEAR_TO_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfYear,languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear,languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfYear,languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear,languageCode)}`
        );
    }

    const handleCustomDates =  () =>{
        setCustomDate(true);
        setDateBtn("");
    }

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const selectedFromDate = fromDateRef.current.value;
        const selectedToDate = toDateRef.current.value;
        //select date
        const [yearFrom, monthFrom, dayFrom] = selectedFromDate.split('-');
        const [yearTo, monthTo, dayTo] = selectedToDate.split('-');
        const customFromDate = new Date(yearFrom, monthFrom - 1, dayFrom, 0, 0, 0, 0);
        const customToDate = new Date(yearTo, monthTo - 1, dayTo, 23, 59, 59, 999);

        setDateState(DateState.CUSTOM_DATES);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(customFromDate,languageCode)} 
            ${language.ToDate}: ${formatDate(customToDate,languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)}
            ${language.ToDate}: ${formatDate(customToDate,languageCode)}`
        );
    };
    /****************** END: DATE HANDLE ***********************************/

    return (
        <>
            <section className="main-container-app">
                <div className="dashboard-container">
                    <div className="dashboard-btn-container">
                        <button onClick={handleToday} className="dashboard-date-btn">{language.Today}</button>
                        <button onClick={handleWeekDate} className="dashboard-date-btn">{language.WeekToDate}</button>
                        <button onClick={handleMonthDate} className="dashboard-date-btn">{language.MonthToDate}</button>
                        <button onClick={handleYearToDate} className="dashboard-date-btn">{language.YearToDate}</button>
                        <button onClick={handleCustomDates} className="dashboard-date-btn">{language.CustomDates}</button>
                    </div>
                    {customDate && (
                        <div className="dashboard-box-date">
                            <Form>
                                <Form.Group controlId="fromDate">
                                    <Form.Label>From Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        ref={fromDateRef}
                                        onChange={handleFromDateChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="toDate">
                                    <Form.Label>To Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        ref={toDateRef}
                                        onChange={handleToDateChange}
                                    />
                                </Form.Group>
                                <Button className="Submit_btn" onClick={handleSubmit} variant="primary">Submit</Button>
                            </Form>
                        </div>
                    )}
                </div>
                {dateBtn&&(<p className="data_label">{dateFinancial}</p>)}
                
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                >
                    <div className="main-box">
                        <div className="dashboard-settings">
                            {(Object.keys(items).length > 0) && ((items.dashboard1.length + items.dashboard2.length) > 15) && (
                                <IoIosSettings size={20} style={{ fill: '#727BBE' }} />
                            )}
                        </div>
                        <h4 className="dashboard-subtitle">{language.Financial}</h4>
                        {Object.keys(items).length > 0 && (
                            Object.keys(items).map((group) => (
                                items[group].length > 0 && (
                                    <Droppable id={group}
                                        items={items[group]}
                                        languageCode={languageCode}
                                        currency={currency}
                                        cardType={"Financial"}
                                        language={language}
                                        key={group} 
                                        currentDashboard={"TVMDashboardTransactions"}/>
                                )
                            ))
                        )}
                    </div>
                </DndContext>
            </section>

            {lineMapItem.length > 0 || barChart.length > 0 || countBillsAndCoins.length > 0 ? (
                <section className="main-container-app" style={{ paddingTop: 0 }}>
                    <div className="main-box">
                        <h4 className='dashboard-subtitle'>{language.SummaryGraph}</h4>
                        <div className="graph_container">
                            {lineMapItem.length > 0 && (
                                <BarChart data={lineMapItem} title={language.Sales} currency={currency} languageCode={languageCode} />
                            )}
                            {barChart.length > 0 && (
                                <MultipleBarChart data={barChart} typeGraph={1} title={language.ProductsSales} currency={currency} languageCode={languageCode} />
                            )}
                            {countBillsAndCoins.length > 0 && (
                                <MultipleBarChart data={countBillsAndCoins} typeGraph={2} title={language.PaymentMethodBalance} currency={currency} languageCode={languageCode} />
                            )}
                        </div>
                    </div>
                </section>
            ) : null}

            {heatMapItem.length > 0 && (
                <section className="main-container-app" style={{ paddingTop: 0 }}>
                    <div className="main-box">
                        <h4 className='dashboard-subtitle'>{language.SalesHeatMap}</h4>
                        <div className="container-buttons">
                            <button className="button-dashboard" onClick={handleByTvm}>by TVM</button>
                            <button className="button-dashboard" onClick={handleByStation}>by station</button>
                        </div>
                        <GraphHeatmap data={heatMapItem} isTVM={isTVM} language={language} />
                    </div>
                </section>
            )}
        </>
    );
}

export default DashboardTransactions;
