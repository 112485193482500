import "./css/AddButton.css"
import { FaPlusCircle } from "react-icons/fa";

function AddButton({onClick}){
    return (
        <div className="add-button" onClick={onClick}>
            <div className="add-button__icon">+</div>
        </div>
    );
}

export default AddButton;