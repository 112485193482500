import { fetchAuthSession } from 'aws-amplify/auth';
import { get } from 'aws-amplify/api'

async function getUserCognito(username) {
  try {
    const session = await fetchAuthSession ();
    const accessToken = session.tokens.accessToken.toString();
    let apiName = 'AdminQueries';
    let path = `/getUser?username=${username}`;
    let options = { 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: accessToken
        },
        response: true
    }
    const restOperation  = get({
        apiName,
        path,
        options
    });

    const {body} = await restOperation.response
    const data = await body.json();

    return {data : data, error : ''};
  } catch (error) {
    return error;
  }
}

export default getUserCognito;