import React from 'react';
import './css/UsersOverlay.css';
import getPermission from '../../auth/GetPermission';

function UsersOverlay({show, onClose, position, setShowModal, handleResetPassword, handleChangeEmail, handleChangePhone, username, handleDeleteClick}) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const language = JSON.parse(localStorage.getItem('language'));

  return (
    <>
      {show && (<>
        
        <div className="overlay" onClick={onClose} style={{  
            position: 'absolute',
            left: position.x,
            top: position.y }}>
          <button className="close-button" onClick={onClose}>X</button>
          
            {(username === currentUser.data.userData.name || getPermission(currentUser, "UMUsers", "updateAllow")) &&
                <div className='overlay__link separated' onClick={handleResetPassword}>{language.ResetPassword}</div>
            }
            
            {username === currentUser.data.userData.name &&
                <div className='overlay__link separated' onClick={handleChangeEmail}>{language.ChangeEmail}</div>
            }
            {username === currentUser.data.userData.name &&
                <div className='overlay__link separated' onClick={handleChangePhone}>{language.ChangePhone}</div>
            }
            {(username !== currentUser.data.userData.name && getPermission(currentUser, "UMUsers", "updateAllow")) &&
                <div className='overlay__link-red' onClick={handleDeleteClick}>{language.DeleteUser}</div>
            }
          
        </div>
      </>)}
    </>
  );
}

export default UsersOverlay;
