import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function EditState(state, stateId) {
    const path = `/states/edit/${stateId}`;
    try {
        const data = await postMethod(state, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
};

export default EditState;