import postMethod from "../../Hooks/API/post";

async function createCustomReport(body) {  
    const path = `/CustomReports/add`;
    try {
        const {data,error} = await postMethod(body,path);
        return {data : data, error : ''};;
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default createCustomReport;