import { useEffect, useState } from "react";
import { Button, Spinner, Form  } from "react-bootstrap";
import dropdownReportsType from "../../api/CustomReports/dropdownReportsType";
import getReport from "../../api/CustomReports/getReport";
import ReportsTable from "./tables/ReportsTable";
import GetPermission from "../../auth/GetPermission";

const ChangesPage = () =>{
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dropDown, setDropDown ] = useState([]);
    const [selectedReport, setSelectedReport] = useState('');
    const [reportId, setReportId] = useState('');
    const [filter, setFilter] = useState(false);
    const [data, setData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const responseDropDown = await getDropDownCR();
                setDropDown(responseDropDown.data);
                setIsLoading(false);
            } catch (error) {
                handleError(error.message);
            }
        }
        fetchData();
    }, [currentTenant]);

    useEffect(() =>{
        const fetchReportData = async () => {
            if (reportId && startDate && endDate) {
                try {
                    setIsLoading(true);
                    const body = {
                        fromDate: startDate,
                        toDate: endDate,
                        tenantsDB: currentTenant
                    };
                    const {data,groupData} = await getReport(body, reportId);
                    if (data) {
                        setData(data);
                        setGroupData(groupData)
                    }
                } catch (error) {
                    handleError(error.message);
                }
                setIsLoading(false);
            }
        };
        fetchReportData();
    }, [filter, currentTenant]);

    const getDropDownCR = async () =>{
        const response = await dropdownReportsType({tenantsDB:currentTenant,reportType:'Changes'});
        return response;
    }

    const handleFilter = async () => { 
        setIsLoading(true);
        try{
            if(startDate === '' || endDate === '' || reportId === ''){
                handleError(language.validationReport);
                setIsLoading(false);
                return;
            }
            setFilter(!filter);
        } catch (error) {
            handleError(error.message);
        } finally {
            setIsLoading(false);
        }
        
    };

    const handleClearFilter = async () => {
        setIsLoading(true);
        try{
            setStartDate('');
            setEndDate('');
            setReportId('');
            setSelectedReport('');
            setData([]);
        } catch (error) {
            handleError(error.message);
        } finally {
            setIsLoading(false);
        }
    }; 

    const handleReportChange = (e) => {
        try{
            setSelectedReport(e.target.value);
            const selectedKey = e.target.selectedOptions[0].getAttribute('data-key');
            setReportId(selectedKey);
        } catch (error) {
            handleError(error.message);
        }
    };

    const handleError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => {
            setError('');
        }, 10000);
    };

    // #region components
    const LoadingSpinner = ({ isLoading }) => (
        isLoading && (
            <div className='spinner-container'>
                <Spinner animation="border" variant="primary" className='spinner' />
            </div>
        )
    );

    const ErrorAlert = ({ error }) => (
        error && <div className="alert alert-main alert-danger">{error}</div>
    )
    // #endregion

    const language = JSON.parse(localStorage.getItem('language'));

    return(
        <div className='main-container-app'>
                <ErrorAlert error={error}/>
                <h1 className='module__title'>{language.ChangesReports}</h1>
                {
                GetPermission(currentUser, "ReportsChanges", "readAllow") && (
                <>
                    <div className='inputs-row__centered'>
                    <div className="input-name">
                        <label className='input__label'>{language.StartDate}:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className='module__input-search modal__text-box email-input'
                        />
                    </div>
                    <div className="input-name">
                        <label className='input__label'>{language.EndDate}:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            className='module__input-search modal__text-box email-input'
                        />
                    </div>
                    <div className="input-name custom-dropdown">
                    <label className='input__label'>{language.Report}</label>
                    <Form.Control
                        as="select"
                        value={selectedReport}
                        onChange={handleReportChange}
                        className='module__input-search modal__text-box email-input custom-dropdown'
                    >
                        <option key={0} value="">{language.SelectReport}</option>
                        {dropDown.map((item) => (
                            <option key={item.idCustomReports} value={item.name} data-key={item.idCustomReports}>{item.name}</option>
                        ))}
                    </Form.Control>
                </div>
                    <Button variant="primary" onClick={handleFilter} className="date-filter__button">
                        {language.Filter}
                    </Button>
                    <Button variant="secondary" onClick={handleClearFilter} className="date-filter__button">
                        {language.ClearFilter}
                    </Button>
                    </div>
                    {data.length > 0&&<ReportsTable data={data} groupData={groupData}/>}
                    <LoadingSpinner isLoading={isLoading} />
                </>
                )
                }
        </div>
    )
}

export default ChangesPage;