import getAllCustomAlarms from "../../../api/CustomAlarms/getAllCustomAlarms";
import GeneralTable from "../../../Hooks/Table/GeneralTable";
import getPermission from "../../../auth/GetPermission";
import AddButton from "../../../Common/AddButton";
import CustomAlarmsNewModal from "./CustomAlarmsNewModal";
import CustomAlarmsEditModal from "./CustomAlarmsEditModal";
import deleteCustomAlarm from "../../../api/CustomAlarms/deleteCustomAlarm";
import "./css/CustomAlarmsPage.css";
import DeleteModal from "../../../Common/DeleteModal";

import { Box } from '@mui/material';

import { useState, useEffect } from "react";

import { Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import ErrorCases from "../../../Common/ErrorCases";

function CustomAlarmsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingAlarmId, setEditingAlarmId] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingAlarm, setDeletingAlarm] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getAllCustomAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const columns = [
        {
            header: language.Name,
            accessorKey: 'customAlarmName',
        },
        {
            header: language.Parameter,
            accessorKey: 'parameter',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Device,
            accessorKey: 'deviceName',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Event,
            accessorKey: 'event',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Color,
            accessorKey: 'color',
            Cell: ({ renderedCellValue }) => (
                <Box mx='auto'
                    sx={{
                        bgcolor: renderedCellValue,
                        width: 100,
                        height: 20,

                    }} />
            ),
        },
        {
            header: language.Active,
            accessorKey: 'isActive',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox' />
                </Box>
            ),
        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idCustomAlarms)}
                disabled={!getPermission(currentUser, "DMTVMCustomAlarms", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "DMTVMCustomAlarms", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    const handleEditClick = (id) => {
        setEditingAlarmId(id);
        setShowEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await deleteCustomAlarm(id, currentTenant);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirm = (alarm) => {
        setDeletingAlarm(alarm);
        setShowDeleteModal(true);
        setDeleteError('');
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.CustomAlarms}</h1>
            {getPermission(currentUser, "DMTVMCustomAlarms", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    {
                        getPermission(currentUser, "DMTVMCustomAlarms", "writeAllow") &&
                        <div className='addbutton__container' onClick={() => setShow(true)}><AddButton /></div>
                    }

                    <CustomAlarmsNewModal
                        show={show}
                        onHide={() => setShow(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}> </CustomAlarmsNewModal>

                    <CustomAlarmsEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}
                        editingAlarmId={editingAlarmId} />
                </>
            }
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteCustomAlarmMessage + deletingAlarm.customAlarmName}
                    handleDelete={() => handleDeleteClick(deletingAlarm.idCustomAlarms)}
                    error={deleteError}
                />
            }
        </div>
    );
}

export default CustomAlarmsPage;