import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useState } from 'react';

import createCurrency from '../../api/Currency/createCurrency';

import { getAllISOCodes } from 'iso-country-currency';
import ErrorCases from '../../Common/ErrorCases';

function CurrencyNewModal({ show, onHide, getData, setData, setIsLoading }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const currentUserId = currentUser.data.userData.id;
    const [currency, setCurrency] = useState({ name: "", currency: "", isActive: 0, sign: "", userId: currentUserId, tenantsDB: currentTenant });
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await createCurrency(currency);
            if(response.error) {
                const error = ErrorCases(response.error);
                setCreateError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
            setCurrency({ ...currency, name: "", currency: "", isActive: 0, sign: "" });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
        setCurrency({ ...currency, name: "", currency: "", isActive: 0, sign: "" });
        setCreateError('');
    };

    const isoCodes = getAllISOCodes();

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewCurrency}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterCurrencyName}
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setCurrency({ ...currency, name: evt.target.value })}
                            ></input>
                        </div>



                        <div>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={currency.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={evt => currency.isActive === 1 ? setCurrency({ ...currency, isActive: 0 }) : setCurrency({ ...currency, isActive: 1 })} />
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div >
                            <label className='input__label'>{language.Currency}</label> <br />
                            <select onChange={evt => setCurrency({ ...currency, currency: evt.target.value })}
                                className='module__input-search'>
                                <option >{language.SelectACurrency}</option>
                                {isoCodes.map(code => {
                                    return (<option key={code.iso} value={code.currency}>{code.countryName}: {code.currency}</option>);
                                })}
                            </select>
                        </div>

                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.Sign}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input input-numeric'
                                onChange={evt => setCurrency({ ...currency, sign: evt.target.value })}
                                value={currency.sign}
                            ></input>
                        </div>
                    </div>
                    <div className='error-alert'>{createError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CurrencyNewModal;