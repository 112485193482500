import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "../SortableItem/SortableItem";
import "./Droppable.css";
import { MdSave } from 'react-icons/md';


import React, { useState } from "react";
import GetPermission from "../../../auth/GetPermission";

const Droppable = ({ id, items, handleCardDetails, cardType, languageCode, currency, language, currentDashboard, programFromDate, programToDate, compareFromDate, compareToDate, 
    setIsLoading, showModal, setShowModal }) => {
    const { setNodeRef } = useDroppable({ id });
    const [draggingItemId, setDraggingItemId] = useState(null);
    const [isDraggable, setIsDraggable] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const toggleDraggable = () => {
        setIsDraggable((prev) => !prev);
    };

    return (
        <>
            {
                GetPermission(currentUser, currentDashboard, "updateAllow") &&
                <div className="dragAndDrop-cards-btn">
                    <button onClick={toggleDraggable} className="toggle-drag-button">
                        {isDraggable ? (
                            <MdSave size={20} />
                        ) : (
                            <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
                        )}
                    </button>
                </div>
            }
            <SortableContext id={id} items={items} strategy={rectSortingStrategy} key={id}>
                <div ref={setNodeRef} className={`droppable ${draggingItemId ? 'droppable-over' : ''}`}>
                    {items.map((item) => (
                        (<SortableItem
                            key={item.id}
                            id={item.id}
                            item={item}
                            cardType={cardType}
                            handleCardDetails={handleCardDetails}
                            languageCode={languageCode}
                            currency={currency}
                            language={language}
                            onDraggingChange={setDraggingItemId}
                            isDraggable={isDraggable}
                            programFromDate={programFromDate}
                            programToDate={programToDate}
                            compareFromDate={compareFromDate}
                            compareToDate={compareToDate}
                            setIsLoading={setIsLoading}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />)
                    ))}
                </div>
            </SortableContext>
        </>
    );
};

export default Droppable;
