import deleteMethod from "../../Hooks/API/DeleteMethod";

async function DeleteTVM(id, tenantsDB) {
    const path = `/Tvm/delete/${id}?tenantsDB=${tenantsDB}`;
    try {
        const { data, error } = await deleteMethod(path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default DeleteTVM;