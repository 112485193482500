import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import createEmailAlarm from '../../../api/EmailsAlarms/createEmailAlarm';
import ErrorCases from '../../../Common/ErrorCases';

function EmailsNewModal({ show, onHide, getData, setData, setIsLoading }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [email, setEmail] = useState({
        name: '',
        lastName: '',
        isActive: 1,
        email: '',
        tenantsDB: currentTenant
    });
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState('');
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    const handleCancel = () => {
        onHide();
        clearEmail();
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail({ ...email, email: value });

        if (validateEmail(value)) {
            setEmailError('');
        } else {
            setEmailError(language.EnterValidEmail);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email.email)) {
            setEmailError(language.EnterValidEmail);
        } else {
            try {
                setIsLoading(true);
                const response = await createEmailAlarm(email);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setCreateError(error);
                    return;
                }
                const newData = await getData();
                setData(newData);
                onHide();
                clearEmail();
            } catch (error) {
                console.error(error);
                setCreateError(error.message);
            } finally {
                setIsLoading(false);
            }

        }
    };

    const clearEmail = () => {
        setEmail({
            ...email,
            name: '',
            lastName: '',
            isActive: 0,
            email: '',
            tenantsDB: currentTenant
        });
        setEmailError('');
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.NewEmail}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <><form onSubmit={handleSubmit}>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setEmail({ ...email, name: evt.target.value })}
                                required
                            ></input>
                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.LastName}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setEmail({ ...email, lastName: evt.target.value })}
                            ></input>
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div className='modal__row-aligned-item'>
                            <label className='input__label'>{language.Email}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={handleEmailChange}
                            ></input>
                        </div>

                        <div className='modal__row-aligned-item'>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={email.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => email.isActive === 1 ? setEmail({ ...email, isActive: 0 }) : setEmail({ ...email, isActive: 1 })} />
                        </div>
                    </div>
                    {emailError && <p className='error-alert'>{emailError}</p>}
                    <div className='error-alert' >{createError}</div>
                </form>
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button className="general-button danger" onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className="general-button primary" onClick={handleSubmit} type="submit">
                    {language.Save}
                </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default EmailsNewModal;