import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaExpand, FaCompress } from 'react-icons/fa';
import processDataGraphics from './processDataGraphics';

const MultipleBarChart = ({ data, typeGraph, title, currency, languageCode }) => {
    const [expanded, setExpanded] = useState(false);
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            title: {
                text: title,
                align: 'center',
                margin: 20,
                offsetY: 20
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            },
            yaxis: {
                labels: {
                formatter: function (val) {
                    return new Intl.NumberFormat(languageCode, {
                    style: 'currency',
                    currency: currency.currency,
                    }).format(val);
                }
                }
            },
        }
    });

    useEffect(() => {
        const {categories,series} = processDataGraphics(data,"barChart",typeGraph);
        setChartData({
        series: series,
        options: {
            ...chartData.options,
            xaxis: {
                categories: categories
            }
        }
        });
    }, [data]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={`remove-design division ${expanded ? 'expanded' : ''}`}>
            <div className="chart-header">
                <button onClick={toggleExpand} className="expand-btn">
                {expanded ? <FaCompress /> : <FaExpand />}
                </button>
            </div>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default MultipleBarChart;
