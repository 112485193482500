import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import postMethod from '../../Hooks/API/post';

async function CreateUser(dataUser) {  
    const path = '/Users/CreateUser';
    try {
        const data = await postMethod(dataUser, path);
        return {data : data, error : ''};
    } catch (error) {
        return {data : '', error : error};
    }
} 

export default CreateUser;
