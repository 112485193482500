import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ZoneNewModal.css';
import editZone from '../../api/Fares/editZone';

import CurrencyInput from 'react-currency-input-field';
import ErrorCases from '../../Common/ErrorCases';

function ZoneEditModal({ show, onHide, products, zones, editingZoneId, editingZone, setEditedZoneId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [zone, setZone] = useState([]);
    const [formattedPrice, setFormattedPrice] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [editError, setEditError] = useState('');

    useEffect(() => {
        setZone([]);
        setZone({ ...editingZone });
        setFormattedPrice(editingZone.price);
    }, [products, editingZone]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const editedZone = zone;
            editedZone.price = formattedPrice;
            const response = await editZone(editedZone, editingZoneId);
            if(response.error) {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            setEditedZoneId(editingZoneId);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setEditError('');
        onHide();
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.EditZone}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row'>
                        <div className='dropdown input'>
                            <label className='input__label'>{language.Product}</label> <br />
                            <select className='module__input-search'
                                onChange={evt => setZone({ ...zone, productId: evt.target.value })}
                                value={zone.productId}>
                                {
                                    products.map(product => {
                                        return (
                                            <option value={product.idProduct}>{product.name}</option>
                                        )
                                    })
                                }
                            </select> <br />
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneOrigin}</label> <br />
                            <select className='module__input-search'
                                onChange={evt => setZone({ ...zone, zoneIdOrigin: evt.target.value })}
                                value={zone.zoneIdOrigin}>
                                {
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneDestiny}</label> <br />
                            <select className='module__input-search'
                                onChange={evt => setZone({ ...zone, zoneIdDestiny: evt.target.value })}
                                value={zone.zoneIdDestiny}>
                                {
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='dropdown input'>
                            <label className='input__label'>{language.Price}</label> <br />
                            <CurrencyInput
                                placeholder={language.EnterProductPrice}
                                className='module__input-search'
                                defaultValue={formattedPrice}
                                decimalsLimit={2}
                                onValueChange={(value, names, values) => setFormattedPrice(values.float)}
                                decimalScale={2}
                                maxLength={7}
                                intlConfig={{ locale: languageCode, currency: currency.currency }}
                            />
                            <br />
                        </div>
                    </div>
                    <div className='error-alert'>{editError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default ZoneEditModal;