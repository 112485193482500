import { useState, useMemo, useEffect } from "react";

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import getZoneFare from "../../api/Fares/getZoneFare";
import getPermission from "../../auth/GetPermission";
import deleteZone from "../../api/Fares/deleteZone";
import GeneralTable from "../../Hooks/Table/GeneralTable";
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from "../../Common/ErrorCases";

function ZoneTable({ productId, setIsLoading, setProducts, setZones, setEditingZone,
    setEditingZoneId, setShowEditZoneModal, editedZoneId, setEditedZoneId, setRestrictedZones,
    setFareZones }) {
    const [data, setData] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currency = currentUser.data.catalog.currency[0];
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteZoneModal, setShowDeleteZoneModal] = useState(false);
    const [deletingZone, setDeletingZone] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response.faresZone);
                setFareZones(response.faresZone);
                setProducts(response.productId);
                setZones(response.ZoneId);
                setRestrictedZones(response.restrictionZone);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, [productId, editedZoneId]);

    async function getData() {
        const { data, error } = await getZoneFare(productId, currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (zone) => {
        setEditingZone({
            zoneIdDestiny: zone.zoneIdDestiny,
            zoneIdOrigin: zone.zoneIdOrigin,
            price: zone.price,
            productId: zone.productId
        });
        setEditingZoneId(zone.idFareZone);
        setShowEditZoneModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const response = await deleteZone(id, currentUser.data.currentTenant);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            setEditedZoneId(id);
            setShowDeleteZoneModal(false);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirm = (zone) => {
        setDeleteError('');
        setDeletingZone(zone);
        setShowDeleteZoneModal(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.ZoneOrigin,
                accessorKey: 'zoneIdOrigin',
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.ZoneDestiny,
                accessorKey: 'zoneIdDestiny',
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.Price,
                accessorKey: 'price',
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        {currency.sign + (renderedCellValue)}
                    </Box>
                ),
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },

        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original)}
                disabled={!getPermission(currentUser, "FareProducts", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "FareProducts", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <>
            <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
            {
                showDeleteZoneModal &&
                <DeleteModal
                    show={showDeleteZoneModal}
                    onHide={() => setShowDeleteZoneModal(false)}
                    message={language.DeleteProductMessage + deletingZone.zoneIdOrigin + ' - ' + deletingZone.zoneIdDestiny}
                    handleDelete={() => handleDeleteClick(deletingZone.idFareZone)}
                    error={deleteError}
                />
            }
        </>
    );
}

export default ZoneTable;