import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api'

async function updateAttribute(username, attributeKey, value) {
  try {
    const session = await fetchAuthSession ();
    const accessToken = session.tokens.accessToken.toString();
    let apiName = 'AdminQueries';
    let path = '/updateUserAttributes';
    let options = {
        body: {
          "username" : username,
          "attributes":[
            {
                "Name":attributeKey,
                "Value":value
            }
        ]
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: accessToken
        },
        response: true
    }

    const restOperation  = post({
        apiName,
        path,
        options
    });

    const {body} = await restOperation.response
    const data = await body.json();

    return {data : data, error : ''};
  } catch (error) {
    return error;
  }
}

export default updateAttribute;