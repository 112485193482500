import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import postMethod from '../../Hooks/API/post';

async function EditUser(dataUser,id) {
    const path = `/Users/edit/${id}`;
    try {
        const data = await postMethod(dataUser, path);
        return {data : data, error : undefined};
    } catch (error) {
        let responseError = error.response.message !== undefined? error.response.message : error.response.data.message;
        if(responseError === undefined){
            responseError = error.response.data.error;
        }
        console.error(responseError);
        return {data : undefined, error : responseError};
    }
} 

export default EditUser;
