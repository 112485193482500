import {fetchAuthSession } from 'aws-amplify/auth';
import {del} from 'aws-amplify/api';
import DeleteMethod from '../../Hooks/API/DeleteMethod';

async function DeleteUser(id, tenant) {  
    const path = `/Users/${id}?tenantsDB=${tenant}`;
    try {
        const { data, error } = await DeleteMethod(path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default DeleteUser;