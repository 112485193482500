import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import processDataGraphics from './processDataGraphics';

function prepareData(data,isTVM,language) {
    return processDataGraphics({data,isTVM},"heatmap",0,language);
}

function GraphHeatmap({data,isTVM,language}) {

    const [chartOptions, setChartOptions] = useState(null);
    const [series, setSeries] = useState([])
    
    useEffect(() => {
        const { series, categories, colorRanges } = prepareData(data,isTVM,language);

        setSeries(series);
        setChartOptions({
            chart: {
                height: 350,
                type: 'heatmap',
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: 'category',
                categories: categories
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: colorRanges
                    }
                }
            }
        });
    }, [data]);

    if (!chartOptions || series.length === 0) {
        return <div>No data available to display</div>;
    }

    return (
        <div className='remove-design'>
            <ReactApexChart options={chartOptions} series={series} type="heatmap" height={350} />
        </div>
    );
}

export default GraphHeatmap;
