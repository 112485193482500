import { fetchAuthSession  } from '@aws-amplify/auth';
import { get } from 'aws-amplify/api';
import GetMethod from '../../Hooks/API/GetMethod';

const currentUser = JSON.parse(localStorage.getItem('currentUser'));

async function GetRole(id) {
    const queryParameters = {
        tenantsDB: currentUser.data.currentTenant
    };
    const path = `/Roles/${id}`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return {data : data, error : undefined};
    } catch (error) {
        const responseError = error.response.message !== undefined? error.response.message : error.response.data.error;
        console.error(responseError);
        return {data : undefined, error : responseError};
    }
}
export default GetRole;
