import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';

import createCountry from '../../api/Location/createCountry';
import ErrorCases from '../../Common/ErrorCases';

function CountriesNewModal({ show, onHide, getData, setData }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [country, setCountry] = useState({ tenantsDB: currentUser.data.currentTenant });
    const [isLoading, setIsLoading] = useState(false);
    const language = JSON.parse(localStorage.getItem('language'));
    const [error, setError] = useState('');

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await createCountry(country);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        };

    };

    const handleCancel = () => {
        onHide();
        setError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewCountry}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='input__label'>{language.Country}</label> <br />
                    <input placeholder={language.EnterNewCountryName}
                        className='module__input-search'
                        onChange={evt => setCountry({ ...country, name: evt.target.value })}
                    ></input>
                    <label className='input__label'>{language.Abbreviation}</label> <br />
                    <input placeholder={language.EnterCountryAbbreviation}
                        className='module__input-search'
                        onChange={evt => setCountry({ ...country, abbreviation: evt.target.value })}
                    ></input>
                    <div className='error-alert'>{error}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default CountriesNewModal;