import { Spinner } from "react-bootstrap";

import { useState, useEffect } from "react";

import AddButton from "../../../Common/AddButton";
import getPermission from "../../../auth/GetPermission";
import GeneralTable from "../../../Hooks/Table/GeneralTable";
import getFullStatusControl from "../../../api/TVM/getFullStatusControl";
import FullStatusNewModal from "./FullStatusNewModal";
import FullStatusEditModal from "./FullStatusEditModal";
import DeleteFullStatusControl from "../../../api/TVM/deleteFullStatusControl";
import FullStatusQRCode from "./FullStatusQR";
import DeleteModal from "../../../Common/DeleteModal";

import { Box } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { PiQrCodeLight } from "react-icons/pi";
import ErrorCases from "../../../Common/ErrorCases";

function FullStatusControl() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showFullStatusNewModal, setShowFullStatusNewModal] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [showFullStatusEditModal, setShowFullStatusEditModal] = useState(false);
    const [showFullStatusQR, setShowFullStatusQR] = useState(false);
    const [qrId, setQrId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingFullStatusControl, setDeletingFullStatusControl] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getFullStatusControl(currentTenant);

        if (error !== undefined) {
            console.error(error);
            return [];
        } else {
            return data.data;
        }
    };

    const columns = [
        {
            header: language.AccessType,
            accessorKey: 'accessType',
            muiTableBodyCellProps: {
                align: 'left',
            }
        },
        {
            header: language.name,
            accessorKey: 'name',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Active,
            accessorKey: 'active',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox' />
                </Box>
            ),
        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleQRClick(row.original.idFullAccessControl)}
                disabled={!getPermission(currentUser, "TVMFullStatusControl", "updateAllow")}>
                <PiQrCodeLight className='table__icon-qrcode' />
            </button>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idFullAccessControl)}
                disabled={!getPermission(currentUser, "TVMFullStatusControl", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "TVMFullStatusControl", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    const handleEditClick = (id) => {
        setEditingId(id);
        setShowFullStatusEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await DeleteFullStatusControl(id, currentTenant);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.log(error);
            setDeleteError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirm = (fullStatusControl) => {
        setDeletingFullStatusControl(fullStatusControl);
        setShowDeleteModal(true);
        setDeleteError('');
    };

    const handleQRClick = (id) => {
        setQrId(id);
        setShowFullStatusQR(true);
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.FullStatusControl}</h1>
            <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
            {
                getPermission(currentUser, "TVMFullStatusControl", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowFullStatusNewModal(true)}><AddButton /></div>
            }

            <FullStatusNewModal
                show={showFullStatusNewModal}
                onHide={() => setShowFullStatusNewModal(false)}
                setIsLoading={setIsLoading}
                getData={getData}
                setData={setData}
            />

            <FullStatusEditModal
                show={showFullStatusEditModal}
                onHide={() => setShowFullStatusEditModal(false)}
                setIsLoading={setIsLoading}
                getData={getData}
                setData={setData}
                editingId={editingId}
            />

            {editingId && showFullStatusEditModal ? (
                <FullStatusEditModal
                    show={showFullStatusEditModal}
                    onHide={() => setShowFullStatusEditModal(false)}
                    setIsLoading={setIsLoading}
                    getData={getData}
                    setData={setData}
                    editingId={editingId}
                />
            ) : null}

            {qrId && showFullStatusQR ? (
                <FullStatusQRCode
                    show={showFullStatusQR}
                    onHide={() => setShowFullStatusQR(false)}
                    setIsLoading={setIsLoading}
                    getData={getData}
                    setData={setData}
                    qrId={qrId}
                />
            ) : null}

            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteFullStatusControlMessage + deletingFullStatusControl.accessType}
                    handleDelete={() => handleDeleteClick(deletingFullStatusControl.idFullAccessControl)}
                    error={deleteError}
                />
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>
    );
}

export default FullStatusControl;