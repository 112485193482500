import { Button } from "react-bootstrap";

import { TbLockCancel } from "react-icons/tb";

import './css/AccessDeniedPage.css';
import languages from  './LanguageFile.json';

import { Link } from "react-router-dom";

function AccessDenied() {
    const currentLanguage = localStorage.getItem('currentLanguage');
    const language = languages[currentLanguage][0];
    // const language = JSON.parse(localStorage.getItem('language'));


    // const currentUser = await GetUser();

    return (
        <div className='app-container navbar-open main-container-app'>
            <h1 className='module__title'>{language.AccessDenied}</h1>
            <TbLockCancel className="lock-icon" />
            <Link to="/Home">
                <Button className='general-button primary' >
                    {language.Home}
                </Button>
            </Link>

        </div>

    );
};

export default AccessDenied;