import GetMethod from "../../Hooks/API/GetMethod";

const getDetailInfo = async ({id,isRealTime,toDate,fromDate,tenantsDB, typesOfDetails}) =>{
    const queryParameters = {
        tenantsDB,
        isRealTime,
        toDate,
        fromDate,
        typesOfDetails
    };
    const path = `/dashBoard/TVM/alarms/${id}`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default getDetailInfo;