import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useEffect, useState } from 'react';

import editCountry from '../../api/Location/editCountry';

function CountriesEditModal({ show, onHide, getData, setData, editingCountry }) {
    const [country, setCountry] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const language = JSON.parse(localStorage.getItem('language'));
    const [error, setError] = useState('');

    useEffect(() => {
        setCountry([]);
        setCountry({ ...editingCountry });
    }, [editingCountry]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await editCountry(country);
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.error(error);
            const errorMessage = error?.message || JSON.stringify(error);
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
        setError('');
    };

    return (
        <>
            <Modal centered show={show} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>{language.EditCountry}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='input__label'>{language.Country}</label> <br />
                    <input
                        className='module__input-search'
                        onChange={evt => setCountry({ ...country, name: evt.target.value })}
                        value={country.name}
                    ></input>
                    <label className='input__label'>{language.Abbreviation}</label> <br />
                    <input placeholder={language.EnterCountryAbbreviation}
                        className='module__input-search'
                        onChange={evt => setCountry({ ...country, abbreviation: evt.target.value })}
                        value={country.abbreviation}
                    ></input>
                    <div className='error-alert'>{error}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default CountriesEditModal;