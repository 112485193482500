import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useEffect, useState } from 'react';

import getCountries from '../../api/Location/getCountries';
import getStates from '../../api/Location/getStates';
import editCity from '../../api/Location/editCity';
import ErrorCases from '../../Common/ErrorCases';

function CitiesEditModal({ show, onHide, getData, setData, editingCity, editingCityId }) {
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [error, setError] = useState(null);
    const [countryId, setCountryId] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        setCity([]);
        setCity({ ...editingCity });

        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getCountriesData();
                setCountries(response);
                const statesResponse = await getStatesData();
                setStates(statesResponse);
                const selectedCountry = states.find(state => state.stateId === city.stateId)?.countryId || '';
                setCountryId(selectedCountry);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();

    }, [editingCity]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await editCity(city, editingCityId);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function getCountriesData() {
        const { data, error } = await getCountries(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    async function getStatesData() {
        const { data, error } = await getStates(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const statesSearch = async (tenantsDB, countryId) => {
        setIsLoading(true);
        const data = await getStates(tenantsDB, countryId);
        setStates(data.data);
        setCountryId(countryId);
        setIsLoading(false);
    };

    const handleCancel = () => {
        onHide();
        setEditError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.EditCity}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='input__label'>{language.city}</label> <br />
                    <input
                        className='module__input-search'
                        onChange={evt => setCity({ ...city, name: evt.target.value })}
                        value={city.name}
                    ></input>
                    <label className='input__label'>{language.Abbreviation}</label> <br />
                    <input placeholder={language.EnterCityAbbreviation}
                        className='module__input-search'
                        onChange={evt => setCity({ ...city, abbreviation: evt.target.value })}
                        value={city.abbreviation}
                    ></input>
                    <label className='input__label'>{language.Country}</label> <br />
                    <select className="module__input-search"
                        onChange={evt => statesSearch(currentUser.data.currentTenant, evt.target.value)}
                        value={countryId}
                    >
                        <option value="">{language.SelectCityCountry}</option>
                        {
                            countries.map(item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                        }
                    </select><br />
                    <label>{language.state}</label> <br />
                    <select className="module__input-search"
                        onChange={evt => setCity({ ...city, stateId: evt.target.value })}
                        value={city.stateId}
                    >
                        <option value="">{language.SelectCityState}</option>
                        {
                            states.map(item => {
                                return <option key={item.stateId} value={item.stateId}>{item.stateName}</option>
                            })
                        }
                    </select>
                    <div className='error-alert'>{editError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default CitiesEditModal;