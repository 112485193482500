import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import createDeviceAlarm from '../../../api/DevicesAlarms/createDeviceAlarm';
import getDeviceCodesCatalog from '../../../api/DevicesAlarms/getDeviceCodesCatalog';
import editDeviceAlarm from '../../../api/DevicesAlarms/editDeviceAlarm';
import ErrorCases from '../../../Common/ErrorCases';

function DevicesEditModal({ show, onHide, getData, setData, setIsLoading, editingDevice }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [device, setDevice] = useState([]);
    const [codesCatalog, setCodesCatalog] = useState([]);
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getCodesCatalog();
                setCodesCatalog(response);
                setDevice({
                    name: editingDevice.name,
                    description: editingDevice.descriptionDevice,
                    isActive: editingDevice.isActive,
                    deviceCode: editingDevice.deviceCode,
                    tenantsDB: currentTenant
                });
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, [editingDevice]);

    async function getCodesCatalog() {
        const { data, error } = await getDeviceCodesCatalog(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleCancel = () => {
        onHide();
        setEditError('');
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await editDeviceAlarm(device, editingDevice.idDeviceAlarms);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            onHide();
        } catch (error) {
            console.error(error);
            setEditError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.EditDevice}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterDeviceName}
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setDevice({ ...device, name: evt.target.value })}
                                value={device.name}
                            ></input>
                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.Description}</label> <br />
                            <input placeholder={language.EnterDeviceDescription}
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setDevice({ ...device, description: evt.target.value })}
                                value={device.description}
                            ></input>
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div className='modal__row-aligned-item'>
                            <label className='input__label'>{language.DeviceCode}</label> <br />
                            <select
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setDevice({ ...device, deviceCode: evt.target.value })}
                                value={device.deviceCode}>
                                <option>{language.SelectDeviceCode}</option>
                                {codesCatalog.length > 0 && codesCatalog.map(code => {
                                    return (<option value={code.name}>{code.name}</option>)
                                })}
                            </select>
                        </div>

                        <div className='modal__row-aligned-item'>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={device.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => device.isActive === 1 ? setDevice({ ...device, isActive: 0 }) : setDevice({ ...device, isActive: 1 })} />
                        </div>
                    </div>
                    <div className='error-alert'>{editError}</div>
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button className="general-button danger" onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className="general-button primary" onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DevicesEditModal;