import { fetchAuthSession } from 'aws-amplify/auth';
import { get } from 'aws-amplify/api';
import { signOut } from 'aws-amplify/auth';

async function GetMethod(path, queryParams) {

    try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken.toString();
        const apiName = 'UPA';

        const options = {
            headers: {
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
            response: true,
            queryParams: queryParams
        };

        const restOperation = get({
            apiName,
            path: path,
            options
        });

        const { body } = await restOperation.response;
        const data = await body.json();
        return { data };
    } catch (error) {
        if (error.message.includes('The incoming token has expired') || 
            error.message.includes('Cannot read properties of undefined')) {
            localStorage.setItem('tokenExpired', JSON.stringify(true));
            signOut();
            window.location.reload();
        };
        return { error };
    }
}

const tokenExpired = () => {
    localStorage.setItem('tokenExpired', JSON.stringify(true));
    window.location.reload();
};

export default GetMethod;