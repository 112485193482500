import { fetchAuthSession  } from 'aws-amplify/auth';
import { get } from 'aws-amplify/api';
import GetMethod from '../../Hooks/API/GetMethod';

async function getStates(tenantsDB, countryId) {
    const path = countryId ? `/states?tenantsDB=${tenantsDB}&countryId=${countryId}` : `/states?tenantsDB=${tenantsDB}`;
    try {
        const { data, error } = await GetMethod(path);
        return {data : data, error : undefined};
    } catch (error) {
        let responseError = error.response.message !== undefined? error.response.message : error.response.data.message;
        if(responseError === undefined){
            responseError = error.response.data.error;
        }
        console.error({responseError});
        return {data : undefined, error : responseError};
    }
}

export default getStates;