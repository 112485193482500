import postMethod from "../../Hooks/API/post";

const disableQR = async (tenantsDB, disable, id) => {
    const body = {
        disable,
        tenantsDB: tenantsDB
    };
    const path = `/Tvm/getQR/${id}`;
    try {
        const data = await postMethod(body, path);
        return data;
    } catch (error) {
        console.log(error)
        return error;
    }
}

export default disableQR;