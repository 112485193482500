import postMethod from "../../Hooks/API/post";

async function getReport(body,reportType) {  
    const path = `/CustomReports/customQuery/${reportType}`;
    try {
        const {data,groupData} = await postMethod(body,path);
        return {data,groupData};
    } catch (error) {
        return error;
    }
} 

export default getReport;