import React from "react";
import './css/RoleCard.css'

import { MdAdminPanelSettings } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";


function RoleCard (props){
    const language = JSON.parse(localStorage.getItem('language'));

    return(
        <div className="role-card">
            <div className="role-card__header">
                {/* <MdAdminPanelSettings className="role-card__header-icon"/> */}
                {props.isActive === 1 ? 
                <img src='./img/icons/pages/ActiveRole.svg' className='role-card__header-icon' /> 
                : <img src='./img/icons/pages/InactiveRole.svg' className='role-card__header-icon' />  }
                
                <div className="role-card__header-title">{props.title}</div>
                <img src='./img/icons/pages/EditUser-01.png' className='role-card__edit-icon'/>
            </div>
            <div className="role-card__description">{props.description}</div>
            <div className="role-card__footer">
                <div className="role-card__members"><img src='./img/icons/pages/User Management.svg' className='role-card__footer-icon'/> {props.members} {language.Members}</div>
            </div>
        </div>
    );
}

export default RoleCard;