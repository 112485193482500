import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { Button, ModalBody, ModalFooter, ModalHeader, ModalTitle, Spinner } from "react-bootstrap";
import GetPermission from './GetPermission';
import { Modal } from 'react-bootstrap';

const PrivateRoute = ({ className, moduleName }) => {
    const [authenticated, setAuthenticated] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const location = useLocation();
    const [tokenExpired, setTokenExpired] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const currentUser = await getCurrentUser();
                setAuthenticated(!!currentUser);
                if(authenticated){
                    localStorage.setItem('openSession', JSON.stringify(true));
                }
                const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));
                const permission = GetPermission(currentLocalUser, moduleName, "readAllow");
                setHasPermission(permission);
            } catch (error) {
                setAuthenticated(false);
                setHasPermission(false);
            }
        };

        checkAuthentication();

    }, [location]);

    if (authenticated === null) {
        return (
            <div className='spinner-container'>
                <Spinner animation="border" variant="primary" className='spinner' />
            </div>
        )
    }

    if (authenticated && hasPermission) {
        return <div className={className}><Outlet /></div>
    }
    else if (authenticated && !hasPermission) {
        return <Navigate to="/access-denied" />;
    }
    else if (!authenticated) {
        const isLogout = JSON.parse(localStorage.getItem('logout'));
        const isOpenSession = JSON.parse(localStorage.getItem('openSession'));
        if(!isLogout && isOpenSession) {
            localStorage.setItem('tokenExpired', JSON.stringify(true));
        };
        localStorage.removeItem('logout');
        localStorage.removeItem('openSession');
        navigate("/login");
        navigate(0);
    };
};

export default PrivateRoute;