import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import editCurrency from '../../api/Currency/editCurrency';

import { getAllISOCodes } from 'iso-country-currency';
import ErrorCases from '../../Common/ErrorCases';

function CurrencyEditModal({ show, onHide, getData, setData, setIsLoading, editingCurrency, editingCurrencyId }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const currentUserId = currentUser.data.userData.id;
    const [currency, setCurrency] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        setCurrency({
            ...currency,
            name: editingCurrency.name,
            currency: editingCurrency.currency,
            isActive: editingCurrency.isActive,
            sign: editingCurrency.sign
        });
    }, [editingCurrency]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await editCurrency(currency, editingCurrencyId);
            if (response.error !== '') {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
            setCurrency({ ...currency, name: "", currency: "", isActive: 0, sign: "" });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
        setCurrency({ ...currency, name: "", currency: "", isActive: 0, sign: "" });
        setEditError('');
    };

    const isoCodes = getAllISOCodes();

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.EditCurrency}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterCurrencyName}
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setCurrency({ ...currency, name: evt.target.value })}
                                value={currency.name}
                            ></input>
                        </div>

                        <div>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={currency.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={evt => currency.isActive === 1 ? setCurrency({ ...currency, isActive: 0 }) : setCurrency({ ...currency, isActive: 1 })} />
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div >
                            <label className='input__label'>{language.Currency}</label> <br />
                            <select onChange={evt => setCurrency({ ...currency, currency: evt.target.value })}
                                value={isoCodes.find(curr => curr.currency === currency.currency)?.currency}
                                className='module__input-search'>
                                <option >{language.SelectACurrency}</option>
                                {isoCodes.map(code => {
                                    return (<option key={code.iso} value={code.currency}>{code.countryName}: {code.currency}</option>);
                                })}
                            </select>
                        </div>

                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.Sign}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input input-numeric'
                                onChange={evt => setCurrency({ ...currency, sign: evt.target.value })}
                                value={currency.sign}
                            ></input>
                        </div>
                    </div>
                    <div className='error-alert'>{editError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CurrencyEditModal;