import postMethod from "../../Hooks/API/post";

async function EditEmailAlarm(email, id) {
    const path = `/EmailAlarms/edit/${id}`;
    try {
        const { data, error } = await postMethod(email, path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default EditEmailAlarm;