import postMethod from "../../Hooks/API/post";

async function CreateCommand(command) {
    const path = '/CommandsDevice/add';
    try {
        const { data, error } = await postMethod(command, path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default CreateCommand;