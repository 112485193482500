import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function EditRestrictionZone(zone, zoneId) {
    const path = `/Products/restrictedZone/edit/${zoneId}`;
    try {
        const data = await postMethod(zone, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
};

export default EditRestrictionZone;