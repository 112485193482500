

const GetPermission = (user, currentModule, action) => {
    try {
        const modulePermission = user.data.permission.find(item => item.nameModule === currentModule);
        const actionPermission = modulePermission[action];
        return actionPermission === 1;
    } catch (error) {
        return false;
    }    
};



export default GetPermission;