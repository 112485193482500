import { fetchAuthSession  } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import postMethod from '../../Hooks/API/post';

async function CreateRole(dataRole) {  
    const path = '/Roles/add';
    try {
        const data = await postMethod(dataRole, path);
        return {data : data, error : undefined};
    } catch (error) {
        const responseError = error.response.message !== undefined? error.response.message : error.response.data.error;
        console.error(responseError);
        return {data : '', error : responseError};
    }
} 

export default CreateRole;
