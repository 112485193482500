import * as React from "react";
import { useState, useEffect } from 'react';

import "./css/Auth.css";

import { BsKey } from "react-icons/bs";
import { BsLock } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from "react-bootstrap";

import { confirmResetPassword, resetPassword } from "aws-amplify/auth";

import { useNavigate } from 'react-router-dom';
import CustomAlert from "../Common/CustomAlert";
import languages from './LoginLanguage.json';

function ResetPassword() {
  const [code, setCode] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [username, setUsername] = useState('');
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const navigate = useNavigate();
  const currentLanguage = localStorage.getItem('currentLanguage');
  const language = languages[currentLanguage][0];
  const [isLoading, setIsLoading] = useState(false);

  const togglePassword = () => {
    var passwordInput1 = document.getElementById("password1");
    var passwordInput2 = document.getElementById("password2");

    if (passwordInput1.type === "password") {
      passwordInput1.type = "text";
      passwordInput2.type = "text";
      setIsPasswordHidden(false);
    } else {
      passwordInput1.type = "password";
      passwordInput2.type = "password";
      setIsPasswordHidden(true);
    }
  }

  const handleResetCode = async () => {
    setIsLoading(true);
    try {
      if(username === '')
      {
        navigate('/forgot-password');
      }
      if(code != '' && password1 === password2)
      {
        setUsername(username);
        const response = await confirmResetPassword({username:username, newPassword:password1, confirmationCode:code});
        navigate('/');
        navigate(0);
      } else {
        setShowAlert1(true);
      }
    } catch (error) {
      setShowAlert2(true);
    }
    setIsLoading(false);
  }

  const handleResendCode = async () => {
    setIsLoading(true);
    try {
      if(username === '')
      {
        navigate('/forgot-password');
      }
      const response = await resetPassword({username});
    } catch (error) {
      //console.log(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      setUsername(savedUsername);
    }
    setIsLoading(false);
  }, []);

  const setPassword = () =>{};

  return (
    <>
      <div className="main-container">
        
        <img
              loading="lazy"
              srcSet="/img/logoBEATT.png"
              className="login__logo"
            />
        <div className="login__container">
          <div className="login__inputs-group">
            <div className="login__input">
              <img src='./img/icons/login/Username.png' className='login__icon'/>
              <Form.Control className="login__form" type="text" placeholder={language.Code}
                                onChange={evt => setCode(evt.target.value)} />
            </div>
            <div className="login__input">
              <img src='./img/icons/login/Password.png' className='login__icon'/>
              <input className="login__form" id="password1" type="password" minLength="8" placeholder={language.Password}
                                    onChange={evt => setPassword1(evt.target.value)} />
              {
                      isPasswordHidden ? (
                        <img src='./img/icons/login/Disguise-01.png' className='login__icon-right' 
                        onClick={togglePassword}/>
                      ) : (
                          <BsEye className='login__icon-right' onClick={togglePassword} />
                      )
                  }
            </div>
            <div className="login__input">
              <img src='./img/icons/login/Password.png' className='login__icon'/>
              <input className="login__form" id="password2" type="password" minLength="8" placeholder={language.ConfirmPassword}
                                    onChange={evt => setPassword2(evt.target.value)}  />
              {
                      isPasswordHidden ? (
                        <img src='./img/icons/login/Disguise-01.png' className='login__icon-right' 
                        onClick={togglePassword}/>
                      ) : (
                          <BsEye className='login__icon-right' onClick={togglePassword} />
                      )
                  }
            </div>
          </div>

          <div className="button-light-blue" onClick={handleResetCode}>{language.SUBMIT}</div>

        </div>
        <div className="login__bottom--centered">
          <div onClick={handleResendCode} className="login__bottom-forgot">{language.ResendCode}</div>
        </div>
        {
          showAlert1 &&
          <CustomAlert message={language.CheckCodeAlert}/>
        }
        
        {
          showAlert2 &&
          <CustomAlert message={language.PasswordIncorrectAlert}/>
        }
        
      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
    </>
  );
}

export default ResetPassword;
