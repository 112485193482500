import { useState, useMemo, useEffect } from "react";

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import getPermission from "../../auth/GetPermission";
import deleteRestrictionZone from "../../api/Fares/deleteRestrictionZone";
import GeneralTable from "../../Hooks/Table/GeneralTable";
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from "../../Common/ErrorCases";

function RestrictionZoneTable({ restrictedZones, setEditingRestrictionZone, setShowRestrictionZoneEditModal, setEditedZoneId, setIsLoading }) {
    const [data, setData] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteRestrictionZoneModal, setShowDeleteRestrictionZoneModal] = useState(false);
    const [deletingRestrictionZone, setDeletingRestrictionZone] = useState(false);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        setData(restrictedZones);
    }, [restrictedZones]);

    const columns = useMemo(
        () => [
            {
                header: language.ZoneOrigin,
                accessorKey: 'zoneIdOrigin',
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.ZoneDestiny,
                accessorKey: 'zoneIdDestiny',
                muiTableBodyCellProps: {
                    align: 'right',
                },
            }
        ],
        [],
    );

    const handleEditClick = (zone) => {
        setEditingRestrictionZone(zone);
        setShowRestrictionZoneEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await deleteRestrictionZone(id, currentTenant);
            if (response.error !== '') {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            setEditedZoneId(id);
            setShowDeleteRestrictionZoneModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }


    };

    const deleteConfirm = (restrictionZone) => {
        setDeleteError('');
        setDeletingRestrictionZone(restrictionZone);
        setShowDeleteRestrictionZoneModal(true);
    };

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original)}
                disabled={!getPermission(currentUser, "FareProducts", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "FareProducts", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <>
            <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
            {
                showDeleteRestrictionZoneModal &&
                <DeleteModal
                    show={showDeleteRestrictionZoneModal}
                    onHide={() => setShowDeleteRestrictionZoneModal(false)}
                    message={language.DeleteProductMessage + deletingRestrictionZone.zoneIdOrigin + ' - ' + deletingRestrictionZone.zoneIdDestiny}
                    handleDelete={() => handleDeleteClick(deletingRestrictionZone.idRestrictedZone)}
                    error={deleteError}
                />
            }
        </>
    );
}

export default RestrictionZoneTable;