import postMethod from "../../Hooks/API/post";

async function userControlTenants(body) {  
    const path = '/Users/control';
    try {
        const data = await postMethod(body,path);
        return data;
    } catch (error) {
        return error;
    }
} 

export default userControlTenants;