import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import createStation from '../../api/Location/createStation';
import ErrorCases from '../../Common/ErrorCases';

function StationsNewModal({ show, onHide, getData, setData }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [station, setStation] = useState({ tenantsDB: currentUser.data.currentTenant, isActive: 1 });
    const [isLoading, setIsLoading] = useState(false);
    const language = JSON.parse(localStorage.getItem('language'));
    const [error, setError] = useState('');

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await createStation(station);
            if(response.error !== ''){
                const error = ErrorCases(response.error);
                setError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleActiveClick = () => {
        station.isActive === 1 ? setStation({ ...station, isActive: 0 }) : setStation({ ...station, isActive: 1 });
    };

    const handleCancel = () => {
        onHide();
        setError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewStation}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='input__label'>{language.StationName}</label> <br />
                    <input placeholder={language.EnterNewStationName}
                        className='module__input-search'
                        onChange={evt => setStation({ ...station, nameStation: evt.target.value })}
                    ></input>
                    <label className='input__label'>{language.StationNumber}</label> <br />
                    <input placeholder={language.EnterStationNumber}
                        className='module__input-search'
                        onChange={evt => setStation({ ...station, consecutiveNumber: evt.target.value })}
                        type='number'
                    ></input>
                    <label className='input__label'>{language.Active}</label> <br />
                    <img src={station.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox'
                        onClick={handleActiveClick} /><br />
                    {/*<label className='input__label'>{language.Screen}</label> <br />
                <input placeholder={language.EnterScreenNumber}
                    className='module__input-search'
                    onChange={evt => setStation({...station, screen: evt.target.value})}
                    type='number'
                ></input>*/}
                    <label className='input__label'>{language.Position}</label> <br />
                    <input placeholder={language.EnterStationPosition}
                        className='module__input-search'
                        onChange={evt => setStation({ ...station, position: evt.target.value })}
                        type='number'
                    ></input>
                    <label className='input__label'>{language.ZoneNumber}</label> <br />
                    <input placeholder={language.EnterStationZone}
                        className='module__input-search'
                        onChange={evt => setStation({ ...station, zoneId: evt.target.value })}
                        type='number'
                    ></input>
                    <div className='error-alert'>{error}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default StationsNewModal;