import postMethod from "../../Hooks/API/post";

async function EditCurrency(currency, id) {
    const path = `/Currencies/edit/${id}`;
    try {
        const { data, error, message } = await postMethod(currency, path);
        return { data, error, message };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default EditCurrency;