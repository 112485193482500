import postMethod from "../../Hooks/API/post";

async function CreateCustomAlarm(alarm) {
    const path = '/CustomAlarms/add';
    try {
        const { data, error } = await postMethod(alarm, path);
        return { data, error };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default CreateCustomAlarm;