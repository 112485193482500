import GetMethod from "../../Hooks/API/GetMethod";

async function dropdownReportsType({tenantsDB,reportType}) {  
    const path = '/CustomReports/GetDropdownCR';
    const queryParameters = {
        tenantsDB,
        reportType
    };

    try {
        const data = await GetMethod(path,queryParameters);
        return data;
    } catch (error) {
        return error;
    }
} 

export default dropdownReportsType;