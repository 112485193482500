import GetMethod from "../../Hooks/API/GetMethod";

const getAllCustomAlarms = async (tenantsDB) =>{
    const queryParameters = {
        tenantsDB
    };
    const path = `/CustomAlarms/GetAllCustomAlarms`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default getAllCustomAlarms;