import postMethod from "../../Hooks/API/post";

async function CreateEmailAlarm(email) {
    const path = '/EmailAlarms/add';
    try {
        const { data, error } = await postMethod(email, path);
        return { data, error };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default CreateEmailAlarm;