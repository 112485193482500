import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './css/DeleteModal.css';

function DeleteModal({ message, handleDelete, onHide, show, error }) {
    const language = JSON.parse(localStorage.getItem('language'));

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                <div className='delete-message'>{message}</div>
                <div className='error-alert'>{error}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="general-button primary" onClick={onHide}>
                    {language.Cancel}
                </Button>
                <Button className="general-button danger" onClick={handleDelete}>
                    {language.Delete}
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default DeleteModal; 