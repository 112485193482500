import postMethod from "../../Hooks/API/post";

async function EditTenant(tenant, id) {
    const path = `/Tenants/edit/${id}`;
    try {
        const { data, error } = await postMethod(tenant, path);
        
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default EditTenant;
