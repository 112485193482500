import postMethod from "../../Hooks/API/post";

async function EditDeviceAlarm(alarm, id) {
    const path = `/DevicesAlarms/edit/${id}`;
    try {
        const { data, error } = await postMethod(alarm, path);
        return { data, error };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default EditDeviceAlarm;