import GetMethod from "../../Hooks/API/GetMethod";

async function getCustomReportById({tenantsDB,id}) {  
    const path = `/CustomReports/${id}`;
    const queryParameters = {
        tenantsDB
    };

    try {
        const data = await GetMethod(path,queryParameters);
        return data;
    } catch (error) {
        return error;
    }
} 

export default getCustomReportById;