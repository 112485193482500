import { Box } from "@mui/material";
import GeneralTable from "../../../../Hooks/Table/GeneralTable";

const formatDate = (isoDate,languageCode) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString(languageCode, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });
};

const DashBoardTable = ({device_data,language}) =>{
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;


    const columns_table = [
        {
            header: language.TVMNumber,
            accessorKey: 'TVMNumber',
        },
        {
            header: language.StatusMessage,
            accessorKey: 'StatusMessage', 
        },
        {
            header: language.dateTime,
            accessorKey: 'dateTime',
            hidden: true,
            Cell:({renderedCellValue}) => (
                <Box>
                    {formatDate(renderedCellValue,languageCode)}
                </Box> 
            )
        },
        {
            header: language.statusType,
            accessorKey: 'statusType',
            hidden: true,
        },
        {
            header: language.StationName,
            accessorKey: 'stationName',
        },
    ]

    return (
        <GeneralTable 
            data={device_data} 
            columns_table={columns_table} 
            actions={false}
        />
    );
}

export default DashBoardTable;