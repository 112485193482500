import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ZoneNewModal.css';
import createRestrictionZone from '../../api/Fares/createRestrictionZone';
import ErrorCases from '../../Common/ErrorCases';

function RestrictionZoneNewModal({ show, onHide, zones, setEditedZoneId }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [isLoading, setIsLoading] = useState(false);
    const [zone, setZone] = useState({ zoneIdOrigin: 1, zoneIdDestiny: 1, tenantsDB: currentUser.data.currentTenant });
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    useEffect(() => {

    }, [zones]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await createRestrictionZone(zone);
            if(response.error) {
                const error = ErrorCases(response.error);
                setCreateError(error);
                return;
            }
            setEditedZoneId(zone.zoneIdOrigin);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setCreateError('');
        onHide();
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewRestrictionZone}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='restriction-zone__inputs-row'>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneOrigin}</label> <br />
                            <select className='module__input-search' onChange={evt => setZone({ ...zone, zoneIdOrigin: evt.target.value })}>
                                {zones.length > 0 &&
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneDestiny}</label> <br />
                            <select className='module__input-search' onChange={evt => setZone({ ...zone, zoneIdDestiny: evt.target.value })}>
                                {zones.length > 0 &&
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='error-alert'>{createError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default RestrictionZoneNewModal;