import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import getAllDevicesAlarms from '../../../api/DevicesAlarms/getAllDevicesAlarms';
import getAllEmailAlarms from '../../../api/EmailsAlarms/getAllEmailAlarms';
import createCustomAlarm from '../../../api/CustomAlarms/createCustomAlarm';
import ErrorCases from '../../../Common/ErrorCases';

function CustomAlarmsNewModal({ show, onHide, getData, setData, setIsLoading }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [customAlarm, setCustomAlarm] = useState({
        name: '',
        eventP: '',
        parameter: "None",
        deviceId: '',
        color: '',
        isActive: 1,
        subject: '',
        content: '',
        sendEmail: 1,
        tenantsDB: currentTenant
    });
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [emailAssignment, setEmailAssignment] = useState([]);
    const [emailUnassigned, setEmailUnassigned] = useState([]);
    const [unassignedSelected, setUnassignedSelected] = useState([]);
    const [assignedSelected, setAssignedSelected] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getDevicesData();
                setDevices(response);
                const newEmails = await getEmails();
                setEmailUnassigned(newEmails);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getDevicesData() {
        const { data, error } = await getAllDevicesAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getEmails() {
        const { data, error } = await getAllEmailAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleCancel = () => {
        onHide();
        setCreateError('');
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const emails = addEmails(emailAssignment);
            const newAlarm = { ...customAlarm, AssignedEmailList: emails }
            const response = await createCustomAlarm(newAlarm);
            if(response.error !== undefined) {
                const error = ErrorCases(response.error);
                setCreateError(error);
                return;
            }
            setEmailAssignment([]);
            const data = await getData();
            setData(data);
            onHide();
            setCreateError('');
        } catch (error) {
            console.error(error);
            setCreateError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    function addEmails(assignedEmails) {
        const newArray = assignedEmails.map(email => ({ idEmailAlarms: parseInt(email.idEmailAlarms) }));
        return newArray;
    }

    const handleChangeUnassigned = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setUnassignedSelected([...unassignedSelected, parseInt(value)]);
        } else {
            setUnassignedSelected(unassignedSelected.filter(item => item !== parseInt(value)));
        }
    };

    const handleChangeAssigned = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setAssignedSelected([...assignedSelected, parseInt(value)]);
        } else {
            setAssignedSelected(assignedSelected.filter(item => item !== parseInt(value)));
        }
    };

    const assignEmails = () => {
        unassignedSelected.forEach(id => {
            const selectedEmail = emailUnassigned.find(email => email.idEmailAlarms === id);
            if (selectedEmail) {
                setEmailAssignment(emailAssignment => [...emailAssignment, selectedEmail]);
            }
        });

        setEmailUnassigned(emailUnassigned.filter(item => !unassignedSelected.includes(item.idEmailAlarms)));
        setUnassignedSelected([]);
    };

    const unassignEmails = () => {
        assignedSelected.forEach(id => {
            const selectedEmail = emailAssignment.find(email => email.idEmailAlarms === id);
            if (selectedEmail) {
                setEmailUnassigned(emailUnassigned => [...emailUnassigned, selectedEmail]);
            }
        });

        setEmailAssignment(emailAssignment.filter(item => !assignedSelected.includes(item.idEmailAlarms)));
        setAssignedSelected([]);
    };

    return (
        <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.NewCustomAlarm}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className='inputs-row'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterAlarmName}
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setCustomAlarm({ ...customAlarm, name: evt.target.value })}
                            ></input>
                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.Device}</label> <br />

                            <select onChange={evt => setCustomAlarm({ ...customAlarm, deviceId: evt.target.value })}
                                className='module__input-search modal__text-box email-input'>
                                <option>{language.SelectDevice}</option>
                                {devices.map(device => {
                                    return (
                                        <option value={device.idDeviceAlarms}>{device.name}</option>
                                    );
                                })}
                            </select>
                        </div>

                        <div>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={customAlarm.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='checkbox'
                                onClick={() => customAlarm.isActive === 1 ? setCustomAlarm({ ...customAlarm, isActive: 0 })
                                    : setCustomAlarm({ ...customAlarm, isActive: 1 })} />
                        </div>

                        <div>
                            <label className='input__label'>{language.Color}</label> <br />
                            <Form.Control
                                type="color"
                                id="customAlarmColor"
                                title={language.ChooseColor}
                                onChange={evt => setCustomAlarm({ ...customAlarm, color: evt.target.value })}
                            />
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>

                        <div className=''>
                            <label className='input__label'>{language.Event}</label> <br />
                            <select onChange={evt => setCustomAlarm({ ...customAlarm, eventP: evt.target.value })}
                                className='module__input-search modal__text-box email-input'>
                                <option>{language.SelectEvent}</option>
                                <option value={"Equal to"}>{language.EqualTo}</option>
                                <option value={"More than"}>{language.MoreThan}</option>
                                <option value={"Full"}>{language.Full}</option>
                                <option value={"Empty"}>{language.Empty}</option>
                                <option value={"Out Paper"}>{language.OutPaper}</option>
                                <option value={"Low Paper"}>{language.LowPaper}</option>
                                <option value={"Online"}>{language.Online}</option>
                                <option value={"Offline"}>{language.Offline}</option>
                            </select>
                        </div>

                        {(customAlarm.eventP === "Equal to" || customAlarm.eventP === "More than") &&
                            <div className='input-name'>
                                <label className='input__label'>{language.Parameter}</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => setCustomAlarm({ ...customAlarm, parameter: evt.target.value })}
                                ></input>
                            </div>
                        }

                    </div>

                    <hr />
                    <div className='modal__subtitle'>{language.EmailData}</div>

                    <div className='input-name'>
                        <label className='input__label'>{language.Subject}</label> <br />
                        <input
                            className='module__input-search modal__text-box'
                            onChange={evt => setCustomAlarm({ ...customAlarm, subject: evt.target.value })}
                        ></input>
                    </div>

                    <div className='input-name'>
                        <label className='input__label'>{language.Content}</label> <br />
                        <textarea
                            onChange={evt => setCustomAlarm({ ...customAlarm, content: evt.target.value })}
                            rows={3}
                            cols={71}
                            className='modal__text-box'
                        />
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div>
                            <label className='input__label'>{language.SendEmail}</label> <br />
                            <img src={customAlarm.sendEmail === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='checkbox'
                                onClick={() => customAlarm.sendEmail === 1 ? setCustomAlarm({ ...customAlarm, sendEmail: 0 })
                                    : setCustomAlarm({ ...customAlarm, sendEmail: 1 })} />
                        </div>

                    </div>

                    <hr />
                    <div className='modal__subtitle'>{language.AssignEmails}</div>
                    <div className='inputs-row__no-justified email-assign__row' >

                        <div>
                            <Card style={{ width: '23rem' }}>
                                <Card.Header>{language.AvailableEmails}</Card.Header>
                                <ListGroup variant="flush">
                                    {emailUnassigned.map(email => {
                                        return (
                                            <ListGroup.Item><input type="checkbox"
                                                value={email.idEmailAlarms}
                                                onChange={handleChangeUnassigned}
                                                checked={unassignedSelected.includes(email.idEmailAlarms)}
                                                className='email-assign__checkbox'
                                            />
                                                {email.email}</ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </Card>
                            <Button className='small-button primary' onClick={assignEmails}>
                                {language.Assign}
                            </Button>
                        </div>

                        <div>
                            <Card style={{ width: '23rem' }}>
                                <Card.Header>{language.AssignedEmails}</Card.Header>
                                <ListGroup variant="flush">
                                    {emailAssignment.map(email => {
                                        return (
                                            <ListGroup.Item><input type="checkbox"
                                                value={email.idEmailAlarms}
                                                onChange={handleChangeAssigned}
                                                checked={assignedSelected.includes(email.idEmailAlarms)}
                                                className='email-assign__checkbox'
                                            />
                                                {email.email}</ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </Card>
                            <Button className='small-button primary' onClick={unassignEmails}>
                                {language.Unassign}
                            </Button>
                        </div>

                    </div>
                </>
                <div className='error-alert'>{createError}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className='general-button primary' onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default CustomAlarmsNewModal;