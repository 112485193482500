import DeleteMethod from "../../Hooks/API/DeleteMethod"

async function deleteCustomReportById({tenantsDB,id}){
    
    const path = `/CustomReports/${id}`;
    const queryParameters = {
        tenantsDB
    };

    try {
        const {data,error} = await DeleteMethod(path,queryParameters);
        return {data,error};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default deleteCustomReportById;