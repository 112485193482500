import postMethod from "../../Hooks/API/post";

async function CreateTenant(tenant) {
    const path = '/Tenants/add';
    try {
        const { data, error } = await postMethod(tenant, path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default CreateTenant;
