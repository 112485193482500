import postMethod from "../../Hooks/API/post";

async function generalDashBoard({tenantsDB,isRealTime,toDate,fromDate}) {  
    const path = '/dashBoard/TVM/alarms';
    const body = 
    {
        tenantsDB,
        isRealTime,
        toDate,
        fromDate
    }

    try {
        const data = await postMethod(body,path);
        return data;
    } catch (error) {
        return error;
    }
} 

export default generalDashBoard;