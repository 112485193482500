import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ZoneNewModal.css';
import editRestrictionZone from '../../api/Fares/editRestrictionZone';
import ErrorCases from '../../Common/ErrorCases';

function RestrictionZoneEditModal({ show, onHide, zones, setEditedZoneId, editingRestrictionZone }) {
    const [isLoading, setIsLoading] = useState(false);
    const [zone, setZone] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        setZone({ zoneIdOrigin: editingRestrictionZone.zoneIdOrigin, zoneIdDestiny: editingRestrictionZone.zoneIdDestiny });
    }, [editingRestrictionZone]);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await editRestrictionZone(zone, editingRestrictionZone.idRestrictedZone);
            if (response.error !== '') {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            setEditedZoneId(editingRestrictionZone.idRestrictedZone);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
        setEditError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.EditRestrictionZone}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='restriction-zone__inputs-row'>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneOrigin}</label> <br />
                            <select className='module__input-search'
                                onChange={evt => setZone({ ...zone, zoneIdOrigin: evt.target.value })}
                                value={zone.zoneIdOrigin}>
                                {zones.length > 0 &&
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneDestiny}</label> <br />
                            <select className='module__input-search'
                                onChange={evt => setZone({ ...zone, zoneIdDestiny: evt.target.value })}
                                value={zone.zoneIdDestiny}>
                                {zones.length > 0 &&
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='error-alert'>{editError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default RestrictionZoneEditModal;