import React from "react";
import { VscError } from "react-icons/vsc";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineWarningAmber } from "react-icons/md";
import { GoKebabHorizontal } from "react-icons/go";
import { AiOutlineDollarCircle } from "react-icons/ai";
import "./Cards.css";

const formatCurrency = (value,languageCode,currency) => {
    return new Intl.NumberFormat(languageCode, {
        style: 'currency',
        currency: currency.currency,
    }).format(value);
};

const CardFinancial = ({name,total,typeIcon,id,languageCode,currency,language, isDragging, isDraggable}) =>{
    let icon;
    let color;
    switch (typeIcon) {
        case 'Financial':
            color = '#009A20';
            icon = <IoIosCheckmarkCircleOutline 
                    key={id} 
                    className="card-financial-icon" 
                    size={40} 
                    style={{ fill: color }}/>;
            total = formatCurrency(total,languageCode,currency);
        break;
        case 'Count':
            color = '#009A20';
            icon = <MdOutlineWarningAmber 
                    key={id} 
                    className="card-financial-icon" 
                    size={40} 
                    style={{ fill: color }}/>;
        break;
        default:
            color = '#009A20';
            icon = <AiOutlineDollarCircle
                key={id}  
                className="card-financial-icon" 
                size={40} 
                style={{ fill: color }} />;
            total = formatCurrency(total,languageCode,currency);
    }

    return (
        <section className={`card-container ${isDragging? 'card-container-drag':'', isDraggable? '':'card-container-isDrag'}`}>
            <div className="card-alert-color" style={{ backgroundColor: color }} />
                <div className="card-information">
                    <div className="card-alert">
                        {icon}
                    </div>
                    <div>
                        <p className="card-title">{language[name]}</p>
                        <p className="card-status">Total: {total}</p>
                    </div>
                </div>
                <div className="card-menu-div">
                    <GoKebabHorizontal className="card-menu" size={20} />
                </div>
            </section>
    );
}

export default CardFinancial;