import React from 'react';
import Table from 'react-bootstrap/Table';
import './styles.css';

const DashboardTableTotalAlarm = ({ device_data, header, language }) => {

    const columns_table = [];
    const columns_color = [];

    const hexToRgba = (hex, alpha) => {
        const bigint = parseInt(hex.replace('#', ''), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
    
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    if(device_data.length > 0){
        columns_table.push({
            header: language.Active,
            accessorKey: 'Active'
        });
        columns_table.push({
            header: language.Total,
            accessorKey: 'Total'
        });
        columns_table.push({
            header: language.Alarms,
            accessorKey: 'totalAlarm'
        });
    }

    header.forEach(item => {
        if(item.deviceCode !== 'TVMInService'){
            columns_table.push({
                header: language[item.deviceCode],
                accessorKey: item.deviceCode
            });

            const colorKey = `${item.deviceCode}_color`;
            columns_color.push({
                [item.deviceCode]:colorKey
            });
        }
    });

    const renderTableHeader = () => (
        <thead>
            <tr>
                {columns_table.map((column, index) => (
                    <th key={index} className='table-header'>{column.header}</th>
                ))}
            </tr>
        </thead>
    );

    const renderTableBody = () => (
        <tbody>
            {device_data.length > 0 && device_data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {columns_table.map((column, colIndex) => {
                        const colorKey = `${column.accessorKey}_color`;
                        const cellColor = row[colorKey];
                        const rgbaColor = cellColor ? hexToRgba(cellColor, 0.45) : 'transparent';
                        return (
                            <td
                                key={colIndex}
                                className='table-cell'
                                style={{ background : rgbaColor }}
                            >
                                {row[column.accessorKey]}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </tbody>
    );

    return (
        <Table responsive bordered   size="sm">
            {renderTableHeader()}
            {renderTableBody()}
        </Table>
    );
}

export default DashboardTableTotalAlarm;
