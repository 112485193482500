function ErrorCases(error) {
    const language = JSON.parse(localStorage.getItem('language'));
    let errorMessage = '';

    if (typeof error === 'string') {
        errorMessage = error;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else {
        errorMessage = JSON.stringify(error);
    }

    if (errorMessage.includes("Cannot delete or update a parent row: a foreign key constraint fails")) {
        return language.DeleteError;
    } if (errorMessage.includes("Code Doesn’t Exist or is invalid")) {
        return language.QRError;
    }
    else {
        return errorMessage;
    }
}

export default ErrorCases;