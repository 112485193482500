import * as React from "react";
import { useState, useEffect } from 'react';

import {
  confirmSignIn,
  fetchUserAttributes,
  getCurrentUser
} from 'aws-amplify/auth';

import "./css/Auth.css";

import { BsKey } from "react-icons/bs";
import { BsLock } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { BsEye } from "react-icons/bs";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";

import SendEmail from "../api/EMSuser/sendEmail";
import languages from './LoginLanguage.json';
import CustomAlert from "../Common/CustomAlert";

function NewPassword() {
  const navigate = useNavigate()
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const currentLanguage = localStorage.getItem('currentLanguage');
  const language = languages[currentLanguage][0];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));

    ((authenticated && currentLocalUser.data.tenantsUser.length === 1) || (authenticated && currentLocalUser.data.currentTenant !== '' && currentLocalUser.data.tenantsUser.length > 1))
      && navigate('/Home');

    (authenticated && currentLocalUser.data.currentTenant === '' && currentLocalUser.data.tenantsUser.length > 1)
      && navigate('/select-tenant');

    setIsLoading(true);
    checkAuthentication();
    setIsLoading(false);
  }, [authenticated]);

  const checkAuthentication = async () => {
    try {
      const currentUser = await getCurrentUser();
      setAuthenticated(!!currentUser);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  const togglePassword = () => {
    var passwordInput1 = document.getElementById("password1");
    var passwordInput2 = document.getElementById("password2");

    if (passwordInput1.type === "password") {
      passwordInput1.type = "text";
      passwordInput2.type = "text";
      setIsPasswordHidden(false);
    } else {
      passwordInput1.type = "password";
      passwordInput2.type = "password"
      setIsPasswordHidden(true);
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (password1 != '' && password1 === password2) {
        var response = await confirmSignInNewPassword(password1);
        const userAttributes = await fetchUserAttributes();
        if (response.nextStep.signInStep === 'DONE' && (!userAttributes.email_verified || userAttributes.email_verified === 'false')) {
          const response = await SendEmail();
          navigate('/email-code');
        }
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert2(true);
    }
    setIsLoading(false);
  }

  async function confirmSignInNewPassword(newPassword) {
    return await confirmSignIn({
      challengeResponse: newPassword
    });
  }

  return (
    <>
      <div className="main-container">
        <img
          loading="lazy"
          srcSet="./img/logoBEATT.png"
          className="login__logo"
        />
        <div className="login__container">
          <div className="login__inputs-group">
            <div className="login__input">
              <img src='./img/icons/login/Password.png' className='login__icon' />
              <input className="login__form" id="password1" type="password" minLength="8" placeholder={language.NewPassword}
                onChange={evt => setPassword1(evt.target.value)} />
              {
                isPasswordHidden ? (
                  <img src='./img/icons/login/Disguise-01.png' className='login__icon-right'
                    onClick={togglePassword} />
                ) : (
                  <BsEye className='login__icon-right' onClick={togglePassword} />
                )
              }
            </div>

            <div className="login__input">
              <img src='./img/icons/login/Password.png' className='login__icon' />
              <input className="login__form" id="password2" type="password" minLength="8" placeholder={language.ConfirmPassword}
                onChange={evt => setPassword2(evt.target.value)} />
              {
                isPasswordHidden ? (
                  <img src='./img/icons/login/Disguise-01.png' className='login__icon-right'
                    onClick={togglePassword} />
                ) : (
                  <BsEye className='login__icon-right' onClick={togglePassword} />
                )
              }
            </div>
          </div>

          <div className="button-light-blue" onClick={handleSubmit}>{language.SUBMIT}</div>

        </div>

        <div className="login__bottom--centered">
          <Link to="/login"><div className="login__bottom-forgot">{language.BackToLogin}</div></Link>
        </div>

        {
          showAlert &&
          <CustomAlert message={language.PasswordDoesntMatch} />
        }

        {
          showAlert2 &&
          <CustomAlert message={language.PasswordIncorrectAlert} />
        }
      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
    </>
  );
}

export default NewPassword;