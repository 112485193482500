import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ProductsNewModal.css';
import editProduct from '../../api/Fares/editProduct';
import getLanguageProducts from '../../api/Fares/getLanguageProducts';

import CurrencyInput from 'react-currency-input-field';
import ErrorCases from '../../Common/ErrorCases';

function ProductsEditModal({ show, onHide, getData, setData, editingProduct, editingProductId }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [product, setProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [languageData, setLanguageData] = useState([]);
    const [error, setError] = useState("");
    const codeLanguage = currentUser.data.userData.languageId === 1 ? "EN" : currentUser.data.userData.languageId === 2 && "ES";
    const [alertMessage, setAlertMessage] = useState('');
    const [formattedPrice, setFormattedPrice] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getLanguageData();
                setLanguageData(response);
                setFormattedPrice(editingProduct.price);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }

        setProduct([]);
        setProduct({ ...editingProduct });
        fetchData();
    }, [editingProduct]);

    async function getLanguageData() {
        const response = await getLanguageProducts({ codeLanguage: codeLanguage, fileName: "Products", tenantsDB: currentUser.data.currentTenant });
        return response.data;
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const editedProduct = product;
            editedProduct.price = formattedPrice;
            const response = await editProduct(editedProduct, editingProductId);
            if (response.error !== '') {
                const error = ErrorCases(response.error);
                setEditError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckClick = key => {
        product[key] === 1 ? setProduct({ ...product, [key]: 0 }) : setProduct({ ...product, [key]: 1 })
    };

    const handleZoneFareCheck = () => {
        const newProduct = product;
        newProduct.zoneFare === 1 ? newProduct.zoneFare = 0 : newProduct.zoneFare = 1;
        if (newProduct.zoneFare === 1 && newProduct.flatFare === 1) {
            newProduct.flatFare = 0;
        }
        setProduct({ ...newProduct });
    };

    const handleFlatFareCheck = () => {
        const newProduct = product;
        newProduct.flatFare === 1 ? newProduct.flatFare = 0 : newProduct.flatFare = 1;
        if (newProduct.zoneFare === 1 && newProduct.flatFare === 1) {
            newProduct.zoneFare = 0;
        }
        setProduct({ ...newProduct });
    };

    const handleCancel = () => {
        onHide();
        setEditError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.EditProduct}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterProductName}
                                className='module__input-search'
                                onChange={evt => setProduct({ ...product, name: evt.target.value })}
                                value={product.name}
                            ></input>
                        </div>
                        {/* <div className=''>
                        <label className='input__label'>Language Number</label> <br />
                        {languageData.length !== 0 &&
                            <select onChange={evt => setProduct({...product, languageNumber: evt.target.value})} 
                                className='dropdown'
                                value={product.languageNumber}>
                                <option>Select an option</option>
                                {languageData.productsName.length !== 0 && 
                                    languageData.productsName.map(language => {
                                        return <option value={language.languageNumber}>{language.productName}</option>
                                        })
                                    }
                            </select>
                        }
                    </div> */}
                    </div>

                    <div className='inputs-row'>
                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.QuantityPass}</label> <br />
                            <input
                                className='module__input-search input-numeric'
                                onChange={evt => setProduct({ ...product, quantityPass: evt.target.value })}
                                type='number'
                                value={product.quantityPass}
                            ></input>
                        </div>
                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.QuantityPrint}</label> <br />
                            <input
                                className='module__input-search input-numeric'
                                onChange={evt => setProduct({ ...product, quantityPrint: evt.target.value })}
                                type='number'
                                value={product.quantityPrint}
                            ></input>
                        </div>


                        <div>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={product.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => handleCheckClick("isActive")} />
                        </div>
                        <div>
                            <label className='input__label'>{language.ZoneFare}</label> <br />
                            <img src={product.zoneFare === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={handleZoneFareCheck} />
                        </div>
                        <div>
                            <label className='input__label'>{language.FlatFare}</label> <br />
                            <img src={product.flatFare === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={handleFlatFareCheck} />
                        </div>
                    </div>

                    <div className='inputs-row'>
                        <div>
                            <label className='input__label'>{language.Price}</label> <br />
                            <CurrencyInput
                                placeholder={language.EnterProductPrice}
                                className='module__input-search modal__text-box email-input'
                                defaultValue={formattedPrice}
                                decimalsLimit={2}
                                onValueChange={(value, names, values) => setFormattedPrice(values.float)}
                                decimalScale={2}
                                maxLength={7}
                                intlConfig={{ locale: languageCode, currency: currency.currency }}
                            />
                        </div>
                        <div>
                            <label className='input__label'>{language.ProductType}</label> <br />
                            <select onChange={evt => setProduct({ ...product, productType: evt.target.value })}
                                className='module__input-search'
                                value={product.productType}>
                                <option >{language.SelectAnOption}</option>
                                <option value={"passes"}>{language.Passes}</option>
                                <option value={"promotional"}>{language.Promotional}</option>
                            </select>
                        </div>
                    </div>
                    {/* <p className='subtitle subtitle-position'>Position Page</p>
                <div className='inputs-row'>
                    <div>
                        <label className='input__label'>Screen</label> <br />
                        <input placeholder="Product's screen page"
                            className='module__input-search modal__text-box email-input'
                            onChange={evt => setProduct({...product, screenPage: evt.target.value})}
                            type='number'
                        ></input>
                    </div>
                    <div>
                        <label className='input__label'>Position</label> <br />
                        <input placeholder="Product's position page"
                            className='module__input-search modal__text-box email-input'
                            onChange={evt => setProduct({...product, positionPage: evt.target.value})}
                            type='number'
                        ></input>
                    </div>
                </div> */}
                    <div className='error-alert'>{editError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default ProductsEditModal;