import deleteMethod from "../../Hooks/API/DeleteMethod";

async function DeleteTenant(id) {
    const path = `/Tenants/${id}`;
    try {
        const { data, error } = await deleteMethod(path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default DeleteTenant;