import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';

async function SendEmail() {  
    const session = await fetchAuthSession ();
    const idToken = session.tokens.idToken.toString();
    const accessToken = session.tokens.accessToken.toString();
    const apiName = 'UPA';
    const path = '/Users/SendVerification';
    const options = {
        headers: {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        },
        response: true,
        body: {
            'accessToken': accessToken,
            'attributeName': 'email'
        }
    };

    try {
        const restOperation  = post({
            apiName,
            path,
            options
        });

        const {body} = await restOperation.response
        const data = await body.json();

        return {data : data, error : ''};
    } catch (error) {
        const responseError = error.response.message !== undefined? error.response.message : error.response.data.error;
        console.error(responseError);
        return {data : '', error : responseError};
    }
} 

export default SendEmail;