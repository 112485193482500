import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function EditCity(city, cityId) {
    const path = `/Cities/edit/${cityId}`
    try {
        const data = await postMethod(city, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
};

export default EditCity;