import React from 'react'
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

async function DisableUser(username) { 
    try {
        const session = await fetchAuthSession ();
        const accessToken = session.tokens.accessToken.toString();
        let apiName = 'AdminQueries';
        let path = '/disableUser';
        let options = {
            body: {
            "username" : username
            }, 
            headers: {
            'Content-Type' : 'application/json',
            'Authorization': accessToken
            } 
        }
        return post({apiName, path, options});
  
    } catch (error) {
        const responseError = error.response.message !== undefined? error.response.message : error.response.data.error;
        console.error(responseError);
        return {data : '', error : responseError};
    }
}
export default DisableUser;