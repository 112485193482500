import { useEffect, useState, useRef } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import getFullStatusControlById from '../../../api/TVM/getFullStatusControlById';
import QR from '../../../Common/QR';

import ReactToPrint from 'react-to-print';

function FullStatusQRCode({ show, onHide, setIsLoading, qrId }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const language = JSON.parse(localStorage.getItem('language'));
    const [qrCode, setQRCode] = useState('');
    const componentRef = useRef();
    const [accessType, setAccessType] = useState('');

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await getFullStatusControlById(qrId, currentTenant);
                setQRCode(response.data.data[0].encryptedQR);
                setAccessType(response.data.data[0].accessType)
            } catch (error) {
                console.error(error.message);
            }
            setIsLoading(false);
        }

        qrId && fetchData();

    }, [qrId]);

    const handleCancel = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.QRCode}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div ref={componentRef}>
                    <div className='qr-modal__access'>{accessType}</div>
                    <div ><QR value={qrCode} /></div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <ReactToPrint
                    trigger={() => {
                        return <Button className='general-button primary'>
                            {language.Print}
                        </Button>
                    }}
                    content={() => componentRef.current}
                    documentTitle={language.QRCode}
                    pageStyle='print'
                />
            </Modal.Footer>
        </Modal>
    );
};

export default FullStatusQRCode;