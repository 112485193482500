import React, { useMemo } from 'react';
import {
    MaterialReactTable, 
    useMaterialReactTable,
    MRT_ExpandAllButton,
} from 'material-react-table';
import { Box, Stack, Button } from '@mui/material';
import { SiMicrosoftexcel } from "react-icons/si"; 
import { FaRegFilePdf } from "react-icons/fa";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';

const GeneralTable = ({data,columns_table,actions, rowActions,groupFiles=false,exportFile=false}) =>{
    const language = JSON.parse(localStorage.getItem('language'));
    const columns = useMemo(() => columns_table, [columns_table]);

    const handleExportRowsPDF = (rows) => {
        const doc = new jsPDF();
        const tableData = rows.map((row) => Object.values(row.original));
        const tableHeaders = columns.map((c) => c.header);
    
        autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
        });
    
        doc.save('mrt-pdf-example.pdf');
    };

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };
    
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };

    let shouldGroupColumns = groupFiles/*groupFiles && groupFiles.length > 0*/;

    const table = useMaterialReactTable({
        enableDensityToggle: false,
        enableColumnActions: actions,
        enableRowActions: actions,
        enableClickToCopy:true,
        columns,
        data, 
        enableColumnOrdering: true, 
        enableGlobalFilter: true,
        renderRowActions: rowActions,
        positionActionsColumn: 'last',
        enableStickyHeader: true,
        enableStickyFooter: true,
        defaultColumn: {
            maxSize: 100,
            minSize: 20,
            size: 50,
        },
        initialState: {
            density: 'compact',
            expanded: true
        },    
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        layoutMode: 'semantic',
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #D7D7D7',
                fontWeight: 'bold',
                fontFamily: 'Open Sans bold',
                fontSize: '1.2rem',
                color: '#6A6A6A',
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
                backgroundColor: 'white',
                height: '50px',
                alignContent: 'center',
                alignItems: 'center'
            },
            align: 'left',
            size: 'fit-content',
            
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #D7D7D7',
                fontFamily: 'Open Sans Semibold',
                fontSize: '0.9rem', 
            },
            align: 'center'
        },
        muiBottomToolbarProps: {
            sx: {
                backgroundColor: '#EBEBEB',
                boxShadow: '0',
                borderTop: '1px solid #D7D7D7',
                borderBottom: '1px solid #D7D7D7',
                borderColor: '#D7D7D7',
                marginTop: '35px'
            },
            
        },
        muiTopToolbarProps: {
            sx: {
                border: '0',
                boxShadow: '0',
                marginLeft: '0',
                backgroundColor: '#EBEBEB'
            },
            align: 'center'
        },
        muiTablePaperProps: {
            sx: {
                m: 'auto',
                maxWidth: '100%',
                backgroundColor: '#EBEBEB',
                borderRadius: '12px',
                border: '1px solid #D7D7D7',
                overflow: 'hidden',
            },
            elevation: 0,
        },
        muiPaginationProps: {
            labelRowsPerPage: 'x'
        },
        muiTableBodyProps: {
            sx: {
                '.Mui-ToolbarDropZone':{
                    backgroundColor: 'black'
                } 
            }
        },
        muiPaginationProps:{
            sx: {
                backgroundColor: 'white'
            },
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: language.Actions,
            },
            ...(shouldGroupColumns && { 

                'mrt-row-expand': {
                    Header: () => (
                        <Stack direction="row" alignItems="center">
                            <MRT_ExpandAllButton table={table} />
                            <Box>Groups</Box>
                        </Stack>
                    ),
                    enableResizing: true,
                    muiTableBodyCellProps: () => ({
                        sx: () => ({
                            color: '#000',
                            fontWeight: 'bold'
                        }),
                    }),
                }})
        },
        ...(shouldGroupColumns && { 
            enableGrouping: true,
            manualGrouping:false,
            groupedColumnMode: 'remove' 
        }),
        ...(exportFile && {
            enableRowSelection: true,
        })
        //localization: MRT_Localization_ES        <- Manage here change of language
    });

    // Custom Header Component
    const CustomHeader = () => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
        <Box
            sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
            }}
        >
            <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<SiMicrosoftexcel style={{ color: '#107C41' }}/>}
            >
            {language.ExportAllData}
            </Button>
            <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<SiMicrosoftexcel style={{ color: '#107C41' }}/>}
            >
            {language.ExportAllRows}
            </Button>
            <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<SiMicrosoftexcel style={{ color: '#107C41' }}/>}
            >
            {language.ExportPageRows}
            </Button>
            <Button
            disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<SiMicrosoftexcel style={{ color: '#107C41' }}/>}
            >
            {language.ExportSelectedRows}
            </Button>
            {/* Exportation PDF */}
            <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                    handleExportRowsPDF(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FaRegFilePdf style={{ color: '#B30B00' }}/>}
                >
                {language.ExportAllRows}
            </Button>
            <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
                startIcon={<FaRegFilePdf style={{ color: '#B30B00' }}/>}
                >
                {language.ExportPageRows}
            </Button>
            <Button
                disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRowsPDF(table.getSelectedRowModel().rows)}
                startIcon={<FaRegFilePdf style={{ color: '#B30B00' }}/>}
                >
                {language.ExportSelectedRows}
            </Button>
        </Box>
        </Stack>
    );

    return(
        <Box sx={{ margin: '0', maxWidth: '100%' }}> 
            {exportFile && <CustomHeader />}
            {(table)&&(<MaterialReactTable table={table}/>)}
        </Box> 
    );
}

export default GeneralTable;