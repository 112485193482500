import { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import getAllDevicesAlarms from '../../../api/DevicesAlarms/getAllDevicesAlarms';
import getAllTVM from '../../../api/TVM/getAllTVM';
import createCommand from '../../../api/Commands/createCommand';
import UploadFile from '../../../Hooks/S3/UploadFile';
import ErrorCases from '../../../Common/ErrorCases';

function CommandsNewModal({ show, onHide, getData, setData, setIsLoading, commandsNames }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [command, setCommand] = useState([]);
    const [error, setError] = useState(null);
    const [fromDate, setFromDate] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toDate, setToDate] = useState("");
    const [toTime, setToTime] = useState("");
    const [devices, setDevices] = useState([]);
    const [tvms, setTvms] = useState([]);
    const [tvmAssignment, setTVMAssignment] = useState([]);
    const [tvmUnassigned, setTVMUnassigned] = useState([]);
    const [unassignedSelected, setUnassignedSelected] = useState([]);
    const [assignedSelected, setAssignedSelected] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showCommandAlert, setShowCommandAlert] = useState(false);
    const [showTVMsAlert, setShowTVMsAlert] = useState(false);
    const [showDatesAlert, setShowDatesAlert] = useState(false);
    const [createError, setCreateError] = useState('');

    const clearCommand = () => {
        setCommand({
            command: '',
            params: '',
            deviceId: 0,
            pathFile: '',
            isActive: 1,
            fromDate: '',
            toDate: '',
            includingFile: 0,
            includingDate: 0,
            includingDevice: 0,
            tenantsDB: currentTenant,
            tvmList: []
        })
        setTVMAssignment([]);
        setShowCommandAlert(false);
        setShowTVMsAlert(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                clearCommand();
                const response = await getDevices();
                setDevices(response);
                const tvmsData = await getTVMs();
                setTVMUnassigned(tvmsData);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getDevices() {
        const { data, error } = await getAllDevicesAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getTVMs() {
        const { data, error } = await getAllTVM(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleCancel = () => {
        setShowCommandAlert(false);
        setShowTVMsAlert(false);
        setCreateError('');
        onHide();
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const isCommandValid = validateCommand();
            const isTVMListValid = validateTVMs();
            const fromDateTime = combineDateTime(fromDate, fromTime);
            const toDateTime = combineDateTime(toDate, toTime);
            const areDatesValid = validateDates(fromDateTime, toDateTime);
            if (isCommandValid && isTVMListValid && areDatesValid) {
                const tvms = addTVMs(tvmAssignment);
                const newCommand = command;
                newCommand.fromDate = fromDateTime;
                newCommand.toDate = toDateTime;
                newCommand.tvmList = tvms;
                if (newCommand.command === 'updateconfig' && newCommand.pathFile !== '') {
                    const result = await UploadFile(command.pathFile, 'update_config');
                    const { key } = result;
                    newCommand.pathFile = key;
                }
                const response = await createCommand(newCommand);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setCreateError(error);
                    return;
                }
                const newData = await getData();
                setData(newData.data);
                onHide();
                clearCommand();
            }
        } catch (error) {
            console.error(error.message);
            setCreateError(error.message);
        } finally {
            setIsLoading(false);
        }

    };

    const combineDateTime = (date, time) => {
        if (date && time) {
            const [year, month, day] = date.split('-');
            const [hours, minutes] = time.split(':');
            const combinedDateTime = new Date(year, month - 1, day, hours, minutes);
            return combinedDateTime.toISOString();;
        }
    };

    function addTVMs(assignedTVM) {
        const newArray = assignedTVM.map(tvm => ({ idTvm: parseInt(tvm.idTvm) }));
        return newArray;
    }

    const handleChangeUnassigned = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setUnassignedSelected([...unassignedSelected, parseInt(value)]);
        } else {
            setUnassignedSelected(unassignedSelected.filter(item => item !== parseInt(value)));
        }
    };

    const handleChangeAssigned = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setAssignedSelected([...assignedSelected, parseInt(value)]);
        } else {
            setAssignedSelected(assignedSelected.filter(item => item !== parseInt(value)));
        }
    };

    const assignTVMs = () => {
        unassignedSelected.forEach(id => {
            const selectedTVM = tvmUnassigned.find(tvm => tvm.idTvm === id);
            if (selectedTVM) {
                setTVMAssignment(tvmAssignment => [...tvmAssignment, selectedTVM]);
            }
        });

        setTVMUnassigned(tvmUnassigned.filter(item => !unassignedSelected.includes(item.idTvm)));
        setUnassignedSelected([]);
    };

    const unassignTVMs = () => {
        assignedSelected.forEach(id => {
            const selectedTVM = tvmAssignment.find(tvm => tvm.idTvm === id);
            if (selectedTVM) {
                setTVMUnassigned(tvmUnassigned => [...tvmUnassigned, selectedTVM]);
            }
        });

        setTVMAssignment(tvmAssignment.filter(item => !assignedSelected.includes(item.idTvm)));
        setAssignedSelected([]);
    };

    const validateCommand = () => {
        if (command.command === '') {
            setShowCommandAlert(true);
            return false;
        } else {
            setShowCommandAlert(false);
            return true;
        }
    };

    const validateTVMs = () => {
        if (tvmAssignment.length < 1) {
            setShowTVMsAlert(true);
            return false;
        } else {
            setShowTVMsAlert(false);
            return true;
        }
    };

    const validateDates = (fromDate, toDate) => {
        if ((command.includingDate === 1 && fromDate < toDate) || command.includingDate === 0) {
            setShowDatesAlert(false);
            return true;
        } if (command.includingDate === 1 && fromDate >= toDate) {
            setShowDatesAlert(true);
            return false;
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.AddingCommandTVM}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.CommandName}</label> <br />
                            <select
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setCommand({ ...command, command: evt.target.value })}>
                                <option>{language.SelectACommand}</option>
                                {commandsNames.map((command, index) => {
                                    return (
                                        <option value={command.commandsName}
                                            key={index}
                                        >{command.commandsName}</option>
                                    )
                                })}
                            </select>

                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.Parameters}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setCommand({ ...command, params: evt.target.value })}
                            ></input>
                        </div>

                        {command.includingDate === 1 &&
                            <>
                                <div className='input-name'>
                                    <label className='input__label'>{language.FromDate}</label> <br />
                                    <input
                                        className='module__input-search'
                                        onChange={evt => setFromDate(evt.target.value)}
                                        type='date'
                                    ></input>
                                    <input
                                        className='module__input-search modal__time'
                                        onChange={evt => setFromTime(evt.target.value)}
                                        type='time'
                                    ></input>
                                </div>

                                <div className='input-name'>
                                    <label className='input__label'>{language.ToDate}</label> <br />
                                    <input
                                        className='module__input-search'
                                        onChange={evt => setToDate(evt.target.value)}
                                        type='date'
                                    ></input>
                                    <input
                                        className='module__input-search modal__time'
                                        onChange={evt => setToTime(evt.target.value)}
                                        type='time'
                                    ></input>
                                </div>
                            </>
                        }
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%' }}>
                            {showCommandAlert && <div className='error-alert' style={{ textAlign: 'left' }}>{language.CommandNameAlert}</div>}
                        </div>
                        <div style={{ width: '50%' }}>
                            {showDatesAlert && <div className='error-alert' style={{ textAlign: 'right' }}>{language.FromDateMustBePrior}</div>}
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div className='modal__aligned-input'>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={command.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => command.isActive === 1 ? setCommand({ ...command, isActive: 0 }) : setCommand({ ...command, isActive: 1 })}
                            />
                        </div>

                        <div className='modal__aligned-input'>
                            <label className='input__label'>{language.IncludingFile}</label> <br />
                            <button
                                disabled={command.command !== 'updateconfig'}
                                className='btn'>
                                <img src={command.includingFile === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='table__checkbox'
                                    onClick={() => command.includingFile === 1 ? setCommand({ ...command, includingFile: 0 }) : setCommand({ ...command, includingFile: 1 })}
                                />
                            </button>
                        </div>

                        <div className='modal__aligned-input'>
                            <label className='input__label'>{language.IncludingDate}</label> <br />
                            <button
                                className='btn'>
                                <img src={command.includingDate === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='table__checkbox'
                                    onClick={() => command.includingDate === 1 ? setCommand({ ...command, includingDate: 0 }) : setCommand({ ...command, includingDate: 1 })}
                                />
                            </button>
                        </div>

                        <div className='modal__aligned-input'>
                            <label className='input__label'>{language.IncludingDevice}</label> <br />
                            <button
                                className='btn'>
                                <img src={command.includingDevice === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='table__checkbox'
                                    onClick={() => command.includingDevice === 1 ? setCommand({ ...command, includingDevice: 0 }) : setCommand({ ...command, includingDevice: 1 })}
                                />
                            </button>
                        </div>

                        {command.includingDevice === 1 &&
                            <div className='modal__aligned-input'>
                                <label className='input__label'>{language.Device}</label> <br />
                                <select className='module__input-search modal__text-box email-input'
                                    onChange={evt => setCommand({ ...command, deviceId: evt.target.value })}>
                                    <option>{language.SelectDevice}</option>
                                    {
                                        devices.map(device => {
                                            return (
                                                <option value={device.idDeviceAlarms} key={device.idDeviceAlarms}>{device.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        }


                    </div>

                    <div >
                        {command.includingFile === 1 &&
                            <div className='inputs-row__no-justified modal__selected-file'>
                                <input type="file" id="file" className="custom-file-input"
                                    onChange={evt => setCommand({ ...command, pathFile: evt.target.files[0] })}
                                    placeholder={language.SelectedFile} />
                                <label htmlFor="file" className="small-button primary modal__file-input-button">
                                    {language.SelectFile}
                                </label>
                                {command.pathFile && (
                                    <div className='table-row__text' style={{ marginTop: '15px' }}>
                                        {language.SelectedFile}: {command.pathFile.name}
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                    <hr />
                    <div className='modal__subtitle'>{language.SelectTVM}</div>
                    <div className='inputs-row__no-justified email-assign__row'>

                        <div>
                            <Card style={{ width: '23rem', height: '14rem', overflow: 'auto' }}>
                                <Card.Header className='card__label'>{language.AvailableTVMsStation}</Card.Header>
                                <ListGroup variant="flush">
                                    {tvmUnassigned.map((tvm, index) => {
                                        return (
                                            <ListGroup.Item className='card__item'
                                                key={tvm.idTvm}>
                                                <input type="checkbox"
                                                    value={tvm.idTvm}
                                                    key={tvm.idTvm}
                                                    onChange={handleChangeUnassigned}
                                                    checked={unassignedSelected.includes(tvm.idTvm)}
                                                    className='email-assign__checkbox'
                                                />
                                                {tvm.nameTVM} - {tvm.stationName}</ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </Card>
                            <Button className="small-button primary" onClick={assignTVMs}>
                                {language.AssignTVMs}
                            </Button>
                        </div>

                        <div>
                            <Card style={{ width: '23rem', height: '14rem', overflow: 'auto' }}>
                                <Card.Header className='card__label'>{language.AssignedTVMs}</Card.Header>
                                <ListGroup variant="flush">
                                    {tvmAssignment.map((tvm, index) => {
                                        return (
                                            <ListGroup.Item className='card__item'><input type="checkbox"
                                                value={tvm.idTvm}
                                                key={tvm.idTvm}
                                                onChange={handleChangeAssigned}
                                                checked={assignedSelected.includes(tvm.idTvm)}
                                                className='email-assign__checkbox'
                                            />
                                                {tvm.nameTVM} - {tvm.stationName}</ListGroup.Item>
                                        )
                                    })}
                                </ListGroup>
                            </Card>
                            <Button className="small-button primary" onClick={unassignTVMs}>
                                {language.UnassignTVMs}
                            </Button>
                        </div>

                    </div>
                    {showTVMsAlert && <div className='error-alert' >{language.TVMsAlert}</div>}
                    <div className='error-alert' >{createError}</div>
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button className="general-button danger" onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className="general-button primary" onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CommandsNewModal;