import "./css/CustomAlert.css"

function CustomAlert({message}){
    return (
        <div className='alert__red' style={{ whiteSpace: 'pre-line' }}>
            <img src='./img/icons/login/Warming-01.png' />
            {message}
        </div>
    );
}

export default CustomAlert;