import React from 'react'
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import amplifyconfig from '../../amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

//const client = generateClient()

async function EnableUser(username) { 
    const session = await fetchAuthSession ();
    const accessToken = session.tokens.accessToken.toString();
    let apiName = 'AdminQueries';
    let path = '/enableUser';
    let options = {
        body: {
          "username" : username
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: accessToken
        } 
    }
    return post({apiName, path, options});
  }

export default EnableUser;