import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ZoneNewModal.css'
import createZone from '../../api/Fares/createZone';

import CurrencyInput from 'react-currency-input-field';
import ErrorCases from '../../Common/ErrorCases';

function ZoneNewModal({ show, onHide, products, zones, setProductId, fareZones, setEditedZoneId }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [isLoading, setIsLoading] = useState(false);
    const [zone, setZone] = useState({ tenantsDB: currentUser.data.currentTenant, zoneIdOrigin: 1, zoneIdDestiny: 1, productId: 1, price: 0.00 });
    const [alarm, setAlarm] = useState(false);
    const [alarmMessage, setAlarmMessage] = useState('');
    const [formattedPrice, setFormattedPrice] = useState(0);
    const language = JSON.parse(localStorage.getItem('language'));

    useEffect(() => {

    }, [products]);

    const handleSave = async () => {
        const result = fareZones.find(fareZone =>
            fareZone.zoneIdOrigin === parseInt(zone.zoneIdOrigin) && fareZone.zoneIdDestiny === parseInt(zone.zoneIdDestiny)

        );

        if (result === undefined) {
            try {
                setIsLoading(true);
                const newZone = zone;
                newZone.price = formattedPrice;
                const response = await createZone(newZone);
                if(response.error !== '') {
                    const error = ErrorCases(response.error);
                    setAlarmMessage(error);
                    setAlarm(true);
                    return;
                }
                setEditedZoneId(zone.zoneIdOrigin);
                onHide();
                setZone({ ...zone, zoneIdOrigin: 1, zoneIdDestiny: 1, productId: 1, price: 0 });

            } catch (error) {

            } finally {
                setIsLoading(false);
            }

        } else {
            setAlarm(true);
            setAlarmMessage(language.FareZoneExists)
        }

    };

    const handleCancel = () => {
        setZone({ ...zone, zoneIdOrigin: 1, zoneIdDestiny: 1, productId: 1, price: 0 });
        setAlarm(false);
        setAlarmMessage('');
        onHide();
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewZone}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row'>
                        <div className='dropdown input'>
                            <label className='input__label'>{language.Product}</label> <br />
                            <select className='module__input-search' onChange={evt => setZone({ ...zone, productId: evt.target.value })}>
                                {
                                    products.map(product => {
                                        return (
                                            <option value={product.idProduct}>{product.name}</option>
                                        )
                                    })
                                }
                            </select> <br />
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneOrigin}</label> <br />
                            <select className='module__input-search' onChange={evt => setZone({ ...zone, zoneIdOrigin: evt.target.value })}>
                                {
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='input-numeric'>
                            <label className='input__label'>{language.ZoneDestiny}</label> <br />
                            <select className='module__input-search' onChange={evt => setZone({ ...zone, zoneIdDestiny: evt.target.value })}>
                                {
                                    zones.map(zone => {
                                        return (
                                            <option value={zone.zoneId}>{zone.zoneId}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='dropdown input'>
                            <label className='input__label'>{language.Price}</label>
                            <br />
                            <CurrencyInput
                                placeholder={language.EnterProductPrice}
                                className='module__input-search'
                                defaultValue={0.00}
                                decimalsLimit={2}
                                onValueChange={(value, names, values) => setFormattedPrice(values.float)}
                                decimalScale={2}
                                maxLength={7}
                                intlConfig={{ locale: languageCode, currency: currency.currency }}
                            />
                            <br />
                        </div>
                    </div>
                    <div className='price__alert'>
                        {alarm && alarmMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default ZoneNewModal;