import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import createCity from '../../api/Location/createCity';
import getCountries from '../../api/Location/getCountries';
import getStates from '../../api/Location/getStates';
import ErrorCases from '../../Common/ErrorCases';

function CitiesNewModal({ show, onHide, getData, setData }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [city, setCity] = useState({ tenantsDB: currentUser.data.currentTenant });
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(100);
    const [error, setError] = useState(null);
    const [states, setStates] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getCountriesData();
                setCountries(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getCountriesData() {
        const { data, error } = await getCountries(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await createCity(city);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setCreateError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const statesSearch = async (tenantsDB, countryId) => {
        setIsLoading(true);
        const data = await getStates(tenantsDB, countryId);
        setStates(data.data);
        setIsLoading(false);
    };

    const handleCancel = () => {
        onHide();
        setCreateError('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewCity}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className='input__label'>{language.city}</label> <br />
                    <input placeholder={language.EnterNewCityName}
                        className='module__input-search'
                        onChange={evt => setCity({ ...city, name: evt.target.value })}
                    ></input>
                    <label className='input__label'>{language.Abbreviation}</label> <br />
                    <input placeholder={language.EnterCityAbbreviation}
                        className='module__input-search'
                        onChange={evt => setCity({ ...city, abbreviation: evt.target.value })}
                    ></input>
                    <label className='input__label'>{language.Country}</label> <br />
                    <select className="module__input-search"
                        onChange={evt => statesSearch(currentUser.data.currentTenant, evt.target.value)}
                    >
                        <option value="">{language.SelectCityCountry}</option>
                        {
                            countries.map(item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                        }
                    </select><br />
                    <label className='input__label'>{language.state}</label> <br />
                    <select className="module__input-search"
                        onChange={evt => setCity({ ...city, stateId: evt.target.value })}
                        disabled={states.length === 0}
                    >
                        <option value="">{language.SelectCityState}</option>
                        {
                            states.map(item => {
                                return <option key={item.stateId} value={item.stateId}>{item.stateName}</option>
                            })
                        }
                    </select>
                    <div className='error-alert'>{createError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default CitiesNewModal;