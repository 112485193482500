import * as React from "react";
import { useState } from 'react';

import "./css/Auth.css";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from "react-bootstrap";

import CustomAlert from '../Common/CustomAlert';
import languages from './LoginLanguage.json';

import { resetPassword } from 'aws-amplify/auth';

import { useNavigate, Link } from 'react-router-dom';

function ForgotPassword(props) {
  const [username, setUserName] = useState('');
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const currentLanguage = localStorage.getItem('currentLanguage');
  const language = languages[currentLanguage][0];
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      const response = await resetPassword({ username });

      if (response.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        localStorage.setItem('username', username);
        navigate('/reset-password');
      }
    } catch (error) {
      setShowAlert(true);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="main-container">

        <img
          loading="lazy"
          srcSet="./img/logoBEATT.png"
          className="login__logo"
        />
        <div className="login__container">
          <div className="login__inputs-group">
            <div className="login__input">
              <img src='./img/icons/login/Username.png' className='login__icon' />
              <Form.Control className="login__form" type="text" placeholder={language.UserName}
                onChange={evt => setUserName(evt.target.value)} />
            </div>
          </div>
          <div className="button-light-blue" onClick={handleResetPassword}>{language.SENDCODE}</div>
        </div>
        <div className="login__bottom--centered">
          <Link to="/login"><div className="login__bottom-forgot">{language.BackToLogin}</div></Link>
        </div>
        {
          showAlert &&
          <CustomAlert message={language.UsernameIncorrectAlert} />
        }

      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
    </>
  );
}

export default ForgotPassword;
