import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function EditCountry(country) {
    const path = `/Countries/edit/${country.id}`;
    try {
        const data = await postMethod(country, path);
        return {data : data, error : ''};
    } catch (error) {
        return error;
    }
};

export default EditCountry;