import React, { useState } from "react";
import { VscError } from "react-icons/vsc";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { GoKebabHorizontal } from "react-icons/go";
import "./Cards.css";
import { Modal, ModalBody } from "react-bootstrap";
import getDetailInfo from "../../../api/Dashboard/getDetailInfo";
import DashBoardTable from "../Table/DashBoardTable";

const CardCompareAlarm = ({ name, badge, color, typeStatus, idDevice, language, item, isDragging, isDraggable, programFromDate, programToDate, compareFromDate, compareToDate,
    setIsLoading, showModal, setShowModal, handleCardDetails }) => {
    let icon;
    color = 'orange';
    icon = <RiErrorWarningLine
        onClick={() => handleClick()}
        key={idDevice}
        className="card-alert-icon"
        size={40}
        style={{ fill: color }} />;

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;



    const handleClick = () => {
        if (badge !== 0) {
            handleCardDetails(item.id, 'alarm_card');
        }
    };

    const calculateChangePercentage = (programTotal, compareTotal) => {
        const program = parseFloat(programTotal);
        const compare = parseFloat(compareTotal);

        if (compare === 0) {
            return program > 0 ? "-100%" : "0%";
        }

        const changePercentage = ((program - compare) / compare) * 100;
        const symbol = program < compare ? '+' : '-';
        return symbol + changePercentage.toFixed(2) + '%';
    };



    const formatDate = (isoDate, languageCode) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            // hour12: true
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <section className={`card-compare-container ${isDragging ? 'card-container-drag' : '', isDraggable ? '' : 'card-container-isDrag'}`}>
            <div className="card-alert-color" style={{ backgroundColor: color }} />
            <div className="card-information">
                <div className="card-alert">
                    <div >{icon}</div>
                </div>
                <div>
                    <div className="card-percentage">{calculateChangePercentage(item.programTotalRegister, item.compareTotalRegister)}</div>
                    <p className="card-title">{language[item.deviceCode]}</p>
                    <p className="card-status">{language.Status} : {language[item.alarmType]}</p>
                    <p className="card-compare-status" style={{ marginTop: '1rem' }}>
                        <span>{formatDate(programFromDate, language)} - {formatDate(programToDate, language)}</span>
                        <span className="compare-total">{item.programTotalRegister}</span>
                    </p>
                    <p className="card-compare-status" style={{ marginBottom: '1rem' }}>
                        <span>{formatDate(compareFromDate, language)} - {formatDate(compareToDate, language)}</span>
                        <span className="compare-total">{item.compareTotalRegister}</span>
                    </p>
                </div>
            </div>
            <div className="card-menu-div">
                <GoKebabHorizontal className="card-menu" size={20} />
            </div>

        </section>
    );
}

export default CardCompareAlarm;