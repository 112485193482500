import GetMethod from "../../Hooks/API/GetMethod";

const getAllCommands = async (tenantsDB, fromDate, toDate) =>{
    const queryParameters = {
        tenantsDB
    };
    // const path = `/CommandsDevice/GetAllCommandsDevice`;
    const path = `/CommandsDevice/GetAllCommandsDevice?tenantsDB=${tenantsDB}&toDate=${toDate}&fromDate=${fromDate}`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default getAllCommands;