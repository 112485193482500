import GeneralTable from "../../../Hooks/Table/GeneralTable";
import getPermission from "../../../auth/GetPermission";
import AddButton from "../../../Common/AddButton";
import getAllDevicesAlarms from "../../../api/DevicesAlarms/getAllDevicesAlarms";
import DevicesNewModal from "./DevicesNewModal";
import DevicesEditModal from "./DevicesEditModal";
import deleteDeviceAlarm from "../../../api/DevicesAlarms/deleteDeviceAlarm";
import DeleteModal from "../../../Common/DeleteModal";

import { Box } from '@mui/material';

import { useState, useEffect } from "react";

import { Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import ErrorCases from "../../../Common/ErrorCases";

function Devices() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingDevice, setEditingDevice] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingDevice, setDeletingDevice] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getAllDevicesAlarms(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const columns = [
        {
            header: language.Name,
            accessorKey: 'name',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Description,
            accessorKey: 'descriptionDevice',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.DeviceCode,
            accessorKey: 'deviceCode',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Active,
            accessorKey: 'isActive',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox' />
                </Box>
            ),
        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original)}
                disabled={!getPermission(currentUser, "DMConfigDevicesAlarms", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "DMConfigDevicesAlarms", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    const handleEditClick = (device) => {
        setEditingDevice(device);
        setShowEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await deleteDeviceAlarm(id, currentTenant);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirm = (device) => {
        setDeletingDevice(device);
        setShowDeleteModal(true);
        setDeleteError('');
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Devices}</h1>
            {getPermission(currentUser, "DMConfigDevicesAlarms", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    {
                        getPermission(currentUser, "DMConfigDevicesAlarms", "writeAllow") &&
                        <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
                    }

                    <DevicesNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading} />

                    <DevicesEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        getData={getData}
                        setData={setData}
                        setIsLoading={setIsLoading}
                        editingDevice={editingDevice} />
                </>
            }

            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteDeviceAlarmMessage + deletingDevice.name}
                    handleDelete={() => handleDeleteClick(deletingDevice.idDeviceAlarms)}
                    error={deleteError}
                />
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>
    );
}

export default Devices;