import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import getCurrencies from '../../api/Currency/getCurrencies';
import GetTenant from '../../api/Tenants/getTenant';
import EditTenant from '../../api/Tenants/editTenant';
import GetTimeZones from '../../api/Tenants/getTimeZones';

function TenantsEditModal({ show, onHide, setIsLoading, getData, setData, editingTenantId }) {
    const [tenant, setTenant] = useState({
        tenantsDBName: '',
        tenantsName: '',
        agencyName: '',
        abbreviationAgencyName: '',
        idCurrency: 0,
        isActive: 1
    });
    const language = JSON.parse(localStorage.getItem('language'));
    const [currencyData, setCurrencyData] = useState([]);
    const [error, setError] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [timeZones, setTimeZones] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const editingTenant = await getEditingTenant(editingTenantId);
                const response = await getCurrencyData();
                setCurrencyData(response);
                setTenant({
                    tenantsDBName: editingTenant[0].tenantsDBName,
                    tenantsName: editingTenant[0].tenantsName,
                    agencyName: editingTenant[0].agencyName,
                    abbreviationAgencyName: editingTenant[0].abbreviationAgencyName,
                    idCurrency: editingTenant[0].idCurrency,
                    isActive: editingTenant[0].isActive,
                    timeZone: editingTenant[0].idTimeZone
                });
                const timeZonesResponse = await GetTimeZones(currentTenant);
                setTimeZones(timeZonesResponse.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        editingTenantId && fetchData();
    }, [editingTenantId]);

    async function getCurrencyData() {
        const { data, error } = await getCurrencies(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getEditingTenant() {
        const { data, error } = await GetTenant(editingTenantId);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await EditTenant(tenant, editingTenantId);
            const newData = await getData();
            setData(newData.tenants);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.s}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className='input__label'>{language.TenantDBName}</label> <br />
                <input placeholder={language.EnterTenantDBName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, tenantsDBName: evt.target.value })}
                    value={tenant.tenantsDBName}
                ></input>
                <label className='input__label'>{language.TenantName}</label> <br />
                <input placeholder={language.EnterTenantName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, tenantsName: evt.target.value })}
                    value={tenant.tenantsName}
                ></input>
                <label className='input__label'>{language.Agency}</label> <br />
                <input placeholder={language.EnterAgencyName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, agencyName: evt.target.value })}
                    value={tenant.agencyName}
                ></input>
                <label className='input__label'>{language.AgencyAbbreviation}</label> <br />
                <input placeholder={language.EnterAgencyAbbreviation}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, abbreviationAgencyName: evt.target.value })}
                    value={tenant.abbreviationAgencyName}
                ></input>
                <div className='inputs-row__no-justified'>
                    <div>
                        <label className='input__label'>{language.Currency}</label> <br />
                        <select
                            className='module__input-search'
                            onChange={evt => setTenant({ ...tenant, idCurrency: evt.target.value })}
                            value={tenant.idCurrency}>
                            <option>{language.SelectAnOption}</option>
                            {currencyData.map(currency => (
                                <option value={currency.idCurrency} key={currency.idCurrency}>{currency.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className='input__label'>{language.Active}</label> <br />
                        <img src={tenant.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox'
                            onClick={() => tenant.isActive === 1 ? setTenant({ ...tenant, isActive: 0 }) : setTenant({ ...tenant, isActive: 1 })} />
                    </div>
                    <div>
                        <label className='input__label'>{language.TimeZone}</label> <br />
                        <select
                            className='module__input-search'
                            onChange={evt => setTenant({ ...tenant, timeZone: evt.target.value })}
                            value={tenant.timeZone}>
                            <option>{language.SelectAnOption}</option>
                            {timeZones.map(timeZone => (
                                <option value={timeZone.idTimeZone} key={timeZone.idTimeZone}>{timeZone.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className='general-button primary' onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );

};

export default TenantsEditModal;