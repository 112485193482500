
const processDataGraphics = (data,type,data_type,language) =>{
    switch(type){
        case "heatmap":
            return heapMap(data.data,data.isTVM,data_type,language);
        case "lineMap":
            return lineMap(data,data_type);
        case "barChart":
            return barChart(data,data_type);
    }
}

function heapMap(data,isTVM,data_type,language){
    const sortedData = data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    const uniqueDates = Array.from(new Set(sortedData.map(item => item.Date))).sort();
    let uniqueData;
    if(isTVM){
        uniqueData = Array.from(new Set(sortedData.map(item => item.TVMName))).sort();
    } else {
        uniqueData = Array.from(new Set(sortedData.map(item => item.stationName))).sort();
    }
    let maxCount = 0;
    sortedData.forEach(item => {
        if (item.transactionCount > maxCount) {
            maxCount = item.transactionCount;
        }
    });

    const heatmapData = uniqueData.map(tvm => {
        return {
            name: tvm,
            data: uniqueDates.map(date => {
                let count;
                if(isTVM){
                    count = sortedData.find(item => item.TVMName === tvm && item.Date === date)?.transactionCount || 0;
                } else {
                    count = sortedData.find(item => item.stationName === tvm && item.Date === date)?.transactionCount || 0;
                }
                return { x: date, y: count };
            })
        };
    });
    
    const colorRanges = [
        { from: 0, to: 0, color: '#CCCCCC', name: language.NoData },
        { from: 1, to: Math.floor(maxCount * 0.25), color: '#99CCFF', name: language.Low },
        { from: Math.floor(maxCount * 0.25) + 1, to: Math.floor(maxCount * 0.5), color: '#0088FF', name: language.Medium },
        { from: Math.floor(maxCount * 0.5) + 1, to: maxCount, color: '#003D99', name: language.High }
    ];

    return { series: heatmapData, categories: uniqueDates, colorRanges };
}

function lineMap(data,data_type){
    const sortedData = data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    const categories = sortedData.map(item => item.Date);
    const seriesData = sortedData.map(item => parseFloat(item.income));
    return {categories,seriesData}
}

function barChart(data,data_type){
    const sortedData = data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    let categories, series;
    switch(data_type){
        case 1:
            categories = Array.from(new Set(sortedData.map(item => item.Date)));
            const products = Array.from(new Set(sortedData.map(item => item.ProductName)));

            series = products.map(product => {
                return {
                    name: product,
                    data: categories.map(date => {
                        const productData = sortedData.find(item => item.Date === date && item.ProductName === product);
                        return productData ? parseFloat(productData.Cost) : 0;
                    })
                };
            });

            return { categories, series };
        case 2:
            categories = Array.from(new Set(sortedData.map(item => item.Date))).sort();
            const denominationType = Array.from(new Set(sortedData.map(item => item.denominationType))).sort();
            series = denominationType.map(denomination => {
                return {
                    name: denomination,
                    data: categories.map(date => {
                        const denominationData = sortedData.find(item => item.Date === date && item.denominationType === denomination);
                        return denominationData ? parseFloat(denominationData.amount) : 0;
                    })
                };
            });

            return { categories, series };
    }
}

export default processDataGraphics;