import postMethod from "../../Hooks/API/post";

async function EditCommand(command, id) {
    const path = `/CommandsDevice/edit/${id}`;
    try {
        const { data, error } = await postMethod(command, path);
        return { data, error };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default EditCommand;