import Modal from 'react-bootstrap/Modal';
import { Button, Card, Form, ListGroup, Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';

import '../css/reports.css';

import createCustomReport from '../../../api/CustomReports/createCustomReport';
import {
    salesReportField_Parameters,
    salesReportField_GroupBy,
    salesReportField_Filter,
    alarmsReportField_Parameters,
    alarmsReportField_GroupBy,
    alarmsReportField_Filter,
    changesReportField_Parameters,
    changesReportField_GroupBy,
    changesReportField_Filter,
    reportType
} from '../reportDataEnum/reportsData.js';
import ErrorCases from '../../../Common/ErrorCases.js';

function CustomReportsNewModal({ show, onHide, setIsLoading, updateData, flagUpdateData }) {
    // #region get the tenant
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    // #endregion
    // #region variables stored in state hooks
    const [createData, setCreateData] = useState({
        name: '',
        description: '',
        parameters: {},
        Group: {},
        reportType: '',
        isActive: 1,
        tenantsDB: currentTenant
    });

    const [error, setError] = useState('');
    const [unassignedParameters, setUnassignedParameters] = useState([]);
    const [unassignedParametersSelect, setUnassignedParametersSelect] = useState([]);
    const [assignedParameters, setAssignedParameters] = useState([]);
    const [assignedParametersSelect, setAssignedParametersSelect] = useState([]);
    const [unassignedGroup, setUnassignedGroup] = useState([]);
    const [unassignedGroupSelect, setUnassignedGroupSelect] = useState([]);
    const [assignedGroup, setAssignedGroup] = useState([]);
    const [assignedGroupSelect, setAssignedGroupSelect] = useState([]);
    const [assignedFilters, setAssignedFilters] = useState([]);
    const [filterValues, setFilterValues] = useState({});
    // #endregion
    // #region get the language
    const language = JSON.parse(localStorage.getItem('language'));
    // #endregion
    // #region Events handle
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setCreateData({
            ...createData, reportType: selectedValue,
        });
        switch (selectedValue) {
            case reportType.Sales:
                setUnassignedParameters(salesReportField_Parameters);
                setUnassignedGroup(salesReportField_GroupBy);
                setAssignedFilters(salesReportField_Filter);
                break;
            case reportType.Alarms:
                setUnassignedParameters(alarmsReportField_Parameters);
                setUnassignedGroup(alarmsReportField_GroupBy);
                setAssignedFilters(alarmsReportField_Filter);
                break;
            case reportType.Changes:
                setUnassignedParameters(changesReportField_Parameters);
                setUnassignedGroup(changesReportField_GroupBy);
                setAssignedFilters(changesReportField_Filter);
                break;
            default:
                clearModal(true);
                break;
        }
    };

    const handleError = (errorMessage) => {
        setError(errorMessage);
        setTimeout(() => {
            setError('');
        }, 10000);
    };

    const handleSave = async () => {
        try {
            if (!createData.name.trim()) {
                handleError(language.ValidateName);
                return;
            }
    
            if (!createData.reportType) {
                handleError(language.ValidateType);
                return;
            }
    
            setIsLoading(true);
            const Group = assignedGroup.reduce((obj, item, index) => {
                obj[item.name] = index + 1;
                return obj;
            }, []);
            let parameters = {};
            assignedParameters.forEach((item) => {
                parameters = {
                    ...parameters,
                    [item.name]: true
                };
            });
    
            createData.parameters = parameters;
            createData.Group = Group;
            createData.filters = filterValues;

            if (Object.keys(createData.parameters).length === 0) {
                handleError(language.ValidateParameters);
                setIsLoading(false);
                return;
            }
    
            setError('');
    
            const response = await createCustomReport(createData);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                handleError(error);
                return;
            }
            updateData(!flagUpdateData);
            clearModal(false);
            onHide();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        clearModal(false);
        onHide();
    };

    const clearModal = (selectClear) => {
        if (!selectClear) {
            setCreateData({
                ...createData,
                name: '',
                description: '',
                parameters: {},
                Group: {},
                Filters: {},
                reportType: '',
                isActive: 1,
                tenantsDB: currentTenant
            });
        }

        setUnassignedParameters([]);
        setAssignedParameters([]);
        setUnassignedGroup([]);
        setAssignedGroup([]);
        /* Clear the select assigned and unassigned */
        setUnassignedParametersSelect([]);
        setAssignedParametersSelect([]);
        setUnassignedGroupSelect([]);
        setAssignedGroupSelect([]);

        setAssignedFilters([]);
        setFilterValues([]);
    }
    //filters handle
    const handleFilterValueChange = (event, filterName) => {
        const value = event.target.value;
        setFilterValues({
            ...filterValues,
            [filterName]: value
        });
    };

    /* start: handle checkbox assigned or unassigned */
    const handleChangeAssignedParameters = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setUnassignedParametersSelect([...unassignedParametersSelect, value]);
        } else {
            setUnassignedParametersSelect(unassignedParametersSelect.filter(item => item !== value));
        }
    }
    const handleChangeUnassignedParameters = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setAssignedParametersSelect([...assignedParametersSelect, value]);
        } else {
            setAssignedParametersSelect(assignedParametersSelect.filter(item => item !== value));
        }
    }

    const handleChangeAssignedGroup = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setUnassignedGroupSelect([...unassignedGroupSelect, value]);
        } else {
            setUnassignedGroupSelect(unassignedGroupSelect.filter(item => item !== value));
        }
    }
    const handleChangeUnassignedGroup = (event) => {
        const { checked, value } = event.target;
        if (checked) {
            setAssignedGroupSelect([...assignedGroupSelect, value]);
        } else {
            setAssignedGroupSelect(assignedGroupSelect.filter(item => item !== value));
        }
    }
    /* end: handle checkbox assigned or unassigned */

    /* start: handle btn  assigned or unassigned  */
    const handleAssignParametersItems = () => {
        assignedParametersSelect.forEach(name => {
            const selectedParameters = unassignedParameters.find(item => item.name === name);
            if (selectedParameters) {
                setAssignedParameters(unassignedParameters => [...unassignedParameters, selectedParameters]);
            }
        });

        setUnassignedParameters(unassignedParameters.filter(item => !assignedParametersSelect.includes(item.name)));
        setAssignedParametersSelect([]);
    }
    const handleUnassignParametersItems = () => {
        unassignedParametersSelect.forEach(name => {
            const selectedParameters = assignedParameters.find(item => item.name === name);
            if (selectedParameters) {
                setUnassignedParameters(assignedParameters => [...assignedParameters, selectedParameters]);
            }
        });

        const assignedItem = assignedParameters.filter(item => !unassignedParametersSelect.includes(item.name));

        setAssignedParameters(assignedItem);
        setUnassignedParametersSelect([]);
    }

    const handleAssignGroupItems = () => {
        assignedGroupSelect.forEach(name => {
            const selectedGroup = unassignedGroup.find(item => item.name === name);
            if (selectedGroup) {
                setAssignedGroup(unassignedGroup => [...unassignedGroup, selectedGroup]);
            }
        });

        setUnassignedGroup(unassignedGroup.filter(item => !assignedGroupSelect.includes(item.name)));
        setAssignedGroupSelect([]);
    }
    const handleUnassignGroupItems = () => {
        unassignedGroupSelect.forEach(name => {
            const selectedGroup = assignedGroup.find(item => item.name === name);
            if (selectedGroup) {
                setUnassignedGroup(assignedGroup => [...assignedGroup, selectedGroup]);
            }
        });

        const assignedItem = assignedGroup.filter(item => !unassignedGroupSelect.includes(item.name));

        setAssignedGroup(assignedItem);
        setUnassignedGroupSelect([]);
    }
    /* end: handle btn  assigned or unassigned  */
    // #endregion

    return (
        <Modal show={show} onHide={handleCancel} centered>
            <Modal.Header>
                <Modal.Title>{language.NewReport}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*Message error */}
                {error && <div className="alert alert-modal alert-danger" style={{ margin: '10px' }}>{error}</div>}
                {/* Information fields */}
                <div className='inputs-row__no-justified'>
                    <div className='input-name'>
                        <label className='input__label_modal'>{language.Name}</label> <br />
                        <input placeholder="Enter the report name"
                            className='module__input-search modal__text-box email-input'
                            onChange={evt => setCreateData({ ...createData, name: evt.target.value })}
                        ></input>
                    </div>
                    <div className='input-name'>
                        <label className='input__label_modal'>{language.Description}</label> <br />
                        <input placeholder="Enter a description"
                            className='module__input-search modal__text-box email-input'
                            onChange={evt => setCreateData({ ...createData, description: evt.target.value })}
                        ></input>
                    </div>
                    <div>
                        <label className='input__label_modal'>{language.Active}</label> <br />
                        <img src={createData.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox'
                            onClick={evt => createData.isActive === 1 ? setCreateData({ ...createData, isActive: 0 }) : setCreateData({ ...createData, isActive: 1 })} />
                    </div>
                </div>
                <div>
                    <label className='input__label_modal' htmlFor="report">Select a report</label>
                    <Form.Control
                        as="select"
                        onChange={handleSelectChange}
                        className='module__input-search modal__text-box custom-dropdown custom-dropdown-modal'
                    >
                        <option key={0} value=''>Select a report</option>
                        {Object.keys(reportType).map((key) => (
                            <option key={key} value={reportType[key]}>
                                {reportType[key]}
                            </option>
                        ))}
                    </Form.Control>
                </div>
                {/* select parameters and group by for table */}
                <Tabs defaultActiveKey="fields" id="modal-tabs" className="mb-3 modal-tabs">
                    <Tab eventKey="fields" title={language.columns}>
                        <div className='inputs-row__no-justified email-assign__row'>
                            <div style={{ overflowY: '1px' }}>
                                <Card style={{ width: '16.5rem', overflow: '10px' }}>
                                    <Card.Header>{'Unassigned'}</Card.Header>
                                    <ListGroup variant="flush" className='scrollable-list'>
                                        {unassignedParameters.map(parameters => {
                                            return (
                                                <ListGroup.Item>
                                                    <input type="checkbox"
                                                        key={parameters.name}
                                                        value={parameters.name}
                                                        onChange={handleChangeUnassignedParameters}
                                                        className='email-assign__checkbox'
                                                    />
                                                    {language[parameters.name]}
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                                <Button className='general-button primary margin-btn' onClick={handleAssignParametersItems}>
                                    Assign
                                </Button>
                            </div>

                            <div>
                                <Card style={{ width: '16.5rem', overflow: '10px' }}>
                                    <Card.Header>{'Assigned'}</Card.Header>
                                    <ListGroup variant="flush" className='scrollable-list'>
                                        {assignedParameters.map(parameters => {
                                            return (
                                                <ListGroup.Item>
                                                    <input type="checkbox"
                                                        key={parameters.name}
                                                        value={parameters.name}
                                                        onChange={handleChangeAssignedParameters}
                                                        className='email-assign__checkbox'
                                                    />
                                                    {language[parameters.name]}
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                                <Button className='general-button primary margin-btn' onClick={handleUnassignParametersItems}>
                                    Unassign
                                </Button>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="group-by" title={language.GroupBy}>
                        <div className='inputs-row__no-justified email-assign__row'>
                            <div>
                                <Card style={{ width: '16.5rem' }}>
                                    <Card.Header>{'Unassigned'}</Card.Header>
                                    <ListGroup variant="flush" className='scrollable-list'>
                                        {unassignedGroup.map(group => {
                                            return (
                                                <ListGroup.Item>
                                                    <input type="checkbox"
                                                        key={group.name}
                                                        value={group.name}
                                                        onChange={handleChangeUnassignedGroup}
                                                        className='email-assign__checkbox'
                                                    />
                                                    {language[group.name]}
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                                <Button className='general-button primary margin-btn' onClick={handleAssignGroupItems}>
                                    Assign
                                </Button>
                            </div>
                            <div>
                                <Card style={{ width: '16.5rem' }}>
                                    <Card.Header>{'Assigned'}</Card.Header>
                                    <ListGroup variant="flush" className='scrollable-list'>
                                        {assignedGroup.map(group => {
                                            return (
                                                <ListGroup.Item>
                                                    <input type="checkbox"
                                                        key={group.name}
                                                        value={group.name}
                                                        onChange={handleChangeAssignedGroup}
                                                        className='email-assign__checkbox'
                                                    />
                                                    {language[group.name]}
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                                <Button className='general-button primary margin-btn' onClick={handleUnassignGroupItems}>
                                    Unassign
                                </Button>
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="filters" title={language.FilterBy}>
                        <div className='inputs-row__no-justified email-assign__row'>
                            <div style={{ overflowY: '1px' }}>
                                <Card style={{ width: '33rem', overflow: '10px' , }}>
                                    <Card.Header>{'Filters'}</Card.Header>
                                    <ListGroup variant="flush" className='scrollable-list'>
                                        {assignedFilters.map(Filter => {
                                            return (
                                                <ListGroup.Item className='filter-value-input'>
                                                    {language[Filter.name]+': '}
                                                    <input
                                                        type="text"
                                                        placeholder={`Enter value for ${language[Filter.name]}`}
                                                        value={filterValues[Filter.name] || ''}
                                                        onChange={(e) => handleFilterValueChange(e, Filter.name)}
                                                        className='filter-field'
                                                    />
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Card>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button className="general-button danger" onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className="general-button primary" onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CustomReportsNewModal;