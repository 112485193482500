import postMethod from "../../Hooks/API/post";

async function compareDashboard(body) {  
    const path = '/dashBoard/TVM/compare';

    try {
        const data = await postMethod(body,path);
        return data;
    } catch (error) {
        return error;
    }
} 

export default compareDashboard;