import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaExpand, FaCompress } from 'react-icons/fa';
import processDataGraphics from './processDataGraphics';

const LineChart = ({ data, title, currency, languageCode}) => {
  const [expanded, setExpanded] = useState(false);
  const [chartData, setChartData] = useState({
    series: [{
      name: "Income",
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return new Intl.NumberFormat(languageCode, {
            style: 'currency',
            currency: currency.currency,
          }).format(val);
        }
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: title,
        align: 'center',
        margin: 20,
        offsetY: 20
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return new Intl.NumberFormat(languageCode, {
              style: 'currency',
              currency: currency.currency,
            }).format(val);
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return new Intl.NumberFormat(languageCode, {
              style: 'currency',
              currency: currency.currency,
            }).format(val);
          }
        }
      }
    },
  });

  useEffect(() => {
    const {categories,seriesData} = processDataGraphics(data,"lineMap",0);

    setChartData({
      series: [{
        name: "Income",
        data: seriesData
      }],
      options: {
        ...chartData.options,
        xaxis: {
          categories: categories,
        }
      }
    });
  }, [data]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`remove-design division ${expanded ? 'expanded' : ''}`}>
      <div className="chart-header">
        <button onClick={toggleExpand} className="expand-btn">
          {expanded ? <FaCompress /> : <FaExpand />}
        </button>
      </div>
      <ReactApexChart 
        options={chartData.options} 
        series={chartData.series} 
        type="line" height={350} />
    </div>
  );
};

export default LineChart;
