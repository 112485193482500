import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function EditStation(station, stationId) {
    const path = `/Stations/edit/${stationId}`;
    try {
        const data = await postMethod(station, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
};

export default EditStation;