import React, { useState, useEffect, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faMagnifyingGlass, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Box } from '@mui/material';

import getPermission from '../../auth/GetPermission.js';

import AddButton from '../../Common/AddButton.js'

import './css/ProductsPage.css';

import Spinner from 'react-bootstrap/Spinner';
import getProducts from '../../api/Fares/getProducts.js';
import ProductsNewModal from './ProductsNewModal.js';
import ProductsEditModal from './ProductsEditModal.js';
import deleteProduct from '../../api/Fares/deleteProduct.js';
import ZoneTable from './ZoneTable.js';
import ZoneNewModal from './ZoneNewModal.js';
import ZoneEditModal from './ZoneEditModal.js';
import RestrictionZoneTable from './RestrictionZoneTable.js';
import RestrictionZoneNewModal from './RestrictionZoneNewModal.js';
import RestrictionZoneEditModal from './RestrictionZoneEditModal.js';
import GeneralTable from '../../Hooks/Table/GeneralTable.js';
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from '../../Common/ErrorCases.js';

function Products() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currency = currentUser.data.catalog.currency[0];
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [productId, setProductId] = useState(0);
    const [product, setProduct] = useState([]);
    const [showZone, setShowZone] = useState(false);
    const [showZoneNewModal, setShowZoneNewModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [zones, setZones] = useState([]);
    const [editingZone, setEditingZone] = useState([]);
    const [editingZoneId, setEditingZoneId] = useState(0);
    const [showEditZoneModal, setShowEditZoneModal] = useState(false);
    const [editedZoneId, setEditedZoneId] = useState(0);
    const [restrictedZones, setRestrictedZones] = useState([]);
    const [showRestrictionZoneNewModal, setShowRestrictionZoneNewModal] = useState(false);
    const [showRestrictionZoneEditModal, setShowRestrictionZoneEditModal] = useState(false);
    const [editingRestrictionZone, setEditingRestrictionZone] = useState([]);
    const [fareZones, setFareZones] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
    const [deletingProduct, setDeletingProduct] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getProducts();

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (id) => {
        setProductId(id);
        const productToEdit = data.find((product) => product.idProduct === id);
        setProduct({ ...productToEdit, tenantsDB: currentUser.data.currentTenant });
        setShowEditModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const productToDelete = { id: id, tenantsDB: currentUser.data.currentTenant };
            const response = await deleteProduct(productToDelete);
            if (response.error !== '') {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);   
            setShowDeleteProductModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteProductConfirm = (product) => {
        setDeleteError('');
        setDeletingProduct(product);
        setShowDeleteProductModal(true);
    };

    const handleZoneTableClick = (id) => {
        setProductId(id);
        setShowZone(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.Name,
                accessorKey: 'name',
                size: 100
            },
            {
                header: language.ProductType,
                accessorKey: 'productType',
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: language.Price,
                accessorKey: 'price',
                Cell: ({ renderedCellValue }) => (
                    <Box>
                        {currency.sign + renderedCellValue}
                    </Box>
                ),
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.Active,
                accessorKey: 'isActive',

                Cell: ({ renderedCellValue }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox' />
                    </Box>
                ),
            },
            {
                header: language.ZoneFare,
                accessorKey: 'zoneFare',

                Cell: ({ renderedCellValue }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'} className='table__checkbox' />
                    </Box>
                ),
            },
            {
                header: language.FlatFare,
                accessorKey: 'flatFare',

                Cell: ({ renderedCellValue }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'} className='table__checkbox' />
                    </Box>
                ),
            }
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idProduct)}
                disabled={!getPermission(currentUser, "FareProducts", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteProductConfirm(row.original)}
                disabled={(!getPermission(currentUser, "FareProducts", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
            {
                row.original.zoneFare === 1 &&
                <button
                    className="btn"
                    onClick={() => handleZoneTableClick(row.original.idProduct)}
                    disabled={!getPermission(currentUser, "FareProducts", "readAllow")}>
                    <img src='./img/icons/pages/Seeker.svg' className='table__icon' />
                </button>
            }
        </Box>
    );

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Products}</h1>
            {
                getPermission(currentUser, "FareProducts", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    {
                        getPermission(currentUser, "FareProducts", "writeAllow") &&
                        <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
                    }
                    <ProductsNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                    />
                    <ProductsEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        editingProduct={product}
                        editingProductId={productId}
                        getData={getData}
                        setData={setData}
                    />

                    {
                        showDeleteProductModal &&
                        <DeleteModal
                            show={showDeleteProductModal}
                            onHide={() => setShowDeleteProductModal(false)}
                            message={language.DeleteProductMessage + deletingProduct.name}
                            handleDelete={() => handleDeleteClick(deletingProduct.idProduct)}
                            error={deleteError}
                        />
                    }

                    {
                        showZone &&
                        <>
                            <div className='subtitle__row'>
                                <h4 className='role-card__header-title'>{language.Zone}</h4>
                            </div>

                            <ZoneTable productId={productId}
                                setIsLoading={setIsLoading}
                                setProducts={setProducts}
                                setZones={setZones}
                                setEditingZone={setEditingZone}
                                setEditingZoneId={setEditingZoneId}
                                setShowEditZoneModal={setShowEditZoneModal}
                                editedZoneId={editedZoneId}
                                setEditedZoneId={setEditedZoneId}
                                setRestrictedZones={setRestrictedZones}
                                setFareZones={setFareZones} />
                            {
                                getPermission(currentUser, "FareProducts", "writeAllow") &&
                                <div className='addbutton__container' onClick={() => setShowZoneNewModal(true)}><AddButton /></div>
                            }

                            <ZoneNewModal
                                show={showZoneNewModal}
                                onHide={() => setShowZoneNewModal(false)}
                                products={products}
                                zones={zones}
                                setProductId={setProductId}
                                fareZones={fareZones}
                                setEditedZoneId={setEditedZoneId} />
                            <ZoneEditModal
                                show={showEditZoneModal}
                                onHide={() => setShowEditZoneModal(false)}
                                products={products}
                                zones={zones}
                                setProductId={setProductId}
                                editingZone={editingZone}
                                editingZoneId={editingZoneId}
                                setEditedZoneId={setEditedZoneId}
                            />



                            <div className='subtitle__row'>
                                <h4 className='role-card__header-title'>{language.RestrictionZone}</h4>
                            </div>

                            <RestrictionZoneTable
                                restrictedZones={restrictedZones}
                                setEditingRestrictionZone={setEditingRestrictionZone}
                                setShowRestrictionZoneEditModal={setShowRestrictionZoneEditModal}
                                setEditedZoneId={setEditedZoneId}
                                setIsLoading={setIsLoading}
                            />
                            {
                                getPermission(currentUser, "FareProducts", "writeAllow") &&
                                <div className='addbutton__container' onClick={() => setShowRestrictionZoneNewModal(true)}><AddButton /></div>
                            }

                            <RestrictionZoneNewModal
                                show={showRestrictionZoneNewModal}
                                onHide={() => setShowRestrictionZoneNewModal(false)}
                                zones={zones}
                                setEditedZoneId={setEditedZoneId}
                            />
                            <RestrictionZoneEditModal
                                show={showRestrictionZoneEditModal}
                                onHide={() => setShowRestrictionZoneEditModal(false)}
                                zones={zones}
                                setEditedZoneId={setEditedZoneId}
                                editingRestrictionZone={editingRestrictionZone}
                            />

                        </>
                    }

                </>
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>

    );
}

export default Products;