import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import getCurrencies from '../../api/Currency/getCurrencies';
import CreateTenant from '../../api/Tenants/createTenant';
import GetTimeZones from '../../api/Tenants/getTimeZones';
import ErrorCases from '../../Common/ErrorCases';

function TenantsNewModal({ show, onHide, setIsLoading, getData, setData }) {
    const [tenant, setTenant] = useState({
        tenantsDBName: '',
        tenantsName: '',
        agencyName: '',
        abbreviationAgencyName: '',
        idCurrency: 0,
        isActive: 1,
        timeZone: 0
    });
    const language = JSON.parse(localStorage.getItem('language'));
    const [currencyData, setCurrencyData] = useState([]);
    const [error, setError] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [timeZones, setTimeZones] = useState([]);
    const [saveError, setSaveError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getCurrencyData();
                setCurrencyData(response);
                const timeZonesResponse = await GetTimeZones(currentTenant);
                setTimeZones(timeZonesResponse.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    async function getCurrencyData() {
        const { data, error } = await getCurrencies(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const response = await CreateTenant(tenant);
            if (response.error !== undefined) {
                const error = ErrorCases(response.error);
                setSaveError(error);
                return;
            }
            const newData = await getData();
            setData(newData.tenants);
            onHide();
            clearData();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const clearData = () => {
        setTenant({
            tenantsDBName: '',
            tenantsName: '',
            agencyName: '',
            abbreviationAgencyName: '',
            idCurrency: 0,
            isActive: 1
        });
        setSaveError('');
    };

    const handleCancel = () => {
        onHide();
        clearData();
    };

    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.NewTenant}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className='input__label'>{language.TenantDBName}</label> <br />
                <input placeholder={language.EnterTenantDBName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, tenantsDBName: evt.target.value })}
                ></input>
                <label className='input__label'>{language.TenantName}</label> <br />
                <input placeholder={language.EnterTenantName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, tenantsName: evt.target.value })}
                ></input>
                <label className='input__label'>{language.Agency}</label> <br />
                <input placeholder={language.EnterAgencyName}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, agencyName: evt.target.value })}
                ></input>
                <label className='input__label'>{language.AgencyAbbreviation}</label> <br />
                <input placeholder={language.EnterAgencyAbbreviation}
                    className='module__input-search'
                    onChange={evt => setTenant({ ...tenant, abbreviationAgencyName: evt.target.value })}
                ></input>
                <div className='inputs-row__no-justified'>
                    <div>
                        <label className='input__label'>{language.Currency}</label> <br />
                        <select
                            className='module__input-search'
                            onChange={evt => setTenant({ ...tenant, idCurrency: evt.target.value })}>
                            <option>{language.SelectAnOption}</option>
                            {currencyData.map(currency => (
                                <option value={currency.idCurrency} key={currency.idCurrency}>{currency.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className='input__label'>{language.Active}</label> <br />
                        <img src={tenant.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox'
                            onClick={() => tenant.isActive === 1 ? setTenant({ ...tenant, isActive: 0 }) : setTenant({ ...tenant, isActive: 1 })} />
                    </div>
                    <div>
                        <label className='input__label'>{language.TimeZone}</label> <br />
                        <select
                            className='module__input-search'
                            onChange={evt => setTenant({ ...tenant, timeZone: evt.target.value })}>
                            <option>{language.SelectAnOption}</option>
                            {timeZones.map(timeZone => (
                                <option value={timeZone.idTimeZone} key={timeZone.idTimeZone}>{timeZone.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='error-alert'>{saveError}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className='general-button primary' onClick={handleSave}>
                    {language.Save}
                </Button>
            </Modal.Footer>
        </Modal>
    );

};

export default TenantsNewModal;