import GeneralTable from "../../../Hooks/Table/GeneralTable"
import { Box } from '@mui/material';

const CustomReportsTable = ({data,rowActions,language}) =>{

    const cells = (key) => {
        switch (key) {
            case 'isActive':
                return ({ renderedCellValue }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            className='table__checkbox'/>
                    </Box>
                );
            default:
                return undefined;
        }
    };

    const muiTableBodyCellProps = (key) => {
        switch (key) {
            case 'name':
            case 'descriptionReports':
            case 'ReportType':
                return ({align: 'left'});
            default:
                return undefined;
        }
    };

    const columns_table = [];
    if (data.length > 0) {
        Object.keys(data[0]).filter(key => key !== 'idCustomReports').forEach(key => {
            
            columns_table.push({
                header: language[key],
                accessorKey: key,
                Cell:cells(key),
                muiTableBodyCellProps:muiTableBodyCellProps(key)
            });
        });
    }
    
    return (
        <GeneralTable
            data={data} 
            columns_table={columns_table} 
            actions={true}
            rowActions={rowActions}
        />
    );
}

export default CustomReportsTable;