import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import { signOut } from 'aws-amplify/auth';

async function postMethod(body, path) {
    const postBody = body;
    try {
        let idToken = '';
        const session = await fetchAuthSession();
        idToken = session.tokens.idToken.toString();
        const apiName = 'UPA';

        const options = {
            headers: {
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
            body: postBody
        };

        const restOperation = post({
            apiName,
            path: path,
            options
        });

        const { body } = await restOperation.response;
        const data = await body.json();
        return data;
    } catch (error) {
        if (error.message.includes('The incoming token has expired') ||
            error.message.includes('Cannot read properties of undefined')) {
            localStorage.setItem('tokenExpired', JSON.stringify(true));
            signOut();
            window.location.reload();
        };
        return JSON.parse(error.response.body);
    }
}

export default postMethod;