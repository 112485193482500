export const Ports = [
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "COM10",
    "COM11",
    "COM12",
    "COM13",
    "COM14",
    "COM15",
    "COM16",
    "COM17",
    "COM18",
    "COM19",
    "COM20",
    "COM21",
    "COM22",
    "COM23",
    "COM24",
    "COM25",
    "COM26",
    "COM27",
    "COM28",
    "COM29",
    "COM30"
];