import './css/Paginator.css'

function Paginator (props){
    const language = JSON.parse(localStorage.getItem('language'));

    const handleFirstPage = () => {
        props.firstPage();
    }

    const handleNextPageClick = () => {
        props.nextPage();
    }

    const handlePreviousPageClick = () => {
        props.previousPage();
    }

    const handleLastPage = () => {
        props.lastPage();
    }

    return(
        <div className="paginator">
            <div className='paginator__pages'>{props.firstItem}-{props.lastItem} {language.Of} {props.totalItems}</div>
            <img src='./img/icons/pages/Arrow3.svg' className='paginator__button' onClick={handleFirstPage}/>
            <img src='./img/icons/pages/Arrow4.svg' className='paginator__button' onClick={handlePreviousPageClick}/>
            <img src='./img/icons/pages/Arrow5.svg' className='paginator__button' onClick={handleNextPageClick}/>
            <img src='./img/icons/pages/Arrow6.svg' className='paginator__button' onClick={handleLastPage}/>
        </div>
    );
}

export default Paginator;