import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ExpandAllButton,
} from 'material-react-table';
import { Box, Stack } from '@mui/material';

const GroupTable = ({device_data,columns_table,columns_color,handleDetails,language}) => {
  
  const columns = useMemo(() => columns_table,[columns_table,columns_color]);

  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  };

  const table = useMaterialReactTable({
    columns,
    data:device_data,
    enableSorting:false,
    enablePagination: false,
    enableGrouping: true,
    manualGrouping:false,
    enableDensityToggle:false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    defaultColumn: {
      maxSize: 100,
      minSize: 20,
      size: 50,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    layoutMode: 'semantic',
    groupedColumnMode: 'remove'/*remove|reorder*/,

    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>{language.Groups}</Box>
          </Stack>
        ),
        //groupedByLabel: "Agrupado por",
        enableResizing: true,
        muiTableBodyCellProps: () => ({
          sx: () => ({
            color: '#000',
            fontWeight: 'bold'
          }),
        }),
      },
    },

    initialState: {
      density: 'compact',
      expanded: true,
      grouping: ['nameStation'],
    },    
    muiTableHeadCellProps: {
      sx: {
          fontWeight: 'bold',
          fontFamily: 'Open Sans bold',
          fontSize: '0.85rem',
          color: '#000000',
          '& .Mui-TableHeadCell-Content': {
              justifyContent: 'center',
          },
          backgroundColor: 'white',
          alignContent: 'center',
          alignItems: 'center'
      },
      align: 'left',
      size: 'fit-content',
      
  },
    muiTableBodyCellProps: ({ cell }) => {
        const columnId = cell.column.id;
        const rowData = cell.row.original;
        let color = '';
        let backGroundColor = '';
        let cursor = 'default';
        const colorKey = columns_color.find(item => item[columnId])?.[columnId];

        if (colorKey) {
          color = rowData[colorKey] || '#000';
          backGroundColor = rowData[colorKey] || 'Transparent';
        }

        if(columnId === 'totalAlarm'){
          cursor = 'pointer';
        }


        return {
            sx: {
              cursor,
              backgroundColor: `rgba(${hexToRgb(backGroundColor)}, 0.45)`,
              padding: '2px',
              fontFamily: 'Open Sans Semibold',
              fontSize: '0.8rem', 
            },
            align: 'center',
            
            onClick: () => handleDetails(rowData.idMachine,columnId,rowData),
        };
    },
    muiBottomToolbarProps: {
        sx: {
            borderTop: '2px solid #003865',
            boxShadow: '0'
        },
    },
    muiTopToolbarProps: {
        sx: {
            border: '0',
            boxShadow: '0',
            marginLeft: '0'
        },
        align: 'center'
    },
    muiTablePaperProps: {
        sx: {
            m: 'auto',
            maxWidth: '100%',
          }
    },
  });

  return (
    <Box sx={{ margin: '0 auto 0 auto', maxWidth: 'fit-content' }}> 
      {table && (<MaterialReactTable table={table} />)}
    </Box>
  );
};

export default GroupTable;
