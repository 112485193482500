import * as React from "react";
import { useState, useEffect } from 'react';

import "./css/Auth.css";

import { MdOutlineSms } from "react-icons/md";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from "react-bootstrap";

import {
  confirmSignIn,
  fetchUserAttributes,
  fetchMFAPreference,
  getCurrentUser
} from 'aws-amplify/auth';

import { Link, useNavigate } from "react-router-dom";

import GetUser from '../api/EMSuser/getUser';
import { fetchAuthSession } from 'aws-amplify/auth';
import CustomAlert from '../Common/CustomAlert';
import languages from './LoginLanguage.json';
import CurrentLanguageFile from "../Hooks/language/CurrentLanguageFile";
import userControlTenants from '../api/EMSuser/userControlTenants';
import SendEmail from "../api/EMSuser/sendEmail";

function SmsCode() {
  const [smsCode, setSmsCode] = useState('');
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const currentLanguage = localStorage.getItem('currentLanguage');
  const language = languages[currentLanguage][0];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));

    ((authenticated && currentLocalUser.data.tenantsUser.length === 1) || (authenticated && currentLocalUser.data.currentTenant !== '' && currentLocalUser.data.tenantsUser.length > 1))
      && navigate('/Home');

    (authenticated && currentLocalUser.data.currentTenant === '' && currentLocalUser.data.tenantsUser.length > 1)
      && navigate('/select-tenant');

    setIsLoading(true);
    checkAuthentication();
    setIsLoading(false);
  }, [authenticated]);

  const checkAuthentication = async () => {
    try {
      const currentUser = await getCurrentUser();
      setAuthenticated(!!currentUser);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const response = await confirmSignIn({
        challengeResponse: smsCode
      });
      const userAttributes = await fetchUserAttributes();
      if (response.nextStep.signInStep === 'DONE' && userAttributes.email_verified === 'true') {

        const output = await fetchMFAPreference();

        const userAttributes = await fetchUserAttributes();
        const userSub = userAttributes.sub;
        if (userSub) {
          const tenants = await userControlTenants({ idCognito: userSub });
          const currentTenantsControl = tenants[0].tenantsKey
          localStorage.setItem('currentUser', JSON.stringify({
            data: {
              currentTenant: currentTenantsControl
            }
          }));
        }

        const currentUser = await GetUser();
        await CurrentLanguageFile(currentUser);
        localStorage.setItem('currentLanguage', currentUser.data.userData.languageAbbreviation);
        if (currentUser.data.tenantsUser.length === 1) {
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          navigate('/Home');
          navigate(0);
        } else {
          currentUser.data.currentTenant = '';
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          navigate('/select-tenant');
        }
      } if (response.nextStep.signInStep === 'DONE' && (!userAttributes.email_verified || userAttributes.email_verified === 'false')) {
        const response = await SendEmail();
        navigate('/email-code');
      }
    } catch (error) {
      setShowAlert(true);
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleBackToLogin = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="main-container">
        <img
          loading="lazy"
          srcSet="/img/logoBEATT.png"
          className="login__logo"
        />
        <div className="login__container">
          <div className="login__inputs-group">
            <div className="login__input">
              <img src='./img/icons/login/Code.png' className='login__icon' />
              <Form.Control className="login__form" type="text" placeholder={language.SMSCode} 
                onChange={evt => setSmsCode(evt.target.value)} />
            </div>
          </div>
          <div className="button-light-blue" onClick={handleConfirm}>{language.Confirm}</div>
        </div>
        <div className="login__bottom--centered">
          <div className="login__bottom-forgot"
            onClick={handleBackToLogin}>{language.BackToLogin}</div>
        </div>
        {
          showAlert &&
          <CustomAlert message={language.CodeIncorrectAlert} />
        }
      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
    </>
  );
}

export default SmsCode;