import deleteMethod from "../../Hooks/API/DeleteMethod";

async function DeleteEmailAlarm(id, tenantsDB) {
    const queryParameters = {
        tenantsDB
    };
    const path = `/EmailAlarms/${id}`;
    try {
        const { data, error } = await deleteMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default DeleteEmailAlarm;