import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function CreateStation(station) {  
    const path = '/Stations/add';
    try {
        const data = await postMethod(station, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default CreateStation;