import './css/GlobalConfigPage.css';
import getGlobalConfig from '../../../api/TVM/getGlobalConfig';
import updateGlobalConfig from '../../../api/TVM/updateGlobalConfig';
import getPermission from "../../../auth/GetPermission";
import getAllLanguage from '../../../api/Languages/getAllLanguage';

import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ErrorCases from '../../../Common/ErrorCases';

function GlobalConfig() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [error, setError] = useState(null);
    const [timeError, setTimeError] = useState('');
    const [generalAlarmError, setGeneralAlarmError] = useState('');
    const [speechError, setSpeechError] = useState('');
    const [ticketsError, setTicketsError] = useState('');
    const [languageError, setLanguageError] = useState('');
    const [saveAlert, setSaveAlert] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [languagesData, setLanguagesData] = useState([]);
    const [darkModeError, setDarkModeError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getGlobalConfigData();
                setData(response.globalConfig[0]);
                const languages = await getLanguages();
                setLanguagesData(languages);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        }

        fetchData();

    }, [updateData]);

    async function getGlobalConfigData() {
        const { data, error } = await getGlobalConfig(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getLanguages() {
        const { data, error } = await getAllLanguage(currentTenant);

        if (error !== undefined || data === undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleSave = async () => {
        if (!data.PrimaryLanguage || !data.SecondaryLanguage) {
            setLanguageError(language.LanguagesMustBeSelected);
        } else {
            setLanguageError('');
        }

        if (timeError || darkModeError || generalAlarmError || speechError || ticketsError || languageError) {
            setSaveAlert(language.CheckErrors)
        } else {
            try {
                setIsLoading(true);
                setSaveAlert('')
                const response = await updateGlobalConfig(data, currentTenant);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setSaveAlert(error);
                    return;
                }
                setUpdateData(response);
            } catch (error) {
                console.error(error);
                setSaveAlert(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleChangeStartTime = (event, startKey, endKey) => {
        setData({ ...data, [startKey]: event.target.value + ":00" })
        if (event.target.value >= data[endKey]) {
            if (startKey === "startTime") {
                setTimeError(language.StartTimeBeforeEndTime);
            } else {
                setDarkModeError(language.StartTimeBeforeEndTime);
            }
        } else {
            setTimeError('');
            setDarkModeError('');
        }
    };

    const handleChangeEndTime = (event, startKey, endKey) => {
        setData({ ...data, [endKey]: event.target.value + ":00" })
        if (data[startKey] >= event.target.value) {
            if (endKey === "endTime") {
                setTimeError(language.StartTimeBeforeEndTime);
            } else {
                setDarkModeError(language.StartTimeBeforeEndTime);
            }
        } else {
            setTimeError('');
            setDarkModeError('');
        }
    };

    const handleChangeGeneralAlarmDelay = (event) => {
        setData({ ...data, generalAlarmSendingDelayInSeconds: event.target.value })
        if (event.target.value < 10 || event.target.value > 600) {
            setGeneralAlarmError(language.AlarmDelayAtLeastMaximum);
        } else {
            setGeneralAlarmError('');
        }
    };

    const handleChangeAlarmDelay = (event) => {
        setData({ ...data, alarmSendingDelayInSeconds: event.target.value })
        if (event.target.value < 10 || event.target.value > 600) {
            setGeneralAlarmError(language.AlarmDelayAtLeastMaximum);
        } else {
            setGeneralAlarmError('');
        }
    };

    const alarmTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {language.AlarmDelaySeconds}
        </Tooltip>
    );

    const handleChangeRepetitionsSpeech = (event) => {
        setData({ ...data, numberRepetitionsSpeech: event.target.value })
        if (event.target.value < 1 || event.target.value > 3) {
            setSpeechError(language.RepetitionsRange);
        } else {
            setSpeechError('');
        }
    };

    const handleChangeMaximumTickets = (event) => {
        setData({ ...data, maximumNumberTicketsPurchase: event.target.value })
        if (event.target.value < 1 || event.target.value > 10) {
            setTicketsError(language.TicketsRange);
        } else {
            setTicketsError('');
        }
    };

    const handleZoneFareCheck = () => {
        const newConfig = data;
        newConfig.zoneActive === 1 ? newConfig.zoneActive = 0 : newConfig.zoneActive = 1;
        if (newConfig.zoneActive === 1 && newConfig.flatFareActive === 1) {
            newConfig.flatFareActive = 0;
        }
        setData({ ...newConfig });
    };

    const handleFlatFareCheck = () => {
        const newConfig = data;
        newConfig.flatFareActive === 1 ? newConfig.flatFareActive = 0 : newConfig.flatFareActive = 1;
        if (newConfig.zoneActive === 1 && newConfig.flatFareActive === 1) {
            newConfig.zoneActive = 0;
        }
        setData({ ...newConfig });
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.GlobalConfig}</h1>
            <div className='main-box'>
                {getPermission(currentUser, "GLobalConfigTVM", "readAllow") &&
                    <>
                        <hr />
                        <div className='modal__subtitle'>{language.ScheduleConfiguration}</div>
                        <div className='inputs-row'>
                            <div>
                                <label className='input__label'>{language.EnableWorkHours}:</label> <br />
                                <img src={data.isEnable === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='checkbox'
                                    onClick={() => data.isEnable === 1 ? setData({ ...data, isEnable: 0 }) :
                                        setData({ ...data, isEnable: 1 })} />
                            </div>
                            <div>
                                <label className='input__label'>{language.StartTime}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => handleChangeStartTime(evt, "startTime", "endTime")}
                                    type="time"
                                    value={data.startTime || ''}
                                    disabled={data.isEnable !== 1}
                                ></input>
                            </div>
                            <div >
                                <label className='input__label'>{language.EndTime}:</label> <br />
                                <input
                                    className='module__input-search  email-input'
                                    onChange={evt => handleChangeEndTime(evt, "startTime", "endTime")}
                                    type="time"
                                    value={data.endTime || ''}
                                    disabled={data.isEnable !== 1}
                                ></input>

                            </div>

                            <div>
                                <label className='input__label'>{language.GeneralAlarmDelay}:</label> <br />
                                <div className='input-information__row'>
                                    <input
                                        className='module__input-search modal__text-box email-input'
                                        onChange={handleChangeGeneralAlarmDelay}
                                        type='number'
                                        value={data.generalAlarmSendingDelayInSeconds || ''}
                                    ></input>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={alarmTooltip}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>

                                </div>
                            </div>
                            <div>
                                <label className='input__label'>{language.AlarmDelay}:</label> <br />
                                <div className='input-information__row'>
                                    <input
                                        className='module__input-search modal__text-box email-input'
                                        onChange={handleChangeAlarmDelay}
                                        type='number'
                                        value={data.alarmSendingDelayInSeconds || ''}
                                    ></input>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={alarmTooltip}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>
                                </div>

                            </div>
                        </div>

                        {timeError && <div className='error-alert'>{timeError}</div>}
                        {generalAlarmError && <div className='error-alert'>{generalAlarmError}</div>}

                        <hr />
                        <div className='modal__subtitle'>{language.DarkModeSchedule}</div>
                        <div className='inputs-row__no-justified'>
                            <div>
                                <label className='input__label'>{language.EnableWorkHours}:</label> <br />
                                <img src={data.isEnable_darkMode === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='checkbox'
                                    onClick={() => data.isEnable_darkMode === 1 ? setData({ ...data, isEnable_darkMode: 0 }) :
                                        setData({ ...data, isEnable_darkMode: 1 })} />
                            </div>
                            <div>
                                <label className='input__label'>{language.StartTime}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => handleChangeStartTime(evt, "startTime_darkMode", "endTime_darkMode")}
                                    type="time"
                                    value={data.startTime_darkMode || ''}
                                    disabled={data.isEnable_darkMode !== 1}
                                ></input>
                            </div>
                            <div >
                                <label className='input__label'>{language.EndTime}:</label> <br />
                                <input
                                    className='module__input-search  email-input'
                                    onChange={evt => handleChangeEndTime(evt, "startTime_darkMode", "endTime_darkMode")}
                                    type="time"
                                    value={data.endTime_darkMode || ''}
                                    disabled={data.isEnable_darkMode !== 1}
                                ></input>
                            </div>
                        </div>

                        {darkModeError && <div className='error-alert'>{darkModeError}</div>}

                        <hr />
                        <div className='modal__subtitle'>{language.LanguageSettings}</div>
                        <div className='inputs-row'>
                            <div>
                                <label className='input__label'>{language.BucketName}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => setData({ ...data, bucketName: evt.target.value })}
                                    value={data.bucketName || ''}
                                ></input>
                            </div>
                            <div>
                                <label className='input__label'>{language.PrimaryLanguage}:</label> <br />
                                <select value={data.PrimaryLanguage || ''}
                                    onChange={evt => setData({ ...data, PrimaryLanguage: evt.target.value })}
                                    className='module__input-search'>
                                    <option>{language.SelectYourLanguage}</option>
                                    {
                                        languagesData.length > 0 && languagesData.map(item => {
                                            return (
                                                <option key={item.id} value={item.abbreviation}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label className='input__label'>{language.SecundaryLanguage}:</label> <br />
                                <select value={data.SecondaryLanguage || ''}
                                    onChange={evt => setData({ ...data, SecondaryLanguage: evt.target.value })}
                                    className='module__input-search'>
                                    <option>{language.SelectYourLanguage}</option>
                                    {
                                        languagesData.length > 0 && languagesData.map(item => {
                                            return (
                                                <option key={item.id} value={item.abbreviation}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label className='input__label'>{language.RepetitionsSpeech}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={handleChangeRepetitionsSpeech}
                                    type='number'
                                    value={data.numberRepetitionsSpeech || ''}
                                ></input>
                            </div>
                        </div>

                        {speechError && <div className='error-alert'>{speechError}</div>}
                        {languageError && <div className='error-alert'>{languageError}</div>}

                        <hr />
                        <div className='modal__subtitle'>{language.ColorSettings}</div>
                        <div className='inputs-row'>
                            <div>
                                <label className='input__label'>{language.PromotionalColor}:</label> <br />
                                <Form.Control
                                    type="color"
                                    id="promotionalColor"
                                    value={data.promotinalColor_btn || ''}
                                    title={language.ChooseColor}
                                    onChange={evt => setData({ ...data, promotinalColor_btn: evt.target.value })}
                                />
                            </div>
                            <div>
                                <label className='input__label'>{language.PrimaryColor}:</label> <br />
                                <Form.Control
                                    type="color"
                                    id="primaryColor"
                                    value={data.PrimaryColor_btn || ''}
                                    title={language.ChooseColor}
                                    onChange={evt => setData({ ...data, PrimaryColor_btn: evt.target.value })}
                                />
                            </div>
                            <div>
                                <label className='input__label'>{language.SecundaryColor}:</label> <br />
                                <Form.Control
                                    type="color"
                                    id="secundaryColor"
                                    value={data.SecondaryColor_btn || ''}
                                    title={language.ChooseColor}
                                    onChange={evt => setData({ ...data, SecondaryColor_btn: evt.target.value })}
                                />
                            </div>
                            <div>
                                <label className='input__label'>{language.ThirdColor}:</label> <br />
                                <Form.Control
                                    type="color"
                                    id="thirdColor"
                                    value={data.ThirdColor_btn || ''}
                                    title={language.ChooseColor}
                                    onChange={evt => setData({ ...data, ThirdColor_btn: evt.target.value })}
                                />
                            </div>
                            <div>
                                <label className='input__label'>{language.CancelColor}:</label> <br />
                                <Form.Control
                                    type="color"
                                    id="cancelColor"
                                    value={data.cancelColor_btn || ''}
                                    title={language.ChooseColor}
                                    onChange={evt => setData({ ...data, cancelColor_btn: evt.target.value })}
                                />
                            </div>
                        </div>

                        <hr />
                        <div className='modal__subtitle'>{language.FaresSettings}</div>
                        <div className='inputs-row__no-justified'>
                            <div>
                                <label className='input__label'>{language.ZoneFare}:</label> <br />
                                <img src={data.zoneActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='checkbox'
                                    onClick={handleZoneFareCheck} />
                            </div>

                            <div>
                                <label className='input__label'>{language.FlatFare}:</label> <br />
                                <img src={data.flatFareActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                    className='checkbox'
                                    onClick={handleFlatFareCheck} />
                            </div>
                        </div>

                        <hr />
                        <div className='modal__subtitle'>{language.GeneralSettings}</div>
                        <div className='inputs-row__no-justified'>
                            <div>
                                <label className='input__label'>{language.EndpointWS}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => setData({ ...data, EndPointWS: evt.target.value })}
                                    value={data.EndPointWS || ''}
                                ></input>
                            </div>
                            <div>
                                <label className='input__label'>{language.MaximumTickets}:</label> <br />
                                <input
                                    className='module__input-search modal__text-box email-input'
                                    onChange={handleChangeMaximumTickets}
                                    type='number'
                                    value={data.maximumNumberTicketsPurchase || ''}
                                ></input>
                            </div>
                        </div>

                        {ticketsError && <div className='error-alert'>{ticketsError}</div>}

                        <hr />
                        <div className='footer'>
                            <div className='footer-button'>
                                {getPermission(currentUser, "GLobalConfigTVM", "writeAllow") &&
                                    <Button className='general-button primary' onClick={handleSave}>
                                        {language.Save}
                                    </Button>
                                }
                            </div>
                            {saveAlert && <div className='error-alert'>{saveAlert}</div>}
                        </div>
                    </>
                }
            </div>

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>
    );
};

export default GlobalConfig;