import QRCode from "react-qr-code";

function QR({ value }) {
    return (
        <div style={{ height: "auto", margin: "1rem auto", width: "50%" }}>
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={value}
                viewBox={`0 0 256 256`}
            />
        </div>
    );
};

export default QR;