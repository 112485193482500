import React, { useState, useEffect, useMemo } from 'react';

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Box } from '@mui/material';

import deleteCountry from '../../api/Location/deleteCountry.js';
import getPermission from '../../auth/GetPermission.js';
import getCountries from '../../api/Location/getCountries.js';
import DeleteModal from '../../Common/DeleteModal';

import AddButton from '../../Common/AddButton.js'

import './css/CountriesPage.css';

import Spinner from 'react-bootstrap/Spinner';
import CountriesNewModal from './CountriesNewModal';
import CountriesEditModal from './CountriesEditModal.js';
import GeneralTable from '../../Hooks/Table/GeneralTable.js';

function Countries() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(100);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [country, setCountry] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingCountry, setDeletingCountry] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getCountries(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (countryId) => {
        const countryToEdit = data.find((country) => country.id === countryId);
        setCountry({ ...countryToEdit, tenantsDB: currentUser.data.currentTenant });
        setShowEditModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const countryToDelete = data.find((country) => country.id === id);
            const updatedCountry = { ...countryToDelete, tenantsDB: currentUser.data.currentTenant };
            const response = await deleteCountry(updatedCountry);
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
            const errorMessage = error?.message || JSON.stringify(error);
            setDeleteError(errorMessage);
        } finally {
            setIsLoading(false);
        }

    }

    const deleteConfirmation = (country) => {
        setDeleteError('');
        setDeletingCountry(country);
        setShowDeleteModal(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.Country,
                accessorKey: 'name',
            },
            {
                header: language.Abbreviation,
                accessorKey: 'abbreviation',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            }
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.id)}
                disabled={!getPermission(currentUser, "LocationCountries", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirmation(row.original)}
                disabled={(!getPermission(currentUser, "LocationCountries", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Countries}</h1>

            {
                getPermission(currentUser, "LocationCountries", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    <CountriesNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                    />
                    <CountriesEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        editingCountry={country}
                        getData={getData}
                        setData={setData}
                    ></CountriesEditModal>
                </>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteCountryMessage + deletingCountry.name}
                    handleDelete={() => handleDeleteClick(deletingCountry.id)}
                    error={deleteError}
                />
            }

            {
                getPermission(currentUser, "LocationCountries", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </div>

    );
}

export default Countries;