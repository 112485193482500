import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './css/UsersModal.css'

import updateAttribute from '../../api/EMSuser/updateAttribute';
import getUserCognito from '../../api/EMSuser/getUserCognito';
import confirmEmail from '../../api/EMSuser/confirmEmail';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function UsersModal({show, onHide, isChangeEmail, username, isChangePhone}) {
    const [newEmail, setNewEmail] = useState("");
    const [confirmNewEmail, setConfirmNewEmail] = useState("");
    const attribute = isChangeEmail ? "email" : "phone_number";
    const [isConfirmCode, setIsConfirmCode] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const navigate = useNavigate();
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    const [confirmNewPhoneNumber, setConfirmNewPhoneNumber] = useState("");
    const [validNumber, setValidNumber] = useState(true);
    const [validConfirmNumber, setValidConfirmNumber] = useState(true);
    const [phoneNumberReseted, setPhoneNumberReseted] = useState(false);
    const language = JSON.parse(localStorage.getItem('language'));

    async function handleSendCode() {
        if(newEmail === confirmNewEmail) {
            const isEmailConfirmed = await checkConfirmedEmail();
            if (isEmailConfirmed) {
                setAlertMessage(language.ConfirmedEmail);
                setShowAlert(true);
            } else {
                const response = await updateAttribute(username, attribute, newEmail);
                if(response.data.message.includes(language.SuccessUpdating)){
                    setIsConfirmCode(true);
                    setShowAlert(false);
                }
            }
        } else {
            setAlertMessage(language.EnterEmailTwice);
            setShowAlert(true);
        }
    }

    const handleCancel = () => {
        setShowAlert(false);
        setAlertMessage("");
        setIsConfirmCode(false);
        setValidNumber(true);
        setEmailConfirmed(false);
        setPhoneNumberReseted(false);
        onHide();
        ((emailConfirmed || phoneNumberReseted) && navigate(0));
    } 

    async function checkConfirmedEmail() {
        const {data} = await getUserCognito(username);
        const {UserAttributes} = data;
        let email = "";
        let confirmed = false;
        for(let attribute in UserAttributes) {
            if (UserAttributes[attribute].Name === "email") {
                email = UserAttributes[attribute].Value;
            } if (UserAttributes[attribute].Name === "email_verified" && UserAttributes[attribute].Value === "true") {
                confirmed = true;
            }
        }
        return (email === newEmail) && confirmed;
    }

    async function handleConfirmCode() {
        const response = await confirmEmail(confirmationCode);
        if (response === "Invalid verification code provided, please try again.") {
            setAlertMessage(response);
            setShowAlert(true);
        } if (response.data.response === "the attribute was confirmed successfully") {
            setAlertMessage(language.ConfirmedEmailSuccesfully);
            setEmailConfirmed(true);
            setShowAlert(true);
        }
    }

    const handlePhoneChange = (id, value, data) => {
        if (id === "newPhoneNumber") {
            setNewPhoneNumber(value);
            setValidNumber(validateNumber(value.slice(data.dialCode.length)));
        } else if (id === "confirmNewPhoneNumber") {
            setConfirmNewPhoneNumber(value);
            setValidConfirmNumber(validateNumber(value.slice(data.dialCode.length)));
        }
    };

    const validateNumber = number => {
        const phoneNumberPattern = /^\d{10}$/;
        return phoneNumberPattern.test(number);
    }

    async function handleChangePhone() {
        if(newPhoneNumber === confirmNewPhoneNumber && validNumber) {
            const phone = "+" + newPhoneNumber
            const response = await updateAttribute(username, attribute, phone);
            if(response.data.message.includes("Success updating")){
                setPhoneNumberReseted(true);
                setAlertMessage(language.ResetedPhoneNumber);
                setShowAlert(true);
            }
        } else {
            setAlertMessage(language.EnterAValidNumber);
            setShowAlert(true);
        }
    }

    return(
        <Modal show={show} onHide={onHide} className='modal' centered>
            <Modal.Header>
                <Modal.Title>{((isChangeEmail && isConfirmCode) && language.CodeVerification) || 
                    (isChangeEmail && language.ChangeEmail) || (isChangePhone && language.ChangePhone)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(!emailConfirmed && !phoneNumberReseted) &&
                    <>
                        {isChangeEmail &&
                            <>
                                <label className='input__label'>{language.Email}</label> <br />
                                <input placeholder={language.EnterNewEmail} 
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => setNewEmail(evt.target.value)}
                                    disabled={isConfirmCode}
                                ></input>
                                <label className='input__label'>{isChangeEmail && language.ConfirmEmail}</label> <br />
                                <input placeholder={isChangeEmail && language.TypeAgainEmail} 
                                    className='module__input-search modal__text-box email-input'
                                    onChange={evt => setConfirmNewEmail(evt.target.value)}
                                    disabled={isConfirmCode}
                                ></input>
                                
                                {
                                    isConfirmCode &&
                                    <>
                                        <label className='input__label'>{isChangeEmail && language.Code}</label> <br />
                                        <input placeholder={isChangeEmail && language.EnterYourVerificationCode} 
                                            className='module__input-search modal__text-box'
                                            onChange={evt => setConfirmationCode(evt.target.value)}
                                        ></input>
                                    </>
                                }
                            </>
                        }
                        
                        {isChangePhone &&
                        <>
                            <label className='input__label'>{language.NewPhoneNumber}</label> <br />
                            {!validNumber && <div className='inputs__invalid-number-alert'>{language.TenDigitNumber}</div>}
                            <PhoneInput placeholder={language.EnterNewPhone} className='module__input-search modal__text-box email-input' 
                                onChange={(value, data) => handlePhoneChange("newPhoneNumber", value, data)} type='tel' inputProps={{required: true}}
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" country={"us"} id={"newPhoneNumber"} ></PhoneInput>
                            <label className='input__label'>{language.ConfirmNewPhone}</label> <br />
                            {!validConfirmNumber && <div className='inputs__invalid-number-alert'>{language.TenDigitNumber}</div>}
                            <PhoneInput placeholder={language.ConfirmYourNewPhone} className='module__input-search modal__text-box email-input' 
                                onChange={(value, data) => handlePhoneChange("confirmNewPhoneNumber", value, data)} type='tel' inputProps={{required: true}}
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" country={"us"} id={"confirmNewPhoneNumber"} ></PhoneInput>
                        </>
                            
                        }
                        
                    </>
                }
                {showAlert &&
                    <>
                        <p className='alert-message'>{alertMessage}</p>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                
                <Button className='general-button danger' onClick={handleCancel}>
                    {(emailConfirmed || phoneNumberReseted) ? language.Close : language.Cancel}
                </Button>
                {(!emailConfirmed && !phoneNumberReseted) &&
                    <Button className='general-button primary' onClick={((isChangeEmail && isConfirmCode) && handleConfirmCode) || (isChangeEmail && handleSendCode) || (isChangePhone && handleChangePhone)}>
                        {(((isChangeEmail && isConfirmCode) && language.ConfirmCode) || (isChangeEmail && language.SendCode) || (isChangePhone && language.SavePhoneNumber))}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default UsersModal;