import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';

async function ResetPassword(dataUser) {  
    const session = await fetchAuthSession ();
    const idToken = session.tokens.idToken.toString();
    const apiName = 'UPA';
    const path = '/Users/resetTemporaryPassword';
    const options = {
        headers: {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        },
        response: true,
        body: dataUser 
    };

    try {
        const restOperation  = post({
            apiName,
            path,
            options
        });

        const {body} = await restOperation.response
        const data = await body.json();
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default ResetPassword;
