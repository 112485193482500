import deleteMethod from "../../Hooks/API/DeleteMethod";

async function DeleteFullStatusControl(id, tenantsDB) {
    const path = `/Tvm/fullStatusControl/${id}?tenantsDB=${tenantsDB}`;
    try {
        const { data, error } = await deleteMethod(path);
        return { data, error };
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
}

export default DeleteFullStatusControl;