import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import createDeviceAlarm from '../../../api/DevicesAlarms/createDeviceAlarm';
import getDeviceCodesCatalog from '../../../api/DevicesAlarms/getDeviceCodesCatalog';
import createEmailAlarm from '../../../api/EmailsAlarms/createEmailAlarm';
import editEmailAlarm from '../../../api/EmailsAlarms/editEmailAlarm';
import ErrorCases from '../../../Common/ErrorCases';

function EmailsEditModal({ show, onHide, getData, setData, setIsLoading, editingEmail }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [email, setEmail] = useState([]);
    const [codesCatalog, setCodesCatalog] = useState([]);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState('');
    const language = JSON.parse(localStorage.getItem('language'));
    const [editError, setEditError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                setEmail({
                    name: editingEmail.name,
                    lastName: editingEmail.lastName,
                    isActive: editingEmail.isActive,
                    email: editingEmail.email,
                    tenantsDB: currentTenant
                });
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, [editingEmail]);

    const handleCancel = () => {
        onHide();
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail({ ...email, email: value });

        if (validateEmail(value)) {
            setEmailError('');
        } else {
            setEmailError(language.EnterValidEmail);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email.email)) {
            setEmailError(language.EnterValidEmail);
        } else {
            try {
                setIsLoading(true);
                const response = await editEmailAlarm(email, editingEmail.idEmailAlarms);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setEditError(error);
                    return;
                }
                const newData = await getData();
                setData(newData);
                onHide();
                clearEmail();
            } catch (error) {
                console.error(error);
                setEditError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const clearEmail = () => {
        setEmail({
            ...email,
            name: '',
            lastName: '',
            isActive: 0,
            email: '',
            tenantsDB: currentTenant
        });
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.EditEmail}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <><form onSubmit={handleSubmit}>
                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setEmail({ ...email, name: evt.target.value })}
                                value={email.name}
                            ></input>
                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.LastName}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={evt => setEmail({ ...email, lastName: evt.target.value })}
                                value={email.lastName}
                            ></input>
                        </div>
                    </div>

                    <div className='inputs-row__no-justified'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Email}</label> <br />
                            <input
                                className='module__input-search modal__text-box email-input'
                                onChange={handleEmailChange}
                                value={email.email}
                            ></input>
                        </div>

                        <div className='input-name'>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={email.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => email.isActive === 1 ? setEmail({ ...email, isActive: 0 }) : setEmail({ ...email, isActive: 1 })} />
                        </div>
                    </div>
                </form>
                </>
            </Modal.Body>

            <Modal.Footer>
                <Button className="general-button danger" onClick={handleCancel}>
                    {language.Cancel}
                </Button>
                <Button className="general-button primary" onClick={handleSubmit} type="submit">
                    {language.Save}
                </Button>
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
            </Modal.Footer>
        </Modal>
    );
}

export default EmailsEditModal;