import * as React from "react";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import "./css/Auth.css";

import { AiOutlineMail } from "react-icons/ai";

import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from "react-bootstrap";

import ConfirmEmail from "../api/EMSuser/confirmEmail";
import GetUser from '../api/EMSuser/getUser';

import {
  fetchUserAttributes,
  updateMFAPreference,
  getCurrentUser,
  signOut
} from 'aws-amplify/auth';
import CustomAlert from "../Common/CustomAlert";
import languages from './LoginLanguage.json';
import CurrentLanguageFile from "../Hooks/language/CurrentLanguageFile";
import currentTenantsControl from "../Hooks/currentTenant/currentTenantsControl";

function EmailCode() {
    const [emailCode, setEmailCode] = useState('');
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const confirmEmail = JSON.parse(localStorage.getItem('confirmEmail'));
    const currentLanguage = localStorage.getItem('currentLanguage');
    const language = languages[currentLanguage][0];
    const language_EMS =  localStorage.getItem('language');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      try {
        const currentLocalUser = JSON.parse(localStorage.getItem('currentUser'));

        if (authenticated && !confirmEmail && currentLocalUser ) {
          const { tenantsUser, currentTenant } = currentLocalUser.data;
  
          if (tenantsUser.length === 1) {
              navigate('/Home');
          } else if (tenantsUser.length > 1) {
              if (currentTenant === '') {
                  navigate('/select-tenant');
              } else {
                  navigate('/Home');
              }
          }
        }
      } catch (error) {
        console.error(error);
      }
        


    setIsLoading(false);
  }, [authenticated]);

    const checkAuthentication = async () => {
      try {
          const currentUser = await getCurrentUser();
          setAuthenticated(!!currentUser);
      } catch (error) {
          setAuthenticated(false);
      }
    };

    const handleConfirm = async () => {
      setIsLoading(true);
      try{
        await ConfirmEmail(emailCode);
        const userAttributes = await fetchUserAttributes();
        if(userAttributes.email_verified === 'true'){
          await updateMFAPreference({ sms: 'PREFERRED' });
          const tenants = await currentTenantsControl(userAttributes);
          const currentUser = await GetUser(tenants);
          await CurrentLanguageFile(currentUser);
          localStorage.setItem('confirmEmail', JSON.stringify(false));
          localStorage.setItem('currentLanguage', currentUser.data.userData.languageAbbreviation);
          if(currentUser.data.tenantsUser.length === 1) {
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            navigate('/Home');
            navigate(0);
          } else {
            currentUser.data.currentTenant = '';
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            navigate('/select-tenant');
          }
        } else {
          setShowAlert(true);
        }
      } catch (error) {
        setShowAlert(true);
      }
      setIsLoading(false);
    }

  const handleBackToLogin = () => {
    localStorage.clear();
    signOut();
    navigate('/login');
  };

  return (
    <>
      <div className="main-container">
        <img
          loading="lazy"
          srcSet="/img/logoBEATT.png"
          className="login__logo"
        />
        <div className="login__container">
          <div className="login__inputs-group">
            <div className="login__input">
              <img src='./img/icons/login/Code.png' className='login__icon' />
              <Form.Control className="login__form" type="text" placeholder={language.EmailCode}
                onChange={evt => setEmailCode(evt.target.value)} />
            </div>
          </div>
          <div className="button-light-blue" onClick={handleConfirm}>{language.CONFIRM}</div>
        </div>

        <div className="login__bottom--centered">
          <div className="login__bottom-forgot"
            onClick={handleBackToLogin}>{language.BackToLogin}</div>
        </div>
        {
          showAlert &&
          <CustomAlert message={language.CodeIncorrectAlert} />
        }

      </div>
      {isLoading &&
        <div className='spinner-container'>
          <Spinner animation="border" variant="primary" className='spinner' />
        </div>
      }
    </>
  );
}

export default EmailCode;