import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';

async function GetLanguageProducts(language) {
    const session = await fetchAuthSession ();
    const idToken = session.tokens.idToken.toString();
    const apiName = 'UPA';
    const path = `/Products/Language`;
    const options = {
        headers: {
            'Authorization': idToken,
            'Content-Type': 'application/json'
        },
        response: true,
        body: language 
    };

    try {
        const restOperation  = await post({
            apiName,
            path,
            options
        });
        
        const {body} = await restOperation.response
        const data = await body.json();
        
        return {data};
    } catch (error) {
        return error;
    }
}

export default GetLanguageProducts;