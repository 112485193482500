import { Spinner } from "react-bootstrap";

import { useState, useEffect } from "react";

import getAllTVM from "../../../api/TVM/getAllTVM";
import AddButton from "../../../Common/AddButton";
import getPermission from "../../../auth/GetPermission";
import GeneralTable from "../../../Hooks/Table/GeneralTable";
import TVMNewModal from "./TVMNewModal";
import "./css/TVMPage.css";
import TVMEditModal from "./TVMEditModal";
import deleteTVM from "../../../api/TVM/deleteTVM";
import DeleteModal from "../../../Common/DeleteModal";
import ErrorCases from "../../../Common/ErrorCases";

import { Box } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

function TVM() {
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showTVMNewModal, setShowTVMNewModal] = useState(false);
    const [editingTVM, setEditingTVM] = useState(null);
    const [showTVMEditModal, setShowTVMEditModal] = useState(false);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingTvm, setDeletingTvm] = useState(false);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getAllTVM(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const columns = [
        {
            header: language.Name,
            accessorKey: 'nameTVM',
        },
        {
            header: language.Number,
            accessorKey: 'TVMNumber',
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            header: language.Station,
            accessorKey: 'stationName',
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: language.Active,
            accessorKey: 'isActive',
            Cell: ({ renderedCellValue }) => (
                <Box>
                    <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                        className='table__checkbox' />
                </Box>
            ),
        }
    ];

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '0rem', flexGrow: true }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original)}
                disabled={!getPermission(currentUser, "DMTVM", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirm(row.original)}
                disabled={(!getPermission(currentUser, "DMTVM", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    const handleEditClick = (TVM) => {
        setEditingTVM(TVM);
        setShowTVMEditModal(true);
    };

    const handleDeleteClick = async (id) => {
        try {
            setIsLoading(true);
            const response = await deleteTVM(id, currentTenant);
            if (response.error != undefined) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false);
        }
    };

    const deleteConfirm = (tvm) => {
        setDeletingTvm(tvm);
        setDeleteError('');
        setShowDeleteModal(true);
    };

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.TVM}</h1>
            <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
            {
                getPermission(currentUser, "DMTVM", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowTVMNewModal(true)}><AddButton /></div>
            }
            <TVMNewModal
                show={showTVMNewModal}
                onHide={() => setShowTVMNewModal(false)}
                getData={getData}
                setData={setData}
                setIsLoading={setIsLoading} />
            {editingTVM && showTVMEditModal && (
                <TVMEditModal
                    show={showTVMEditModal}
                    onHide={() => setShowTVMEditModal(false)}
                    getData={getData}
                    setData={setData}
                    setIsLoading={setIsLoading}
                    editingTVM={editingTVM} />
            )}
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteTvmMessage + deletingTvm.nameTVM}
                    handleDelete={() => handleDeleteClick(deletingTvm.idTvm)}
                    error={deleteError}
                />
            }
        </div>
    );
};

export default TVM;