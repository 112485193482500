import {fetchAuthSession } from 'aws-amplify/auth';
import {post} from 'aws-amplify/api';
import postMethod from '../../Hooks/API/post';

async function CreateProduct(product) {  
    const path = '/Products/add';
    try {
        const data = await postMethod(product, path);
        return {data : data, error : ''};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default CreateProduct;