import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { useState, useEffect } from 'react';

import './css/ProductsNewModal.css';
import createProduct from '../../api/Fares/createProduct';
import getLanguageProducts from '../../api/Fares/getLanguageProducts';
import ErrorCases from '../../Common/ErrorCases.js';

import CurrencyInput from 'react-currency-input-field';

function ProductsNewModal({ show, onHide, getData, setData }) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [product, setProduct] = useState({ tenantsDB: currentUser.data.currentTenant, isActive: 1, zoneFare: 0, flatFare: 0, languageNumber: 1, price: 0.00 });
    const [isLoading, setIsLoading] = useState(false);
    const codeLanguage = currentUser.data.userData.languageId === 1 ? "EN" : currentUser.data.userData.languageId === 2 && "ES";
    const [languageData, setLanguageData] = useState([]);
    const [error, setError] = useState("");
    const [alertMessage, setAlertMessage] = useState('');
    const [formattedPrice, setFormattedPrice] = useState(0.00);
    const language = JSON.parse(localStorage.getItem('language'));
    const [createError, setCreateError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getLanguageData();
                setLanguageData(response);

                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }

        fetchData();
    }, []);

    async function getLanguageData() {
        const response = await getLanguageProducts({ codeLanguage: codeLanguage, fileName: "Products", tenantsDB: currentUser.data.currentTenant });
        return response.data;
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const newProduct = product;
            newProduct.price = formattedPrice;
            const response = await createProduct(newProduct);
            if(response.error !== '') {
                const error = ErrorCases(response.error);
                setCreateError(error);
                return;
            }
            const data = await getData();
            setData(data);
            onHide();
            setProduct({...product, isActive: 1, zoneFare: 0, flatFare: 0, languageNumber: 1, price: 0.00});
            } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setProduct({ ...product, isActive: 1, zoneFare: 0, flatFare: 0, languageNumber: 1, price: 0.00 });
        onHide();
        setCreateError('');
    };

    const handleCheckClick = key => {
        product[key] === 1 ? setProduct({ ...product, [key]: 0 }) : setProduct({ ...product, [key]: 1 })
    };

    const handleZoneFareCheck = () => {
        const newProduct = product;
        newProduct.zoneFare === 1 ? newProduct.zoneFare = 0 : newProduct.zoneFare = 1;
        if (newProduct.zoneFare === 1 && newProduct.flatFare === 1) {
            newProduct.flatFare = 0;
        }
        setProduct({ ...newProduct });
    };

    const handleFlatFareCheck = () => {
        const newProduct = product;
        newProduct.flatFare === 1 ? newProduct.flatFare = 0 : newProduct.flatFare = 1;
        if (newProduct.zoneFare === 1 && newProduct.flatFare === 1) {
            newProduct.zoneFare = 0;
        }
        setProduct({ ...newProduct });
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header>
                    <Modal.Title>{language.NewProduct}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inputs-row'>
                        <div className='input-name'>
                            <label className='input__label'>{language.Name}</label> <br />
                            <input placeholder={language.EnterProductName}
                                className='module__input-search'
                                onChange={evt => setProduct({ ...product, name: evt.target.value })}
                            ></input>
                        </div>
                        {/* <div className=''>
                        <label className='input__label'>Language Number</label> <br />
                        {languageData.length !== 0 &&
                            <select onChange={evt => setProduct({...product, languageNumber: evt.target.value})} className='dropdown'>
                                <option>Select an option</option>
                                {languageData.productsName.length !== 0 && 
                                    languageData.productsName.map(language => {
                                        return <option value={language.languageNumber}>{language.productName}</option>
                                        })
                                    }
                            </select>
                        }
                    </div> */}
                    </div>

                    <div className='inputs-row'>
                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.QuantityPass}</label> <br />
                            <input
                                className='module__input-search input-numeric'
                                onChange={evt => setProduct({ ...product, quantityPass: evt.target.value })}
                                type='number'
                            ></input>
                        </div>
                        <div className='input-numeric-box'>
                            <label className='input__label'>{language.QuantityPrint}</label> <br />
                            <input
                                className='module__input-search input-numeric'
                                onChange={evt => setProduct({ ...product, quantityPrint: evt.target.value })}
                                type='number'
                            ></input>
                        </div>


                        <div>
                            <label className='input__label'>{language.Active}</label> <br />
                            <img src={product.isActive === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={() => handleCheckClick("isActive")} />
                        </div>
                        <div>
                            <label className='input__label'>{language.ZoneFare}</label> <br />
                            <img src={product.zoneFare === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={handleZoneFareCheck} />
                        </div>
                        <div>
                            <label className='input__label'>{language.FlatFare}</label> <br />
                            <img src={product.flatFare === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='table__checkbox'
                                onClick={handleFlatFareCheck} />
                        </div>
                    </div>

                    <div className='inputs-row'>
                        <div>
                            <label className='input__label'>{language.Price}</label> <br />
                            <CurrencyInput
                                placeholder={language.EnterProductPrice}
                                className='module__input-search'
                                defaultValue={0.00}
                                decimalsLimit={2}
                                onValueChange={(value, names, values) => setFormattedPrice(values.float)}
                                decimalScale={2}
                                maxLength={7}
                                intlConfig={{ locale: languageCode, currency: currency.currency }}
                            />
                        </div>
                        <div>
                            <label className='input__label'>{language.ProductType}</label> <br />
                            <select onChange={evt => setProduct({ ...product, productType: evt.target.value })} className='module__input-search'>
                                <option >{language.SelectAnOption}</option>
                                <option value={"passes"}>{language.Passes}</option>
                                <option value={"promotional"}>{language.Promotional}</option>
                            </select>
                        </div>
                    </div>
                    <p className='price__alert'>{alertMessage}</p>
                    <div className='error-alert'>{createError}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancel}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={handleSave}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default ProductsNewModal;