import React from 'react';
import GroupTable from "../../../../Hooks/Table/GroupTable";
import { RiErrorWarningFill } from "react-icons/ri";

const DashboardTableAlarm = ({device_data,header,handleDetails,language}) =>{
    const columns_color = [{
        'InService':'InService_color'
    }];
    const columns_table = [
        {
            header: language.totalAlarm,
            accessorKey: 'totalAlarm',
            Cell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <div>
                        {value > 0 && (<RiErrorWarningFill  style={{ marginRight: '2px' }} color="orange" size={24}/>)}
                    </div>
                );
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            header: language.nameStation,
            accessorKey: 'nameStation'
        },
        {
            header: 'TVM',
            accessorKey: 'nameTVM' 
        },
        {
            header: language.TVMInService,
            accessorKey: 'InService' 
        }
    ];


    header.map(item => {
        if(item.deviceCode !== 'TVMInService'){
            columns_table.push({
                header: language[item.deviceCode],
                accessorKey: item.deviceCode
            });

            const colorKey = `${item.deviceCode}_color`;
            columns_color.push({
                [item.deviceCode]:colorKey
            });
        }
    });

    return (
        <GroupTable device_data={device_data} columns_table={columns_table} columns_color={columns_color} handleDetails={handleDetails} language={language}/>
    );
}

export default DashboardTableAlarm;