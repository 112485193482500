import { fetchAuthSession  } from '@aws-amplify/auth';
import { get } from 'aws-amplify/api';
async function GetUser(tenantsDB) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = tenantsDB?tenantsDB:currentUser.data.currentTenant;

    try {
        const session = await fetchAuthSession ();
        const idToken = session.tokens.idToken.toString();
        const apiName = 'UPA';
        const path = `/Users/0?tenantsDB=${currentTenant}`;
        const options = {
            headers: {
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
            response: true
        };
        
        const restOperation = get({
            apiName,
            path,
            options
        });

        const {body} = await restOperation.response
        const data = await body.json();

        return {data : data, error : ''};


    } catch (error) {
        const responseError = error.response.message !== undefined? error.response.message : error.response.data.error;
        console.error(responseError);
        return {data : undefined, error : responseError};
    }
}
export default GetUser;
