import { uploadData } from 'aws-amplify/storage';
import {fetchAuthSession } from 'aws-amplify/auth';
import RemoveFile from './RemoveFile';

//can upload new files in the selected path and can update the file with the same name
const UploadFile = async (pathFile,typeFile,updateFile) => {
    const session = await fetchAuthSession ();
    //const extension = pathFile.name.split('.').pop();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    let keyPath;
    switch (typeFile) {
        //this creates the TVM update file
        case 'update_config':
            keyPath = `${currentTenant}/updateconfig/${pathFile.name}`;
            break;
        //Path to save profile photo images, update and create
        case 'profile':
            //keyPath = `${currentTenant}/profile/${session.userSub}/profile${extension}`;
            break;
        default:
            keyPath = `${currentTenant}/others/${pathFile.name}`;
            break;
    }

    try {
        const result = await uploadData({
            key: keyPath,
            data: pathFile,
            options: {
                onProgress: ({ transferredBytes, totalBytes }) => {
                    if (totalBytes) {
                        console.log(
                        `Upload progress ${
                            Math.round((transferredBytes / totalBytes) * 100)
                        } %`
                        );
                    }
                }
            }
        }).result;
        return result;
    } catch (error) {
        console.log('Error : ', error);
        return error;
    }
}

export default UploadFile;
