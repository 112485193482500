import postMethod from "../../Hooks/API/post";

async function editCustomReport(body,id) {  
    const path = `/CustomReports/edit/${id}`;
    try {
        const {data,error} = await postMethod(body,path);
        return {data,error};
    } catch (error) {
        const errorMessage = error.message;
        return ({error:errorMessage});
    }
} 

export default editCustomReport;