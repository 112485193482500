import postMethod from "../../Hooks/API/post";

const getQR = async (tenantsDB, codeQr) => {
    const body = {
        codeQr: codeQr,
        tenantsDB: tenantsDB
    };
    const path = `/Tvm/getQR`;
    try {
        const data = await postMethod(body, path);
        return data;
    } catch (error) {
        console.log(error)
        return {error};
    }
}

export default getQR;